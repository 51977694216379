import {
    CQReactTable,
    PrimaryButton,

} from '@prasanna-transcend/code-quick-components';
import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import {
    Box,
    HStack,
    Text,
} from '@chakra-ui/react';
import AppColors from '../../../../constants/AppColors';
import { defaultReactTableProp } from '../../../../utils/my_audits.utils';
import { FONT_FAMILY } from '../../../../constants/Theme';
const columnHelper = createColumnHelper();

const EducationClassTable = () => {

    const data = [
      { course: 'Coding 101', link : 'https://calendly.com/education-sessions/coding-101' },
      { course: 'Documentation 101', link : 'https://calendly.com/education-sessions/documentation-101' },
      { course: 'MDM 101', link : 'https://calendly.com/education-sessions/mdm101' },
    ];

    const columns = React.useMemo(
        () => [
            columnHelper.accessor('course', {
                header: () => 'Course',
                id: 'course',
                cell: info => {
                    return (
                        <Text >
                            {info.renderValue()}
                        </Text>
                    );
                },
            }),
            columnHelper.accessor('action', {
                header: () => <span>Action</span>,
                cell: info => {
                    
                    return <PrimaryButton
                        bg={AppColors.secondary}
                        fontSize={'14px'}
                        fontWeight="normal"
                        size="md"
                        borderRadius="3xl"
                        px="10"
                        onClick={() => {
                            const route = info?.row?.original?.link; 
                            window.open(route)
                        }}
                    >
                        Enroll
                    </PrimaryButton>
                },
            }),
        ],
        // eslint-disable-next-line
        []
    );

    const getTableCellProps = (cell, data) => {
        const startingHeaderId = ['course', ];

        let color = 'black';
        if (data.column.id === 'date' || data.column.id === 'accuracy') {
            color = AppColors.gray;
        }
        return {
            fontFamily: FONT_FAMILY.ptSans,
            color: color,
            textAlign: startingHeaderId.includes(data.column.id) ? 'none' : 'center',

        };
    };

    function getHeaderCellProps(header) {
        const startingHeaderId = ['course',];
        return {
            textTransform: 'none',
            fontFamily: FONT_FAMILY.ptSans,
            fontWeight: 'bold',
            color: 'black',
            justifyContent: startingHeaderId.includes(header.id)
                ? 'flex-start'
                : 'center',
        };
    }

    return <>
        <Box
            marginTop={3}
            width={'100%'}
            bg={AppColors.white}
            padding={4}
            borderRadius={'lg'}
            shadow={'md'}
        >
            <HStack width={'100%'} justifyContent={'space-between'} mb={3}>
                <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
                    Education Class
                </Text>
            </HStack>
            <CQReactTable
                isLoading={false}
                data={data}
                columns={columns}
                {...defaultReactTableProp}
                getHeaderCellProps={header => {
                    return {
                        ...getHeaderCellProps(header),
                    };
                }}
                getCellProps={getTableCellProps}

            />
        </Box>
    </>
}
export default EducationClassTable