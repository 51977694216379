import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Heading,
  HStack,
  TabList,
  TabPanel,
  TabPanels,
  Tabs,
} from '@chakra-ui/react';
import { TextInput } from '@prasanna-transcend/code-quick-components';
import React, { useContext, useEffect, useState } from 'react';
import { CustomTab } from '../../components/CustomTab';
import NotApprovedPage from '../../components/NotApprovedPage';
import AppColors from '../../constants/AppColors';
import { FONT_FAMILY } from '../../constants/Theme';
import { withProvider } from '../../hoc/withProvider';
import useCQMediaQuery from '../../hooks/useCQMediaQuery';
import { AuthContext } from '../../providers/AuthProvider';
import DashboardProvider, {
  DashboardContext,
} from '../../providers/DashboardProvider';
import { getCurrentUserTypeName } from '../../utils/getCurrentUserTypeId';
import ChartTable from './components/ChartTable';

const ChartContainer = props => {
  const authContext = useContext(AuthContext);

  const myChartsTab = [
    {
      title: 'ALL',
      header: 'Audit Uploads',
      component: ChartTable,
    },
  ];
  const dashboardContext = useContext(DashboardContext);
  const { chartFilters, getCharts } = dashboardContext;

  const [selectedTab, setSelectedTab] = useState(0);
  const [onSearchText, setSearchText] = useState('');

  const [name, setName] = useState('');

  useEffect(() => {
    const data = getCurrentUserTypeName(authContext.currentUser);
    setName(data);
  }, [authContext.currentUser]);

  const marginHorizontal = useCQMediaQuery({
    lg: '12%',
    md: '6%',
    sm: '2%',
  });

  const onSearchAction = () => {
    dashboardContext.setChartFilters({ ...chartFilters, search: onSearchText });
  };

  const onFilterChange = e => {
    if (e) {
      getCharts.setParams({ page: 1 });
      setSearchText(e.target.value);
    } else {
      setSearchText('');
    }
  };

  const notApprovedPage = !authContext.currentUser.is_approved;

  return (
    <>
      <Box mx={marginHorizontal} opacity={notApprovedPage ? '0.1' : 1}>
        <Heading my={5} fontSize={'xl'}>{`${name} Audit Results`}</Heading>
        <Box
          shadow={'md'}
          bg="white"
          p={4}
          w="100%"
          style={{ borderRadius: '12px' }}
        >
          <Tabs
            fontFamily={FONT_FAMILY.baiJamurjee}
            onChange={setSelectedTab}
            index={selectedTab}
          >
            <TabList>
              <HStack w={'100%'} justifyContent={'space-between'}>
                <HStack spacing={4} px={4}>
                  {myChartsTab.map((aa, index) => {
                    return (
                      <CustomTab
                        key={`my-charts-tab-${index}`}
                        _selected={{
                          fontWeight: 'bold',
                          color: AppColors.secondary,
                        }}
                        color={'black'}
                        fontFamily={FONT_FAMILY.baiJamurjee}
                      >
                        {aa.title}
                      </CustomTab>
                    );
                  })}
                </HStack>
                <Box>
                  <Box>
                    <TextInput
                      borderRadius={'md'}
                      value={onSearchText}
                      onChange={e => onFilterChange(e)}
                      onKeyDown={e => {
                        if (e.keyCode === 13) {
                          onSearchAction();
                        }
                      }}
                      mb="2"
                      rightIconProps={{ zIndex: 0 }}
                      placeholder="Search"
                      rightIcon={
                        <SearchIcon
                          onClick={onSearchAction}
                          color={AppColors.gray}
                        />
                      }
                    />
                  </Box>
                </Box>
              </HStack>
            </TabList>

            <TabPanels>
              {myChartsTab.map((aa, index) => {
                return (
                  <TabPanel p={0} key={`my-charts-panel-${index}`}>
                    <aa.component />
                  </TabPanel>
                );
              })}
            </TabPanels>
          </Tabs>
        </Box>
      </Box>
      {notApprovedPage ? <NotApprovedPage /> : null}
    </>
  );
};

export default withProvider(DashboardProvider, ChartContainer);
