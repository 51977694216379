/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Center,
  HStack,
  Skeleton,
  Spinner,
  Stack,
  Text,
} from '@chakra-ui/react';
import {
  useBoolean,
  useToastr,
} from '@prasanna-transcend/code-quick-components';
import { formatDistanceStrict } from 'date-fns';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import CQContainer from '../../components/layout/CQContainer';
import AppColors from '../../constants/AppColors';
import ROUTES from '../../constants/Routes';
import { FONT_FAMILY } from '../../constants/Theme';
import { withProvider } from '../../hoc/withProvider';
import NotificationProvider, {
  NotificationsContext,
} from '../../providers/NotificationProvider';
import { replaceRoute, toTitleCase } from '../../utils/common.utils';

const scrollStyle = {
  '&::-webkit-scrollbar': {
    width: '4px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 4px lightGrey',
    width: '4px',
    borderRadius: '4px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'grey',
    borderRadius: '4px',
  },
  // '::-webkit-scrollbar': {
  //   display: 'none',
  // },
};

function Notifications() {
  const notificationContext = useContext(NotificationsContext);
  const navigate = useNavigate();
  const toast = useToastr();

  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);

  const getNotification = async () => {
    const response = await notificationContext.getNotifications(currentPage);
    setNotifications(prev => [...prev, ...response.results]);
    setNotificationCount(response.count);
    ifState.off();
  };

  const [currentPage, setCurrentPage] = React.useState(0);
  const [isFetching, ifState] = useBoolean(false);

  const perPage = 7;
  const lastPage = Math.ceil(notificationCount / perPage);

  useEffect(() => {
    if (currentPage === 0) {
      setCurrentPage(1);
    } else {
      getNotification();
    }
  }, [currentPage]);

  //   const getNewNotification=()=>{

  //    return notifications.filter(x => ar2.map(y => y.itemId).includes(x.itemId));

  //   }

  //   setInterval(() => {

  // getNewNotification()

  //   }, 10000);

  const loadNextData = async e => {
    const bottom =
      e.target.scrollHeight - e.target.scrollTop === e.target.clientHeight;
    if (
      bottom &&
      !notificationContext.isFetchingNotifications &&
      currentPage < lastPage
    ) {
      ifState.on();
      setCurrentPage(prev => prev + 1);
    }
  };

  const notificationContainer = () => {
    return (
      <Box px={40}>
        <Text
          fontWeight={'semibold'}
          fontFamily={FONT_FAMILY.baiJamurjee}
          fontSize={'x-large'}
        >
          Notifications
        </Text>
        <Box
          onScroll={loadNextData}
          overflowY={'scroll'}
          sx={scrollStyle}
          maxHeight={'550px'}
          mt={5}
          maxWidth={'886px'}
          borderRadius={'md'}
          bg={AppColors.white}
        >
          {notificationContext.isFetchingNotifications && !isFetching ? (
            <Stack bg={AppColors.bgColor}>
              <Skeleton height={'88px'} mb={1} />
              <Skeleton height={'88px'} mb={1} />
              <Skeleton height={'88px'} mb={1} />
            </Stack>
          ) : notifications?.length ? (
            notifications
              ?.sort((nl, pn) => Number(nl.read) - Number(pn.read))
              .map((n, i) => {
                return (
                  <Box
                    width={'100%'}
                    borderBottom={
                      i !== notifications?.length - 1 &&
                      `1px solid ${AppColors.gray}`
                    }
                    onClick={async () => {
                      const auditSheetRoute = replaceRoute(
                        ROUTES.SINGLE_AUDIT_SHEET,
                        {
                          uploadId: n.reference_chart,
                        }
                      );
                      if (!n.read)
                        notificationContext.markRead(n.notification_user_id);
                      if (n.reference_chart) {
                        const res = await notificationContext.getAuditUpload(
                          n.reference_chart
                        );
                        if (res) {
                          navigate(auditSheetRoute);
                        } else {
                          toast.showError({
                            description: `This chart has been deleted`,
                          });
                        }
                      }
                    }}
                    height={'88px'}
                    bg={!n.read ? AppColors.white : '#EBEBEE'}
                    display={'flex'}
                    alignItems={'center'}
                    px={10}
                  >
                    <Stack display={'flex'} flexDirection={'column'}>
                      <HStack width={'100%'} alignItems={'baseline'}>
                        <Text
                          fontWeight={'semibold'}
                          color={AppColors.secondary}
                        >
                          {n.message.startsWith('@') &&
                            toTitleCase(
                              `${n?.sender?.first_name} ${n?.sender?.last_name}`
                            )}
                        </Text>
                        <Text>
                          {n.message.startsWith('@')
                            ? n.message?.substring(1)
                            : n.message}
                        </Text>
                      </HStack>
                      <Text
                        textAlign={'left'}
                        color={AppColors.darkGray}
                        fontSize={'11px'}
                      >
                        {formatDistanceStrict(
                          new Date(n.created_at),
                          new Date(),
                          {
                            addSuffix: true,
                          }
                        )}
                      </Text>
                    </Stack>
                  </Box>
                );
              })
          ) : null}
          {isFetching ? (
            <Center m={3}>
              <Spinner />
            </Center>
          ) : (
            ''
          )}
        </Box>
      </Box>
    );
  };

  return <CQContainer>{notificationContainer()}</CQContainer>;
}

export default withProvider(NotificationProvider, Notifications);
