import { API } from '../config/AppConfig';
import {
  CHART_ACCURACY_BREAKDOWN,
  RVU_OPP,
  DASHBOARD_RVU_OPP_BREAKDOWN,
  DEPARTMENT_PROVIDER,
  GET_DASHBOARD_CQ_SCORE,
  GET_DEPARTMENT_NAME,
  PHYSICIAN_LIST,
  DASHBOARD_RVU_OPPORTURINTY,
} from '../constants/Endpoints';

class DepartmentDashboardRepository {
  async getDepartmentCqScore(queryParams) {
    const response = await API.get(GET_DASHBOARD_CQ_SCORE, {
      queryParams,
    });
    return response;
  }

  async getRevenueOpportunity(queryParams) {
    const response = await API.get(RVU_OPP, {
      queryParams,
    });
    return response;
  }

  async getrevenueopporturityScore(queryParams) {
    const response = await API.get(DASHBOARD_RVU_OPPORTURINTY, {
      queryParams,
    });
    return response;
  }

  async getOutstandingRevenue(queryParams) {
    const response = await API.get(DASHBOARD_RVU_OPP_BREAKDOWN, {
      queryParams,
    });
    return response;
  }

  async getProviders(queryParams) {
    const response = await API.get(DEPARTMENT_PROVIDER, {
      queryParams,
    });
    return response;
  }

  async getPhysicians(queryParams) {
    const response = await API.get(PHYSICIAN_LIST, {
      queryParams,
    });
    return response;
  }

  async getAuditChartAccuracy(queryParams) {
    const response = await API.get(CHART_ACCURACY_BREAKDOWN, {
      queryParams,
    });
    return response;
  }

  async getDepartmentName(departmentId) {
    const response = await API.get(GET_DEPARTMENT_NAME, {
      pathParams: { departmentId },
    });
    return response;
  }
}

const departmentDashboardRepository = new DepartmentDashboardRepository();
export default departmentDashboardRepository;
