import { SearchIcon } from '@chakra-ui/icons';
import {
  Box,
  Button,
  Center,
  Divider,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import {
  CQReactTable,
  TextInput,
} from '@prasanna-transcend/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppColors from '../../../../constants/AppColors';
import ROUTES from '../../../../constants/Routes';
import { FONT_FAMILY } from '../../../../constants/Theme';
import departmentDashboardRepository from '../../../../repositories/DepartmentRepository';
import { replaceRoute, toTitleCase } from '../../../../utils/common.utils';
import { defaultReactTableProp } from '../../../../utils/my_audits.utils';
import { priceConverter } from '../../../../utils/priceConverter';

const columnHelper = createColumnHelper();

const DepartmentsTable = ({ isLoading, physiciansData, setOrdering }) => {
  const { departmentId, hospitalId } = useParams();

  const navigate = useNavigate();

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('provider', {
        header: () => 'Provider Name',
        id: 'name',
        cell: info => {
          const physicianFirstName = info.row.original.provider?.first_name;
          const physicianLastName = info.row.original.provider?.last_name;
          return (
            <Text>
              {toTitleCase(`${physicianFirstName} ${physicianLastName}`)}
            </Text>
          );
        },
      }),
      columnHelper.accessor('total_uploads', {
        header: () => 'Uploads',
        cell: info => {
          return <Text>{info?.renderValue()}</Text>;
        },
      }),

      columnHelper.accessor('chart_accuracy', {
        id: 'chart_accuracy',
        header: () => <span>Chart Accuracy</span>,
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('cq_rev_opp', {
        header: () => 'RI Rev. Opp',
        id: 'cq_rev_opp',
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return priceConverter(cellValue);
        },
      }),
      columnHelper.accessor('organization_rank', {
        header: () => 'Organization Rank',
        cell: info => {
          return <Text>{info?.renderValue()}</Text>;
        },
      }),
      columnHelper.accessor('provider', {
        header: () => 'Action',
        id: 'action',
        cell: info => {
          const physicianId = info.row.original.provider?.provider_id;
          return (
            <Button
              size={'sm'}
              bg={AppColors.secondary}
              color={'white'}
              borderRadius="3xl"
              onClick={() => {
                let route = replaceRoute(
                  ROUTES.DEPARTMENT_PHYSICIAN_DASHBOARD,
                  {
                    physicianId: physicianId,
                  }
                );
                if (departmentId && hospitalId && physicianId) {
                  route = replaceRoute(ROUTES.PHYSICIAN_DASHBOARD, {
                    departmentId,
                    hospitalId,
                    physicianId: physicianId,
                  });
                }

                if (departmentId && physicianId && !hospitalId) {
                  route = replaceRoute(ROUTES.HOSPITAL_PHYSICIAN_DASHBOARD, {
                    departmentId,
                    physicianId: physicianId,
                  });
                }

                navigate(route);
              }}
              px={10}
              fontWeight="normal"
              fontSize={'sm'}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.white,
                color: AppColors.secondary,
                outline: `2px solid ${AppColors.secondary}`,
              }}
            >
              View
            </Button>
          );
        },
      }),
    ],
    // eslint-disable-next-line
    [physiciansData]
  );

  const getTableCellProps = (cell, data) => {
    const startingHeaderId = ['name'];

    return {
      fontFamily: FONT_FAMILY.ptSans,
      textAlign: startingHeaderId.includes(data.column.id) ? 'none' : 'center',
      fontWeight: 'bold',
      color: AppColors.secondary,
    };
  };

  function getHeaderCellProps(header) {
    const startingHeaderId = ['name'];
    return {
      textTransform: 'none',
      fontFamily: FONT_FAMILY.ptSans,
      fontWeight: 'bold',
      color: AppColors.black,
      justifyContent: startingHeaderId.includes(header.id)
        ? 'flex-start'
        : 'center',
    };
  }
  return (
    <Box
      id="fixedTableHeader"
      maxHeight={'400px'}
      overflowY={'scroll'}
      className="tableWraper"
    >
      <CQReactTable
        isLoading={isLoading}
        data={physiciansData}
        columns={columns}
        {...defaultReactTableProp}
        getHeaderCellProps={header => {
          return {
            ...getHeaderCellProps(header),
          };
        }}
        getCellProps={getTableCellProps}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />

      {physiciansData?.length === 0 && (
        <Center>There is no data to display</Center>
      )}
    </Box>
  );
};

function DPhysicianTable({
  defaultStartDate,
  defaultEndDate,
  endDate,
  startDate,
  filter,
}) {
  const { departmentId } = useParams();
  const [isFetching, ifState] = useBoolean(false);
  const [physiciansData, setPhysiciansData] = useState(null);
  const [search, setSearch] = useState('');
  const [ordering, setOrdering] = useState('');

  const renderTable = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <DepartmentsTable
          isLoading={isFetching}
          physiciansData={physiciansData}
          setOrdering={setOrdering}
        />
      </ScaleFade>
    );
  };

  const fetchPhysicians = async () => {
    try {
      ifState.on();
      const filterParams = {};
      // filterParams.filter = filter.toLowerCase()
      // if (filter?.toLowerCase() === 'custom') {
      //   setFilters(
      //     filterParams,
      //     dateFormat(defaultStartDate, 'yyyy-MM-dd'),
      //     dateFormat(defaultEndDate, 'yyyy-MM-dd'),
      //     dateFormat(startDate, 'yyyy-MM-dd'),
      //     dateFormat(endDate, 'yyyy-MM-dd')
      //   );
      //   delete filterParams.filter
      // }
      filterParams.department_id = departmentId;

      if (search) filterParams.search = search;
      if (ordering) filterParams.ordering = ordering;
      const response = await departmentDashboardRepository.getPhysicians(
        filterParams
      );
      return setPhysiciansData(response);
    } catch (error) {
    } finally {
      ifState.off();
    }
  };

  React.useEffect(() => {
    fetchPhysicians();
    // eslint-disable-next-line
  }, [ordering, search]);

  const renderFailedPage = () => {
    return (
      <VStack m={4} justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isFetching) {
      return renderLoader();
    } else if (!physiciansData) {
      return renderFailedPage();
    } else {
      return renderTable();
    }
  };

  return (
    <Box
      marginTop={3}
      width={'100%'}
      bg={AppColors.white}
      padding={4}
      borderRadius={'lg'}
      shadow={'md'}
    >
      <HStack width={'100%'} justifyContent={'space-between'} mb={3}>
        <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
            Provider
        </Text>
        <Box>
          <TextInput
            borderRadius={'md'}
            onChange={e => setSearch(e.target.value)}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                fetchPhysicians();
              }
            }}
            si
            rightIconProps={{ zIndex: 0 }}
            placeholder="Search Providers"
            rightIcon={<SearchIcon color={AppColors.gray} />}
          />
        </Box>
      </HStack>
      <Divider />
      {renderMainContent()}
    </Box>
  );
}

export default DPhysicianTable;
