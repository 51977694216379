import { useBoolean } from '@chakra-ui/react';
import React from 'react';
import { useParams } from 'react-router-dom';
import ChartAccuracyComponents from '../../../../components/dashboard_components/ChartAccuracyComponents';
import { CUSTOM } from '../../../../constants/constants';
import hospitalDashboardRepository from '../../../../repositories/HospitalRepository';
import { dateFormat, setFilters } from '../../../../utils/common.utils';

function HChartAccuracy({
  defaultStartDate,
  defaultEndDate,
  startDate,
  endDate,
  filter,
}) {
  const { hospitalId } = useParams();
  const [isFetching, ifState] = useBoolean(false);
  const [cqScore, setCqScore] = React.useState(null);

  const selectedDates = JSON.parse(
    window.localStorage.getItem('customDateFilter')
  );

  const setCustomDates = filterParams => {
    for (const prop in selectedDates) {
      filterParams[prop] = dateFormat(
        new Date(selectedDates[prop]),
        'yyyy-MM-dd'
      );
    }
  };

  const fetchCqScore = async () => {
    try {
      ifState.on();
      const filterParams = {};
      filterParams.filter = filter.toLowerCase();

      if (filter === CUSTOM.toLowerCase()) {
        setCustomDates(filterParams);
        // setFilters(
        //   filterParams,
        //   dateFormat(defaultStartDate, 'yyyy-MM-dd'),
        //   dateFormat(defaultEndDate, 'yyyy-MM-dd'),
        //   dateFormat(startDate, 'yyyy-MM-dd'),
        //   dateFormat(endDate, 'yyyy-MM-dd')
        // );
        delete filterParams.filter;
      }
      filterParams.hospital_id = hospitalId;

      const response = await hospitalDashboardRepository.getHospitalCqscore(
        filterParams
      );
      setCqScore(response);
      return response;
    } catch (error) {
      setCqScore(null);
    } finally {
      ifState.off();
    }
  };

  React.useEffect(() => {
    fetchCqScore();
    // eslint-disable-next-line
  }, [defaultStartDate, defaultEndDate, startDate, endDate]);

  const uiStartDate = dateFormat(defaultStartDate, 'MM/dd/yyyy');
  const uiEndDate = dateFormat(defaultEndDate, 'MM/dd/yyyy');

  return (
    <ChartAccuracyComponents
      isFetching={isFetching}
      cq_score={cqScore}
      filter={filter}
      defaultStartDate={uiStartDate}
      defaultEndDate={uiEndDate}
    />
  );
}

export default HChartAccuracy;
