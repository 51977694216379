import AppColors from '../../constants/AppColors';

export const AUDIT_SHEET_HEADER = [
  'Encounter #',
  'Rendering',
  'Enc Dt',
  'Billed Code',
  'RVU',
  '$ value',
  'Response',
  'Agree',
  'Disagree',
  'Audited Code',
  'RVU',
  '$ value',
  'Notes',
];
export const BATCH_AUDIT_SHEET_HEADER = ['File ID #', ...AUDIT_SHEET_HEADER];
export const AUDIT_SHEET_KEY = [
  'file_name',
  'encounter_no',
  'rendering',
  'enc_dt',
  'srvcs_no',
  'provider_rvu',
  'provider_dollar_value',
  'response',
  'agree',
  'disagree',
  'audited_code',
  'audited_rvu',
  'audited_dollar_value',
  'notes',
];
export const STATUS = ['RESOLVED', 'RE-OPENED', 'REBUTTAL-RESOLVED'];
export const STATUS_MESSAGE = {
  'RE-OPENED': 'Re-opened',
  RESOLVED: 'Marked as resolved',
  'REBUTTAL-RESOLVED': 'Marked as resolved',
};
export const RESOLVED_STATUS = ['RESOLVED', 'REBUTTAL-RESOLVED'];
export const REBUTTAL_STATUS = 'REBUTTAL';
export const OPEN_STATUS = 'OPEN';

export const defaultStyle = {
  control: {
    backgroundColor: '#fff',
    fontSize: 14,
    fontWeight: 'normal',
  },

  '&multiLine': {
    control: {
      fontFamily: 'pt sans',
      minHeight: 35,
    },
    highlighter: {
      padding: 9,
      border: '1px solid transparent',
    },
    input: {
      padding: 9,
      border: '1px solid silver',
      borderRadius: '10px',
    },
  },

  suggestions: {
    list: {
      backgroundColor: 'white',
      fontSize: 14,
      fontWeight: 'bold',
      minWidth: '250px',
      borderRadius: '10px',
      color: AppColors.secondary,
      maxHeight: '200px',
      overflowY: 'scroll',
    },
    item: {
      padding: 10,
      // borderBottom: '1px solid rgba(0,0,0,0.15)',
      '&focused': {
        backgroundColor: '#cee4e5',
      },
    },
  },
};

export const NUMERIC_KEYS = [8, 9, 13, 46, 116, 123, 189, 190, 191];
export const RENDERING_AS_COL = 1;
export const DOLLAR_COL = [5, 11];
export const AGREE_DISAGREE_COL = [7, 8];
export const RESPONSE_AS_COL = 6;

export const BATCH_RENDERING_AS_COL = 2;
export const BATCH_DOLLAR_COL = [6, 12];
export const BATCH_AGREE_DISAGREE_COL = [8, 9];
export const BATCH_RESPONSE_AS_COL = 7;

export const AUDIT_COLUMN_HEADER = {
  ENCOUTER_NO: 'encounter_no',
  RENDERING: 'rendering',
  ENC_DT: 'enc_dt',
  SRVCS_NO: 'srvcs_no',
  PROVIDER_RUV: 'provider_rvu',
  PROVIDER_DOLLER_VALUE: 'provider_dollar_value',
  RESPONSE: 'response',
  AGREE: 'agree',
  DISAGREE: 'disagree',
  AUDITED_CODE: 'audited_code',
  AUDITED_RUV: 'audited_rvu',
  AUDITOR_DOLLER_VALUE: 'audited_dollar_value',
  NOTES: 'notes',
  // NEW COLUMNS
  PROVIDER_EDUCATION: 'providerEducation',
  NUMBER_COMPLEXITY: 'numberAndComplexity',
  AMOUNT_COMPLEXITY: 'amountAndComplexity',
  MORTALITY_OF_PATIENT: 'mortalityOfPatientManagement',

  // adding dynmaically
  BILLED_CPT: 'billed_cpt',
  BILLED_MODIFIER: 'billed_modifier',
  AUDITED_ICD: 'audited_icd',
  AUDITED_CPT: 'audited_cpt',
  AUDITED_MODIFIER: 'audited_modifier',
};

export const dynamicAuditColumnHeader = {
  file_name: 'File Id#',

  encounter_no: 'Encounter #',
  rendering: 'Rendering',
  enc_dt: 'Enc Dt',

  srvcs_no: 'Billed CPT',
  billed_icd: 'Billed ICD',
  billed_modifier: 'Billed Modifier',


  provider_rvu: 'RVU',
  provider_dollar_value: '$ value',
  response: 'Response',

  agree: 'Agree',
  disagree: 'Disagree',

  audited_cpt: 'Audited CPT', // audited code
  audited_icd: 'Audited ICD',
  audited_modifier: 'Audited Modifier',

  audited_rvu: 'RVU',
  audited_dollar_value: '$ Value',
  notes: 'Notes',

  // NEW COLUMNS
  provider_education: 'Provider Education',
  number_complexity: 'Number & Complexity',
  amount_complexity: 'Amount & Complexity',
  risk_complications: 'Mortality of PatientManagement',
};

export const columnWidth = {
  file_name: 0.15,

  encounter_no: 0.15,
  rendering: 0.15,
  enc_dt: 0.15,

  billed_icd: 0.15,
  srvcs_no: 0.15,
  billed_modifier: 0.15,

  provider_rvu: 0.15,
  provider_dollar_value: 0.15,

  response: 0.15,
  agree: 0.15,
  disagree: 0.15,

  audited_code: 0.15,

  audited_rvu: 0.15,

  audited_dollar_value: 0.15,
  notes: 0.15,

  // NEW COLUMNS
  provider_education: 0.15,
  number_complexity: 0.15,
  amount_complexity: 0.15,
  risk_complications: 0.15,

  audited_icd: 0.15,
  audited_cpt: 0.15,

  audited_modifier: 0.15,
};

export const columnType = {
  file_name: 'text',

  encounter_no: 'numeric',
  rendering: 'audit_sheet.dropdown_renderer',
  enc_dt: 'date',
  srvcs_no: 'text',
  billed_cpt: 'text',
  provider_rvu: 'numeric',
  provider_dollar_value: 'audit_sheet.currency_renderer',

  response: 'text',
  agree: 'text',
  disagree: 'text',
  audited_code: 'text',
  audited_rvu: 'numeric',

  audited_dollar_value: 'text',
  notes: 'text',

  // NEW COLUMNS
  provider_education: 'text',
  number_complexity: 'text',
  amount_complexity: 'text',
  risk_complications: 'text',

  billed_modifier: 'text',
  audited_icd: 'text',
  audited_cpt: 'text',
  audited_modifier: 'text',
};
