import { List, ListItem, SlideFade, Text, VStack } from '@chakra-ui/react';
import { useCQMediaQuery } from '@prasanna-transcend/code-quick-components';
import React from 'react';
import { ListIcon, PrimaryUploadIcon } from '../../constants/IconData';
import { FONT_FAMILY } from '../../constants/Theme';

function UploadDocumentTutorial({ slideRight }) {
  const renderFontSize = useCQMediaQuery({
    lg: '36px',
    md: '26',
    sm: '20',
  });

  const renderPadding = useCQMediaQuery({
    lg: '30px',
    md: '20px',
    sm: '15px',
  });

  return (
    <>
      <SlideFade in={true} offsetX={slideRight ? '80px' : '-80px'}>
        <VStack padding={renderPadding}>
          <PrimaryUploadIcon />
          <Text
            textAlign={'center'}
            fontFamily={FONT_FAMILY.baiJamurjee}
            fontSize={renderFontSize}
            mb={5}
          >
            Upload your documents
          </Text>

          <List spacing={'40px'}>
            <ListItem display={'flex'} alignItems={'baseline'}>
              <ListIcon />
              <Text paddingLeft={3} fontWeight={500}>
                To get started, drag & drop files from your desktop, or just use
                the Select Files button to browse to the files on your computer.
              </Text>
            </ListItem>
            <ListItem display={'flex'} alignItems={'baseline'}>
              <ListIcon />
              <Text fontWeight={500} paddingLeft={3}>
                You can always add more files as needed, and you can remove
                files by clicking the red X.
              </Text>
            </ListItem>
            <ListItem display={'flex'} alignItems={'baseline'}>
              <ListIcon />
              <Text fontWeight={500} paddingLeft={3}>
                When you are ready to upload, click Submit to send your
                documents to be coded!
              </Text>
            </ListItem>
          </List>
        </VStack>
      </SlideFade>
    </>
  );
}

export default UploadDocumentTutorial;
