/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Circle,
  FormControl,
  FormLabel,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Text,
  SimpleGrid,
  Spinner,
  Checkbox,
} from '@chakra-ui/react';
import {
  FormTextInput,
  useCQMediaQuery,
} from '@prasanna-transcend/code-quick-components';
import React, { useContext, useEffect, useState } from 'react';
import { useFieldArray, useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import AppColors from '../../../../constants/AppColors';
import { FONT_FAMILY } from '../../../../constants/Theme';
import SelectFormModal from '../../../../components/select_form/SelectFormModal';
import FormMultiSelectModal from '../../../../components/form/FormMultiSelect';
import { MyAccountContext } from '../../../../providers/MyAccountProvider';
import { separateIdNames } from '../../../../utils/separateIdNames';
import { CirclePlusIcon } from '../../../../constants/IconData';
import SpecialtySelect from '../../../../components/select_form/SpecialtySelect';
import authRepository from '../../../../repositories/AuthRepository';
import SearchDropDown from '../../../../components/select_form/SearchDropDown';

const AddHospitalScheme = yup.object({
  name: yup
    .string()
    .required('Name is a required field')
    .matches(/^[aA-zZ\s]+$/, 'Only alphabets are allowed in this field'),
  address: yup.string(),
  account_contacts: yup.array().of(
    yup.object().shape({
      first_name: yup
        .string()
        .matches(/^[aA-zZ\s]+$/, 'Only alphabets allowed'),
      last_name: yup.string().matches(/^[aA-zZ\s]+$/, 'Only alphabets allowed'),
      email : yup.string().email('Enter valid email').required('Enter email')
    })
  ),
  patients_per_month: yup
  .number()
  .positive('Number must be greater than zero')
  .required('Enter the patient count')
  .typeError('Enter a valid number'),
});

const GRID_SPACING = 5;

function createNewContact() {
  return {
    first_name: '',
    last_name: '',
    email: '',
    is_primary: false,
  };
}

const AddHospitalForm = ({
  isOpen,
  onClose,
  hospitalId,
  editHospitalId,
  accountId,
  healthSystemName,
  hospitalList,
  setHospitalList,
}) => {
  // context
  const myAccountContext = useContext(MyAccountContext);
  const [selectedPrimaryAccountCheckBoxIndex, spacbiState] = useState([]);
  const [dataValue, setDataValue] = useState({});

  const blockScrollOnMount = useCQMediaQuery({
    sm: false,
  });

  const form = useForm({
    resolver: yupResolver(AddHospitalScheme),
    defaultValues: {
      account_contacts: [createNewContact()],
    },
  });
  const { control, handleSubmit, getValues, setValue, reset } = form;

  const contactField = useFieldArray({
    control,
    name: 'account_contacts',
  });

  const handleCheckBox = (event, index) => {
    let checkedIndexes = [...selectedPrimaryAccountCheckBoxIndex];
    if (event.target.checked) {
      checkedIndexes.push(index);
    } else {
      checkedIndexes = checkedIndexes.filter(d => d !== index);
    }
    spacbiState(checkedIndexes);
  };

  const promiseOptions = async inputValue => {
    return authRepository.getSpecialtyOptions({ search: inputValue });
  };

  useEffect(() => {
    if (dataValue && Object.keys(dataValue).length) {
      if (
        myAccountContext.specialtyOptions.findIndex(
          d => d.id === dataValue.id && d.label === dataValue.label
        ) === -1
      ) {
        myAccountContext.setSpecialtyOptions(prev => [...prev, dataValue]);
      }
    }
  }, [dataValue]);

  useEffect(() => {
    loadOptions();
  }, []);

  /** --- DATA FETCHING --- */
  function loadOptions() {
    myAccountContext.fetchEHROptions();
    myAccountContext.fetchInsuranceOptions();
    myAccountContext.fetchSpecialtyOptions();
  }

  useEffect(() => {
    getValues('account_contacts')?.forEach((dt, index) => {
      if (selectedPrimaryAccountCheckBoxIndex.includes(index)) {
        setValue(`account_contacts.${index}.is_primary`, true);
      } else {
        setValue(`account_contacts.${index}.is_primary`, false);
      }
    });
  }, [selectedPrimaryAccountCheckBoxIndex]);

  /** --- DATA HANDLING --- */
  async function submitAddAccount(formData) {
    const hospitalBody = {
      name: formData.name,
      health_system_name: healthSystemName,
      patients_per_month: formData.patients_per_month,
      specialties: separateIdNames(formData.specialties, 'new_spec_name'),
      insurance: separateIdNames(formData.insurance, 'new_ins_name'),
      ehr: separateIdNames(formData.ehr, 'new_ehr_name'),
      account_contact: formData.account_contacts,
      health_system: accountId,
    };
  const res = await myAccountContext.createHospital(hospitalBody);
      // let hospitalData = [...hospitalList, res];
      // setHospitalList(hospitalData);
      if(res){
        reset({});
        onClose();
      }
      
   
  }

  /** --- DATA RENDERING --- */
  function renderAddIcon(_props) {
    return (
      <HStack mr={12} _hover={{ cursor: 'pointer' }} {..._props}>
        <Text fontSize={'sm'} fontWeight={'bold'} color={AppColors.secondary}>
          Add
        </Text>
        <CirclePlusIcon style={{ width: 18, height: 18 }} />
      </HStack>
    );
  }

  function renderAddContactForm(field, index) {
    return (
      <React.Fragment key={field.id}>
        <SimpleGrid
          columns={{ sm: 1, md: 4 }}
          spacing={GRID_SPACING}
          alignItems={'center'}
          mb="1"
        >
          <FormTextInput
            height="50px"
            name={`account_contacts.${index}.first_name`}
            control={control}
            placeholder={'First Name'}
            size="md"
          />
          <FormTextInput
            height="50px"
            name={`account_contacts.${index}.last_name`}
            control={control}
            placeholder={'Last Name'}
            size="md"
          />
          <FormTextInput
            height="50px"
            name={`account_contacts.${index}.email`}
            control={control}
            placeholder={'Email'}
            size="md"
          />
          {index === 0 && <br />}
          <Text
            onClick={() => contactField.remove(index)}
            cursor={'pointer'}
            color={AppColors.secondary}
            fontSize="sm"
            display={index > 0 ? 'block' : 'none'}
          >
            Remove
          </Text>
          <Box display={'flex'} alignItems={'center'} gap={'5%'} mt={-2} mb={1}>
            <Checkbox
              onChange={e => handleCheckBox(e, index)}
              value={index.toString()}
            >
              Primary Account
            </Checkbox>
          </Box>
        </SimpleGrid>
      </React.Fragment>
    );
  }

  function renderContacts() {
    return <Box>{contactField.fields.map(renderAddContactForm)}</Box>;
  }

  const renderSpecialtySelect = () => {
    return (
      <SpecialtySelect
        dataValue={dataValue}
        setDataValue={setDataValue}
        promiseOptions={promiseOptions}
      />
    );
  };

  function renderSpecialtiesForModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        addNewText={'Other Specialty Name'}
        subTitle={'Select Account Specialties'}
        isFetching={myAccountContext.isFetchingSpecialtyOption}
        isCreating={myAccountContext.isCreatingSpecialtyOption}
        onCreateNewOption={myAccountContext.createSpecialtyOption}
        renderSpecialtySelect={renderSpecialtySelect}
        dataValue={dataValue}
      />
    );
  }

  function renderInsuranceFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        addNewText={'Add Other Insurance'}
        subTitle={'Select Insurance'}
        isFetching={myAccountContext.isFetchingInsurance}
        isCreating={myAccountContext.isCreatingInsurance}
        onCreateNewOption={myAccountContext.createInsuranceOption}
      />
    );
  }

  function renderEHRFormModal(inputProps) {
    return (
      <SelectFormModal
        {...inputProps}
        title={'Add Health System'}
        subTitle={'Select EHR'}
        addNewText={'Add Other EHR'}
        isFetching={myAccountContext.isFetchingEHR}
        isCreating={myAccountContext.isCreatingEHR}
        onCreateNewOption={myAccountContext.createEHROptions}
      />
    );
  }

  return (
    <React.Fragment>
      <Modal
        size={'4xl'}
        isOpen={isOpen}
        onClose={onClose}
        blockScrollOnMount={blockScrollOnMount ?? true}
      >
        <ModalOverlay style={{ backgroundColor: '#001A41' }} />
        {myAccountContext.isGettingHospitalData ? (
          <ModalContent width={'100'}>
            <Spinner size="xl" />
          </ModalContent>
        ) : (
          <ModalContent mx={4} px={8}>
            <ModalHeader
              fontFamily={FONT_FAMILY.baiJamurjee}
              textAlign={'center'}
            >
              {hospitalId ? 'Add Hospital' : 'Edit Hospital'}
            </ModalHeader>
            <Box
              style={{
                zIndex: '1',
              }}
              position={'absolute'}
              top={-10}
              right={-3}
            >
              <Circle>
                <ModalCloseButton
                  size={'sm'}
                  style={{ color: AppColors.white }}
                  backgroundColor={'#8894A6'}
                  p={3}
                  onClick={onClose}
                  borderRadius={50}
                />
              </Circle>
            </Box>
            <ModalBody pb={6}>
              <SimpleGrid
                columns={{ sm: 1, md: 2, lg: 2 }}
                spacing={GRID_SPACING}
                mb={GRID_SPACING}
              >
                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Hospital Name
                  </FormLabel>
                  <FormTextInput
                    height="50px"
                    name="name"
                    control={control}
                    placeholder={'Hospital Name'}
                    // leftIcon={<HealthSystemIcon />}
                    size="md"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Health System Name
                  </FormLabel>
                  <FormTextInput
                    height="50px"
                    name="health_system_name"
                    value={healthSystemName}
                    disabled={true}
                    control={control}
                    size="md"
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Specialties
                  </FormLabel>
                  <FormMultiSelectModal
                    control={control}
                    name={'specialties'}
                    placeholder={'Select Specialty'}
                    options={myAccountContext.specialtyOptions}
                    renderFormModal={renderSpecialtiesForModal}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    Insurance
                  </FormLabel>
                  <FormMultiSelectModal
                    control={control}
                    name={'insurance'}
                    placeholder={'Select Insurance'}
                    options={myAccountContext.insuranceOptions}
                    renderFormModal={renderInsuranceFormModal}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    EHR
                  </FormLabel>
                  <FormMultiSelectModal
                    control={control}
                    name={'ehr'}
                    placeholder={'Select EHR'}
                    options={myAccountContext.ehrOptions}
                    renderFormModal={renderEHRFormModal}
                  />
                </FormControl>

                <FormControl>
                  <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                    No. of Patients per month
                  </FormLabel>
                  <FormTextInput
                    height="50px"
                    name="patients_per_month"
                    control={control}
                    placeholder={'Type here'}
                    // leftIcon={<PersonIcon style={{ width: 16, height: 16 }} />}
                    size="md"
                  />
                </FormControl>
              </SimpleGrid>

              <FormControl>
                <FormLabel fontSize={'xs'} fontWeight={'bold'}>
                  Account Contact
                </FormLabel>
                <Box>
                  <Box
                    maxHeight={175}
                    overflow="scroll"
                    overflowX={'hidden'}
                    sx={{
                      '&::-webkit-scrollbar': {
                        width: '10px',
                      },
                      '&::-webkit-scrollbar-track': {
                        boxShadow: 'inset 0 0 10px lightGrey',
                        width: '10px',
                        borderRadius: '10px',
                      },
                      '&::-webkit-scrollbar-thumb': {
                        background: 'grey',
                        borderRadius: '10px',
                      },
                    }}
                  >
                    {renderContacts()}
                  </Box>
                  {renderAddIcon({
                    onClick: () => {
                      contactField.append(createNewContact());
                    },
                  })}
                </Box>
              </FormControl>
            </ModalBody>

            <ModalFooter justifyContent={'center'}>
              <Button
                mr={3}
                borderRadius={'full'}
                px={12}
                borderWidth={2}
                borderColor={AppColors.secondary}
                onClick={onClose}
                variant={'outline'}
              >
                <Text fontWeight={'normal'} fontSize={'sm'}>
                  Cancel
                </Text>
              </Button>
              <Button
                bgColor={AppColors.primary}
                borderRadius={'full'}
                px={12}
                onClick={handleSubmit(submitAddAccount)}
                isLoading={myAccountContext.isDepartmentLoading}
              >
                <Text color={'white'} fontSize={'sm'}>
                  {hospitalId ? 'Add' : 'Update'}
                </Text>
              </Button>
            </ModalFooter>
          </ModalContent>
        )}
      </Modal>
    </React.Fragment>
  );
};

export default AddHospitalForm;
