import { Box, Button, HStack, Text } from '@chakra-ui/react';
import { useNavigate } from 'react-router-dom';
import { TriangleIcon, TriangleRedIcon } from '../constants/IconData';
import ROUTES from '../constants/Routes';
import { FONT_FAMILY } from '../constants/Theme';
import { convertToPositiveDollar } from '../utils/common.utils';

const RevenueTile = ({ diff, description, label, dollarValue, filterParams }) => {
  const navigate = useNavigate();
  return (
    <Box alignItems={'left'} >
      <HStack mt={4}>
        {diff >= 0 ? <TriangleIcon /> : <TriangleRedIcon />}
        <Text
          fontSize={'xs'}
          fontFamily={FONT_FAMILY.ptSans}
          color={diff >= 0 ? 'black' : 'red'}
        >
          {convertToPositiveDollar(dollarValue)}
        </Text>
      </HStack>
      <Text
        fontFamily={FONT_FAMILY.ptSans}
        fontSize={'5xl'}
        fontWeight="bold"
        mt={3}
      >
        {label}
      </Text>
      <Text fontFamily={FONT_FAMILY.ptSans} fontSize={'sm'} mt={3}>
        {description}
      </Text>
      <Button
        borderWidth={1}
        borderColor="black"
        bg={'white'}
        my={5}
        fontFamily={FONT_FAMILY.ptSans}
        fontWeight="normal"
        _hover={{ bg: 'white' }}
        fontSize={'sm'}
        onClick={() => {
          navigate(ROUTES.CHARTS, { state: filterParams });
        }}
      >
        View Charts
      </Button>
    </Box>
  );
};
export default RevenueTile;
