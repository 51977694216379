/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, HStack, Text } from '@chakra-ui/react';
import {
  CQReactTable,
  PrimaryButton,
  useBoolean,
} from '@prasanna-transcend/code-quick-components';

import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
// import '../style.css';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../../utils/my_audits.utils';
import { MyChartContext } from '../../../../providers/MyChartProvider';
import AppColors from '../../../../constants/AppColors';
import { EyeAvatarIcon } from '../../../../constants/IconData';
import BatchFileModal from '../../../dashboard/components/BatchFileModal';
import { STATUS } from '../../../../constants/constants';
import ROUTES from '../../../../constants/Routes';
import { useNavigate } from 'react-router-dom';
import { priceConverter } from '../../../../utils/priceConverter';
import { replaceRoute } from '../../../../utils/common.utils';

const columnHelper = createColumnHelper();

const ArchivedCharts = () => {
  const myChartContext = useContext(MyChartContext);
  const [ordering, setOrdering] = useState('');
  const {
    archivedCharts,
    isArchivedChartLoading,
    getArchivedCharts,
    filterParams,
  } = myChartContext;
  const [currentBatch, setCurrentBatch] = useState({});
  const [isModalOpen, imoState] = useBoolean(false);
  const navigate = useNavigate();

  useEffect(() => {
    getArchivedCharts.loadAPI();
  }, [filterParams, getArchivedCharts.showOrdering]);

  useEffect(() => {
    getArchivedCharts.setParams({ ordering });
  }, [ordering]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('upload_id', {
        header: () => 'Upload #',
        id: 'upload_id',
        cell: info => {
          const chartData = info.row.original;
          const cellValue = info.getValue();
          return (
            <Text
              _hover={{ textDecoration: 'underline' }}
              cursor={'pointer'}
              onClick={() => {
                const batchDashboard = replaceRoute(ROUTES.BATCH_DASHBOARD, {
                  batchId: cellValue,
                });
                navigate(batchDashboard, {
                  state: { chartData: chartData },
                });
              }}
            >
              {info.renderValue()}
            </Text>
          );
        },
      }),
      columnHelper.accessor('cq_score', {
        id: 'cq_score',
        header: () => <span>RI Score</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor(row => row.upload_date, {
        id: 'upload_date',
        header: () => <span>Upload Date</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return format(new Date(cellValue), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        id: 'status',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return STATUS[cellValue];
        },
      }),
      columnHelper.accessor('opporturnity', {
        header: () => 'Opportunity',
        id: 'opporturnity',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return (
            <>
              {cellValue > 0 ? (
                <Text color={'#77C586'}>{priceConverter(cellValue)}</Text>
              ) : (
                <Text color={'red'}>{`-${priceConverter(-cellValue)}`}</Text>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor('charts', {
        header: () => 'No. of Charts',
        id: 'charts',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('urgency', {
        header: () => 'Urgency',
        id: 'urgency',
        cell: info => {
          const cellValue = info.getValue();

          return cellValue ?? <Text color={AppColors.gray}>N/A</Text>;
        },
      }),

      columnHelper.accessor(row => row.upload_id, {
        id: 'actions',
        header: () => '',
        enableSorting: false,
        cell: info => {
          const cellValue = info.getValue();

          return (
            <HStack justifyContent={'center'}>
              <PrimaryButton
                bg={AppColors.secondary}
                fontSize={'14px'}
                fontWeight="normal"
                size="md"
                borderRadius="3xl"
                px="10"
                onClick={() => {
                  navigate(
                    ROUTES.VIEW_AUDIT_SHEET.replace(':batchId', cellValue)
                  );
                }}
                _hover={{
                  transition: 'all .1s ease',
                  bgColor: AppColors.white,
                  color: AppColors.secondary,
                  outline: `2px solid ${AppColors.secondary}`,
                }}
              >
                View
              </PrimaryButton>
              <EyeAvatarIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCurrentBatch(info.row.original);
                  imoState.on();
                }}
              />
            </HStack>
          );
        },
      }),
    ],
    [isArchivedChartLoading]
  );

  function handlePreviousPage() {
    getArchivedCharts.loadPrevious();
  }

  function handleNextPage() {
    getArchivedCharts.loadNext();
  }

  function handleSizeChange(size) {
    getArchivedCharts({ size });
  }

  const getTableData = async () => {
    const res = await myChartContext.getFileObjectByBatchId(
      currentBatch.upload_id
    );
    return res;
  };

  return (
    <Box>
      <CQReactTable
        isLoading={isArchivedChartLoading}
        data={archivedCharts}
        columns={columns}
        {...defaultReactTableProp}
        {...paginationProps(getArchivedCharts)}
        onPreviousPage={handlePreviousPage}
        onNextPage={handleNextPage}
        onSizeChange={handleSizeChange}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />
      <BatchFileModal
        isOpen={isModalOpen}
        onClose={imoState.off}
        currentBatch={currentBatch}
        getTableData={getTableData}
        isLoading={myChartContext.isFileLoading}
      />
    </Box>
  );
};
export default ArchivedCharts;
