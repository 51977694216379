import { Box, List, ListItem, SlideFade, Text, VStack } from '@chakra-ui/react';
import { useCQMediaQuery } from '@prasanna-transcend/code-quick-components';
import React from 'react';
import AppColors from '../../constants/AppColors';
import { ListIcon, AccountSettingsIcon } from '../../constants/IconData';
import { FONT_FAMILY } from '../../constants/Theme';

function AccountSettingsTutorial({ slideRight }) {
  const renderFontSize = useCQMediaQuery({
    lg: '36px',
    md: '26',
    sm: '20',
  });

  const renderPadding = useCQMediaQuery({
    lg: '23px',
    md: '13px',
    sm: '10px',
  });
  return (
    <>
      <SlideFade in={true} offsetX={slideRight ? '80px' : '-80px'}>
        <VStack padding={renderPadding}>
          <AccountSettingsIcon />
          <Text
            textAlign={'center'}
            fontFamily={FONT_FAMILY.baiJamurjee}
            fontSize={renderFontSize}
            mb={5}
          >
            Account Settings
          </Text>

          <List spacing={'35px'}>
            {/* <ListItem display={'flex'} alignItems={'baseline'}>
              <ListIcon />
              <Text paddingLeft={3} fontWeight={500}>
                RevIntegrity's Free Trial provides you with 5 charts coded
                absolutely free! After your trial is up, click on My Account in
                the menu underneath your name and add your payment details.
              </Text>
            </ListItem> */}
            <ListItem display={'flex'} alignItems={'baseline'}>
              <ListIcon />
              <Text fontWeight={500} paddingLeft={3}>
                Made some changes to your practice? No problem! Click on
                Practice Info in the menu and you can make your edits.
              </Text>
            </ListItem>
            <ListItem display={'flex'} alignItems={'baseline'}>
              <ListIcon />
              <Text fontWeight={500} paddingLeft={3}>
                You're all set! Let's get started coding your charts!
              </Text>
            </ListItem>
          </List>
          <Box m={3} />
          <Text
            color={AppColors.primary}
            as="i"
            fontSize={'md'}
            fontWeight="bold"
          >
            Want to see the tutorial again?
          </Text>
          <Text
            color={AppColors.primary}
            as="i"
            fontSize={'md'}
            fontWeight="bold"
          >
            Go to Profile dropdown on the top right corner and click on Welcome
            Tutorial
          </Text>
        </VStack>
      </SlideFade>
    </>
  );
}

export default AccountSettingsTutorial;
