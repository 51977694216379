/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  ScaleFade,
  Text,
  VStack,
} from '@chakra-ui/react';
import { FormTextInput } from '@prasanna-transcend/code-quick-components';
import React, { useEffect, useContext } from 'react';
import AppColors from '../../../../../constants/AppColors';
import { CircleMarkIcon, MailIcon } from '../../../../../constants/IconData';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { AuthContext } from '../../../../../providers/AuthProvider';
import './provider.css';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../../constants/Routes';

function InputComponent({ setEmail, email }) {
  const authContext = useContext(AuthContext);
  const userCreateSchema = yup.object({
    email: yup.string().email().required('Email is required'),
    department_email: yup
      .string()
      .email()
      .required('Department Head Mail is required'),
  });
  const form = useForm({
    resolver: yupResolver(userCreateSchema),
  });
  const { control, handleSubmit, setValue } = form;

  const handleSaveData = async values => {
    await authContext.sentProviderMail(values);
    setEmail(values?.email);
  };

  return (
    <Flex
      flexDirection={'column'}
      justifyContent={'center'}
      alignItems={'center'}
    >
      <form onSubmit={handleSubmit(handleSaveData)}>
        <Flex mt={3} gap={5}>
          <FormTextInput
            name="department_email"
            control={control}
            id="department_email"
            leftIcon={<MailIcon />}
            leftIconProps={{ height: '50px' }}
            height="50px"
            size="md"
            placeholder="Department Head's email"
            onChange={e => {
              setValue('department_email', e.target.value);
            }}
          />

          <FormTextInput
            name="email"
            control={control}
            id="email"
            leftIcon={<MailIcon />}
            leftIconProps={{ height: '50px' }}
            height="50px"
            size="md"
            placeholder="Provider's email"
            onChange={e => {
              setValue('email', e.target.value);
            }}
          />
        </Flex>

        <HStack justifyContent={'center'}>
          <Button
            width={'190px'}
            height={'50px'}
            borderRadius={'full'}
            paddingInline={'20px'}
            isLoading={authContext.isMailSending}
            spinnerPlacement="end"
            loadingText="sending"
            bgColor={'transparent'}
            style={{ border: `2px solid ${AppColors.primary}` }}
            _hover={{ bg: 'none' }}
            _focus={{ border: 'none' }}
            bg={'#fff'}
            mt={3}
            variant="outline"
            type="submit"
          >
            Send
          </Button>
        </HStack>
      </form>
    </Flex>
  );
}

function SentMailComponent({ email }) {
  return (
    <Flex flexDirection={'column'} alignItems={'center'}>
      <VStack marginTop={'30px'}>
        <ScaleFade initialScale={0.1} in={true}>
          <CircleMarkIcon style={{ width: '28px', height: '28px' }} />
        </ScaleFade>
        <Text>
          Sent to <span style={{ fontWeight: '600' }}>{email}</span>
        </Text>
      </VStack>
    </Flex>
  );
}

function JoinCodeQuick({ renderProviderLogin, currentState, setEmail, email }) {
  const authContext = useContext(AuthContext);
  const navigate = useNavigate();

  useEffect(() => {
    if (!authContext.renderInputComponent) {
      authContext.ricState.on();
    }
  }, []);

  const renderComponent = () => {
    if (authContext.renderInputComponent) {
      return (
        <InputComponent
          // renderComponent={iicState}
          setEmail={setEmail}
          email={email}
        />
      );
    } else {
      return <SentMailComponent email={email} />;
    }
  };

  return (
    <>
      <Flex>
        <Flex width={'100vw'} justifyContent={'center'}>
          <Flex
            borderRadius={'xl'}
            p={3}
            height={'622px'}
            width={'966px'}
            m={2}
            flexDirection={'column'}
            alignContent={'space-between'}
            justifyContent={'space-between'}
            bg={'white'}
          >
            <Flex
              borderRadius={'md'}
              height={'114px'}
              bg={AppColors.loginFormBg}
              alignItems={'center'}
              justifyContent={'center'}
            >
              <Center color={AppColors.secondary} fontSize={'30px'}>
                Join RevIntegrity!
              </Center>
            </Flex>

            <VStack flexDirection={'column'}>
              <Box>
                <Center mb={2} fontSize={'18px'}>
                  {' '}
                  Hello!
                </Center>
                <Center textAlign={'center'} mb={2} fontSize={'18px'}>
                RevIntegrity does not currently support single provider models.
                </Center>
                <Center textAlign={'center'} mb={2} fontSize={'18px'}>
                  Enter your Department Head’s email, and we’ll send them a link
                  to join the RevIntegrity platform!
                </Center>
              </Box>
              {renderComponent()}
            </VStack>

            <Box mb={5}>
              <Center>
                Have a provider invite link?
                <span
                  onClick={() => {
                    // renderProviderLogin(currentState + 1);
                    navigate(ROUTES.LOGIN_SCREEN);
                  }}
                  style={{ color: '#023B93', cursor: 'pointer' }}
                >
                  {' '}
                  &nbsp;Click Here &nbsp;
                </span>
              </Center>
            </Box>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default JoinCodeQuick;
