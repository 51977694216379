/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Text,
  Circle,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  HStack,
} from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import {
  CQReactTable,
  PrimaryButton,
} from '@prasanna-transcend/code-quick-components';
import { defaultReactTableProp } from '../../../utils/my_audits.utils';
import { FONT_FAMILY } from '../../../constants/Theme';
import { createColumnHelper } from '@tanstack/react-table';
import AuditSheetFileViewer from '../../audit_sheet/components/AuditSheetFileViewer';
import ROUTES from '../../../constants/Routes';
import { useNavigate } from 'react-router-dom';
import { EyeAvatarIcon } from '../../../constants/IconData';

const columnHelper = createColumnHelper();

const BatchFileModal = ({
  isOpen,
  onClose,
  currentBatch,
  getTableData,
  isLoading,
}) => {
  const [tableData, setTableData] = useState([]);
  const getData = async () => {
    const res = await getTableData();
    setTableData(res);
  };

  useEffect(() => {
    if (currentBatch && Object.keys(currentBatch).length) {
      getData();
    }
  }, [currentBatch]);

  const navigate = useNavigate();
  const getTableCellProps = (cell, data) => {
    const startingHeaderId = ['name'];

    let color = 'black';
    if (data.column.id === 'date' || data.column.id === 'accuracy') {
      color = AppColors.gray;
    }
    return {
      fontFamily: FONT_FAMILY.ptSans,
      color: color,
      textAlign: startingHeaderId.includes(data.column.id) ? 'none' : 'center',
    };
  };

  function getHeaderCellProps(header) {
    const startingHeaderId = ['name'];
    return {
      textTransform: 'none',
      fontFamily: FONT_FAMILY.ptSans,
      fontWeight: 'bold',
      color: 'black',
      justifyContent: startingHeaderId.includes(header.id)
        ? 'flex-start'
        : 'center',
    };
  }

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('file_id', {
        header: () => 'File ID',
        id: 'file_id',
        cell: info => {
          return <Text>{info?.renderValue()}</Text>;
        },
      }),

      columnHelper.accessor('file_obj', {
        header: () => 'Actions',
        id: 'file_obj',
        enableSorting: false,

        cell: info => {
          return (
            <HStack justifyContent={'center'}>
              <PrimaryButton
                bg={AppColors.secondary}
                fontSize={'14px'}
                fontWeight="normal"
                size="md"
                borderRadius="3xl"
                px="10"
                onClick={() => {
                  navigate(
                    ROUTES.SINGLE_AUDIT_SHEET.replace(
                      ':uploadId',
                      info.row.original.id
                    )
                  );
                }}
              >
                View
              </PrimaryButton>
              <AuditSheetFileViewer
                auditData={info.row.original}
                icon={<EyeAvatarIcon style={{ cursor: 'pointer' }} />}
              />
            </HStack>
          );
        },
      }),
    ],
    // eslint-disable-next-line
    [tableData]
  );
  const renderTable = () => {
    return (
      <CQReactTable
        data={tableData}
        columns={columns}
        {...defaultReactTableProp}
        getHeaderCellProps={header => {
          return {
            ...getHeaderCellProps(header),
          };
        }}
        isLoading={isLoading}
        getCellProps={getTableCellProps}
      />
    );
  };
  return (
    <>
      <Modal isOpen={isOpen} size={'lg'} onClose={onClose} isCentered>
        <ModalOverlay bg={AppColors.modalOverlayColor} />
        <ModalContent alignItems={'center'} minW={'600px'} p={3}>
          <Box
            style={{
              zIndex: '1',
            }}
            position={'absolute'}
            top={-10}
            right={-10}
          >
            <Circle>
              <ModalCloseButton
                size={'sm'}
                style={{ color: AppColors.white }}
                backgroundColor={'#8894A6'}
                p={3}
                onClick={onClose}
                borderRadius={50}
              />
            </Circle>
          </Box>
          <Box overflowY={'scroll'} width={'100%'} maxH={'400px'}>
            <ModalBody width={'100%'} height={'100%'}>
              {renderTable()}
            </ModalBody>
          </Box>
        </ModalContent>
      </Modal>
    </>
  );
};

export default BatchFileModal;
