/* eslint-disable react-hooks/exhaustive-deps */
import {  FormLabel, GridItem, SimpleGrid } from '@chakra-ui/react';
import React, { useContext, useEffect } from 'react';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import { withProvider } from '../../../hoc/withProvider';
import MyAccountProvider, {
  MyAccountContext,
} from '../../../providers/MyAccountProvider';
import EditPracticeInfo from './EditPracticeInfo';
import CompliancePlan from './CompliancePlan';

const HospitalPracticeInfo = ({ id, user, status }) => {
  const myAccountContext = useContext(MyAccountContext);
  const currentUserPracticeInfo = myAccountContext.currentUserPracticeInfo;

  useEffect(() => {
    myAccountContext.praticeInfoHospital(id);
  }, [id]);

  return (
    <SimpleGrid
      width={'70%'}
      m={'auto'}
      my={4}
      columns={{ sm: 1, md: 1, lg: 2 }}
      spacing={10}
      fontSize={'xl'}
    >
      <GridItem>
        <FormLabel
          fontFamily={FONT_FAMILY.baiJamurjee}
          fontWeight={'bold'}
          fontSize={'xl'}
          color={AppColors.black}
          ml={7}
        >
          Hospital Info
        </FormLabel>
        {currentUserPracticeInfo && (
          <EditPracticeInfo
            profileData={currentUserPracticeInfo}
            id={id}
            user={user}
            status={status}
          />
        )}
      </GridItem>
      <GridItem>
      <CompliancePlan />
      </GridItem>
    </SimpleGrid>
  );
};

export default withProvider(MyAccountProvider, HospitalPracticeInfo);
