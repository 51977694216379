/* eslint-disable react-hooks/exhaustive-deps */
import {
  CheckIcon,
  LockIcon,
  SmallCloseIcon,
} from '@chakra-ui/icons';
import {
  Accordion,
  AccordionButton,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
  ButtonGroup,
  Circle,
  Editable,
  EditableInput,
  EditablePreview,
  Flex,
  FormLabel,
  Grid,
  GridItem,
  HStack,
  IconButton,
  Input,
  SimpleGrid,
  Stack,
  Text,
  useEditableControls,
} from '@chakra-ui/react';
import {
  FormPasswordInput,
  strengthChecker,
} from '@prasanna-transcend/code-quick-components';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useForm } from 'react-hook-form';
import { PasswordStrength } from '../../../components/PasswordStrength';
import AppColors from '../../../constants/AppColors';
import {
  EditIcon,
  EyeHiddenIcon,
  EyeIcon,
} from '../../../constants/IconData';
import { FONT_FAMILY } from '../../../constants/Theme';
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
import { MyAccountContext } from '../../../providers/MyAccountProvider';

export const CommonMyAccountComponents = ({ getData }) => {
  const [newPasswordStrength, setNewPasswordStrength] = useState(0);
  const [openAccordion, setOpenAccordion] = useState();

  const passwordRef = useRef();

  const myAccountContext = useContext(MyAccountContext);
  const currentUser = myAccountContext.currentUserType;

  useEffect(() => {
    myAccountContext.userTypeLookup();
  }, []);

  const handleSaveData = async userformData => {
    let payload = {};
    const formData = {
      new_password: userformData.new_password,
      current_password: userformData.current_password,
    };
    for (const prop in formData) {
      if (formData[prop]) {
        payload[prop] = formData[prop];
      }
    }


    getData(payload);

  };
  const userUpdateSchema = yup.object({
    current_password: yup.string().required('Must enter current password'),
    new_password: yup.string().required('Must enter current password'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('new_password'), null], 'Passwords Must Match'),
  });

  const form = useForm({
    resolver: yupResolver(userUpdateSchema),
  });
  const { control, handleSubmit } = form;

  return (
    <Stack gap={4}>
      {/* name */}
      <Box bgColor={AppColors.white} borderRadius={'lg'} boxShadow={'md'}>
        <SimpleGrid
          // columns={{ sm: 1, md: 3 }}
          //   gridTemplateColumns={'42% 42% 16%'}
          py={2}
        >
          <GridItem p={3}>
            <Flex justifyContent={'space-between'}>
              <Box w={'100%'}>
                <FormLabel
                  fontFamily={FONT_FAMILY.baiJamurjee}
                  fontWeight={'bold'}
                  color={AppColors.secondary}
                >
                  First Name
                </FormLabel>
                <EditableCheck
                  getData={getData}
                  name={currentUser?.first_name}
                  label={'first_name'}
                />
              </Box>
            </Flex>
          </GridItem>
          <GridItem p={3}>
            <Flex justifyContent={'space-between'}>
              <Box w={'100%'}>
                <FormLabel
                  fontFamily={FONT_FAMILY.baiJamurjee}
                  fontWeight={'bold'}
                  color={AppColors.secondary}
                >
                  Last Name
                </FormLabel>
                <EditableCheck
                  getData={getData}
                  name={currentUser?.last_name}
                  label={'last_name'}
                />
              </Box>
            </Flex>
          </GridItem>
        </SimpleGrid>
      </Box>
      {/* email */}
      <Box bgColor={AppColors.white} borderRadius={'lg'} boxShadow={'md'}>
        <Flex p={4}>
          <Box w={'100%'}>
            <FormLabel
              fontFamily={FONT_FAMILY.baiJamurjee}
              fontWeight={'bold'}
              color={AppColors.secondary}
            >
              Email
            </FormLabel>
            <Text fontSize={'lg'}>{currentUser?.email}</Text>
          </Box>
        </Flex>
      </Box>
      {/* passowrd */}
      <form onSubmit={handleSubmit(handleSaveData)}>
        <Box
          bgColor={AppColors.white}
          borderRadius={'lg'}
          boxShadow={'md'}
          p={3}
          px={4}
        >
          <Flex py={1} w={'99%'} pb={3}>
            <Box width={'95%'}>
              <FormLabel
                fontFamily={FONT_FAMILY.baiJamurjee}
                fontWeight={'bold'}
                color={AppColors.secondary}
              >
                Password
              </FormLabel>
              <Box width={'95%'}>
                <FormPasswordInput
                  control={control}
                  name="current_password"
                  id="current_password"
                  placeholder="Current Password"
                  leftIcon={<LockIcon />}
                  passwordShowIcon={<EyeHiddenIcon />}
                  passwordHideIcon={<EyeIcon />}
                  size="md"
                  ref={passwordRef}
                />
              </Box>
            </Box>
            <Circle
              bgColor={'#EBEBEE'}
              borderWidth={1}
              boxSize={10}
              p={2}
              cursor={'pointer'}
            >
              {openAccordion === undefined ? (
                <EditIcon boxSize={5} onClick={() => setOpenAccordion(0)} />
              ) : (
                <SmallCloseIcon
                  boxSize={5}
                  onClick={() => setOpenAccordion()}
                />
              )}
            </Circle>
          </Flex>
          <Box mt={5}>
            <Flex>
              <Accordion width={'100%'} index={[openAccordion]} allowMultiple>
                <AccordionItem border="none" ml={-4} mt={-5} textAlign={'left'}>
                  <AccordionButton _hover={{}} cursor={'default'}>
                    <FormLabel
                      fontFamily={FONT_FAMILY.baiJamurjee}
                      fontWeight={'bold'}
                      color={AppColors.secondary}
                    >
                      Change Password
                    </FormLabel>
                  </AccordionButton>
                  <AccordionPanel pb={4}>
                    <Grid gap={1}>
                      <GridItem>
                        <Box>
                          <FormPasswordInput
                            control={control}
                            name="new_password"
                            id="new_password"
                            placeholder="New Password"
                            leftIcon={<LockIcon />}
                            passwordShowIcon={<EyeHiddenIcon />}
                            passwordHideIcon={<EyeIcon />}
                            size="md"
                            onChange={e => {
                              const value = strengthChecker(e.target.value);
                              setNewPasswordStrength(value);
                            }}
                          />
                        </Box>
                      </GridItem>
                      <GridItem my={1}>
                        <Text fontSize={'sm'} ml={1}>Password Strength</Text>
                        <PasswordStrength strength={newPasswordStrength} />
                      </GridItem>
                      <GridItem pb={3}>
                        <FormPasswordInput
                          control={control}
                          name="confirm_password"
                          id="confirm_password"
                          placeholder="Confirm Password"
                          leftIcon={<LockIcon />}
                          passwordShowIcon={<EyeHiddenIcon />}
                          passwordHideIcon={<EyeIcon />}
                          size="md"
                          onChange={e => {
                            strengthChecker(e.target.value);
                          }}
                        />
                      </GridItem>
                      {/* </Box> */}
                      <Flex w="95%" justifyContent={'center'}>
                        <Button
                          mr={6}
                          borderRadius={'full'}
                          color={AppColors.secondary}
                          border={`2px solid ${AppColors.secondary}`}
                          w={'25%'}
                          bgColor={AppColors.white}
                          onClick={() => setOpenAccordion()}
                        >
                          Cancel
                        </Button>
                        <Button
                          borderRadius={'full'}
                          color={AppColors.white}
                          bgColor={AppColors.primary}
                          w={'25%'}
                          type="submit"
                        >
                          Save
                        </Button>
                      </Flex>
                    </Grid>
                  </AccordionPanel>
                </AccordionItem>
              </Accordion>
            </Flex>
          </Box>
        </Box>
      </form>
    </Stack>
  );
};

export const DepartmentInfoComponent = ({ departments }) => {
  return (
    <Box bgColor={AppColors.white} borderRadius={'lg'} boxShadow={'md'} mt={5}>
      <Flex p={4}>
        <Box w={'100%'}>
          <FormLabel
            fontFamily={FONT_FAMILY.baiJamurjee}
            fontWeight={'bold'}
            color={AppColors.secondary}
          >
            Department Info
          </FormLabel>

          <HStack
            flexWrap={'wrap'}
            gap={2}
            fontFamily={FONT_FAMILY.ptSans}
            fontSize={'md'}
          >
            <HStack m={1} bg={`#F7F7FA`} borderRadius={'full'} px={5} py={2}>
              <Text>
                {departments?.specialty__name
                  ? departments?.specialty__name
                  : 'N/A'}
              </Text>
            </HStack>
          </HStack>
        </Box>
      </Flex>
    </Box>
  );
};

const EditableLayer = ({ getData, user }) => {
  const UserName = { [user.label]: user.userName };
  const {
    isEditing,
    getSubmitButtonProps,
    getCancelButtonProps,
    getEditButtonProps,
  } = useEditableControls();

  return isEditing ? (
    <ButtonGroup
      justifyContent="center"
      size="sm"
      borderRadius={'full'}
      bgColor={AppColors.white}
    >
      <IconButton
        bg={'#EBEBEE'}
        icon={
          <CheckIcon
            onClick={() => {
              getData(UserName);
            }}
          />
        }
        {...getSubmitButtonProps()}
        borderRadius={'full'}
        mr={2}
      />
      <IconButton
        bg={'#EBEBEE'}
        icon={<SmallCloseIcon />}
        {...getCancelButtonProps()}
        borderRadius={'full'}
      />
    </ButtonGroup>
  ) : (
    <Circle size="40px" bg={'#EBEBEE'} cursor={'pointer'}>
      <EditIcon {...getEditButtonProps()} />
    </Circle>
  );
};

export const EditableCheck = ({ label, name, getData }) => {
  const [userName, setUserName] = useState(name);

  useEffect(() => { }, [name]);

  return (
    <Editable
      width={'99%'}
      display={'flex'}
      justifyContent={'space-between'}
      defaultValue={userName}
      fontSize="lg"
      isPreviewFocusable={false}
      selectAllOnFocus={false}
    >
      <EditablePreview />
      {/* Here is the custom input */}

      <Input
        as={EditableInput}
        w={'70%'}
        onBlur={false}
        name={label}
        onChange={e => setUserName(e.target.value)}
      />
      <EditableLayer getData={getData} user={{ userName, label }} />
    </Editable>
  );
};
