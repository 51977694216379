import React from 'react';
import { createColumnHelper } from '@tanstack/react-table';
import { defaultReactTableProp } from '../../utils/my_audits.utils';
import '../../pages/dashboard/hospital_dasboard/sections/TableCss.css';
import {
  CQReactTable,
  TextInput,
} from '@prasanna-transcend/code-quick-components';
import {
  Box,
  Center,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import { SearchIcon } from '@chakra-ui/icons';
import AppColors from '../../constants/AppColors';
import { FONT_FAMILY } from '../../constants/Theme';
import { TriangleIcon, TriangleRedIcon } from '../../constants/IconData';
import PropTypes from 'prop-types';
import { DAY } from '../../constants/constants';
import { replaceRoute } from '../../utils/common.utils';
import ROUTES from '../../constants/Routes';
import { useNavigate } from 'react-router-dom';

const columnHelper = createColumnHelper();

function RenderProviderTable({
  isLoading,
  providerData,
  setOrdering,
  defaultStartDate,
  defaultEndDate,
  filter,
}) {
  const navigate = useNavigate();
  const getTableCellProps = (cell, data) => {
    const startingHeaderId = ['name'];

    let color = 'black';
    if (data.column.id === 'date' || data.column.id === 'accuracy') {
      color = AppColors.gray;
    }
    return {
      fontFamily: FONT_FAMILY.ptSans,
      color: color,
      textAlign: startingHeaderId.includes(data.column.id) ? 'none' : 'center',
    };
  };

  function getHeaderCellProps(header) {
    const startingHeaderId = ['name'];
    return {
      textTransform: 'none',
      fontFamily: FONT_FAMILY.ptSans,
      fontWeight: 'bold',
      color: 'black',
      justifyContent: startingHeaderId.includes(header.id)
        ? 'flex-start'
        : 'center',
    };
  }

  const columns = React.useMemo(
    () => [
      columnHelper.accessor('provider_name', {
        header: () => 'Name',
        id: 'name',
        cell: info => {
          return (
            <Text
              _hover={{ textDecoration: 'underline' }}
              onClick={() => {
                const route = replaceRoute(
                  ROUTES.DEPARTMENT_PHYSICIAN_DASHBOARD,
                  { physicianId: info.row.original.provider_id }
                );
                navigate(route);
              }}
              cursor={'pointer'}
            >
              {info.renderValue()}
            </Text>
          );
        },
      }),
      columnHelper.accessor('cq_score', {
        id: 'accuracy',
        header: () => <span>Accuracy</span>,
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return <Text color={AppColors.black}>{cellValue}%</Text>;
        },
      }),
      columnHelper.accessor('power_ranking', {
        id: 'power_ranking',
        header: () => <span>Provider Power Rankings</span>,
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue || cellValue === 'NA') {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return (
            <HStack justifyContent={'center'}>
              {cellValue > 0 ? (
                <TriangleIcon style={{ width: 12, height: 12 }} />
              ) : (
                <TriangleRedIcon style={{ width: 12, height: 12 }} />
              )}
              <Text>{Math.abs(cellValue)}%</Text>
            </HStack>
          );
        },
      }),
      // columnHelper.accessor('archived_date', {
      //   header: () => 'Date',
      //   id: 'date',
      //   cell: info => {
      //     return (
      //       <Text>
      //         {filter === DAY
      //           ? defaultEndDate
      //           : `${defaultEndDate} -
      //             ${defaultStartDate}`}
      //       </Text>
      //     );
      //   },
      // }),
    ],
    // eslint-disable-next-line
    [providerData]
  );

  return (
    <Box
      id="fixedTableHeader"
      className="tableWraper"
      maxHeight={'300px'}
      overflowY={'scroll'}
    >
      <CQReactTable
        isLoading={isLoading}
        data={providerData}
        columns={columns}
        {...defaultReactTableProp}
        getHeaderCellProps={header => {
          return {
            ...getHeaderCellProps(header),
          };
        }}
        getCellProps={getTableCellProps}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />

      {providerData?.length === 0 && (
        <Center>There is no data to display</Center>
      )}
    </Box>
  );
}

function Providers({
  search,
  defaultStartDate,
  defaultEndDate,
  setSearch,
  fetchHospitalProvider,
  isFetching,
  providerData,
  setOrdering,
  filter,
}) {
  const renderProviderTable = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <RenderProviderTable
          isLoading={isFetching}
          providerData={providerData}
          setOrdering={setOrdering}
          defaultStartDate={defaultStartDate}
          defaultEndDate={defaultEndDate}
          filter={filter}
        />
      </ScaleFade>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isFetching) {
      return renderLoader();
    } else if (!providerData) {
      return renderFailedPage();
    } else {
      return renderProviderTable();
    }
  };

  return (
    <>
      <Box
        height={'449px'}
        bg={AppColors.white}
        borderRadius={'md'}
        padding={4}
        display={'flex'}
        flexDirection={'column'}
      >
        {/* header */}
        <>
          <HStack mb={2}>
            <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
              Providers
            </Text>
          </HStack>
          <Box>
            <TextInput
              borderRadius={'md'}
              onChange={e => setSearch(e.target.value)}
              onKeyDown={e => {
                if (e.keyCode === 13 && search) {
                  fetchHospitalProvider();
                }
              }}
              rightIconProps={{ zIndex: 0 }}
              placeholder="Search Providers"
              rightIcon={<SearchIcon color={AppColors.gray} />}
            />
          </Box>
        </>
        {/* content */}
        {renderMainContent()}
      </Box>
    </>
  );
}

Providers.propTypes = {
  search: PropTypes.string,
  defaultStartDate: PropTypes.string,
  defaultEndDate: PropTypes.string,
  setSearch: PropTypes.func,
  fetchHospitalProvider: PropTypes.func,
  isFetching: PropTypes.bool,
  providerData: PropTypes.array,
  setOrdering: PropTypes.func,
};

RenderProviderTable.propTypes = {
  isLoading: PropTypes.bool,
  providerData: PropTypes.array,
  setOrdering: PropTypes.func,
};

export default Providers;
