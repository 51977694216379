/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import AuditSheet from './components/AuditSheet';
import AuditSheetFooter from './components/AuditSheetFooter';
import Topbar from './components/Topbar';
import {
  Box,
  Modal,
  ModalBody,
  ModalContent,
  ModalOverlay,
  Spinner,
  useBoolean,
} from '@chakra-ui/react';
import AppColors from '../../constants/AppColors';
import { withProvider } from '../../hoc/withProvider';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AuditSheetProvider, {
  AuditSheetContext,
} from '../../providers/AuditSheetProvider';
import { createEmptyObject } from './components/createEmptyObject';
import { AWAITING_AUDIT, CLIENT } from '../../constants/constants';
import { sanitize } from './components/sanitize';
import SubmitModal from './components/SubmitModal';
import ROUTES from '../../constants/Routes';
import { useToastr } from '@prasanna-transcend/code-quick-components';
import { successToast } from '../../components/successToast';
import { submitRule } from './components/submitRules';
import CommentBox from './components/CommentBox';
import { createEmptyColumn } from '../../utils/auditsheetCreateEmptyProps.utils.js';
import { arrangeData } from '../../utils/auditsheetArrangeData.utils';

const AuditSheetContainer = ({ view, batch }) => {
  const auditSheetCount = Math.ceil(window.innerHeight / 24);

  const domEl = useRef(null);
  const topbar = useRef(null);
  const footer = useRef(null)
  const [isLoading, ilState] = useBoolean(false);

  const { batchId, uploadId } = useParams();
  const navigate = useNavigate();
  const toast = useToastr();
  const [isOpen, ioState] = useBoolean(false);
  const [lastSaved, setLastSaved] = useState('');
  const [sheets, setSheets] = useState([
    {
      index: 1,
      sheet_name: 'Sheet1',
      data: createEmptyObject(auditSheetCount),
    },
  ]);
  const [activeSheetIndex, setActiveSheetIndex] = useState(0);
  const [scale, setScale] = useState(1);
  const [isCommentOpen, icoState] = useBoolean(false);
  const [metaData, setMetaData] = useState({});
  const [rebuttalChecked, rcState] = useBoolean(true);
  const [isSubmitDisabled, setSubmitDisabled] = useState(true)

  const auditSheetContext = useContext(AuditSheetContext);
  const {
    getAuditSheet,
    getBatchAuditSheet,
    currentUpload,
    getIndustryCodes,
    getUsers,
    users,
    getAllUsers,
    getCommentFlag,
    getAuditUpload,
    providerOptions
  } = auditSheetContext;

  const [coords,setCoords]=useState({x:0,y:0})

  const sheetNames = sheets.map(s => s?.sheet_name);

  const getUpload = async () => {
    if (uploadId) {
      const res = await getAuditUpload(uploadId);
      setLastSaved(res.updated_date);
    }
  };

  const onCommentClick = (selection, dimension, comment, canRebuttal,columnData,selectedCol) => {    
    const colIndex=selection[0].start.col
    const rowIndex=selection[0].start.row
    const selectedColumnName = columnData[colIndex]?.data;
    setMetaData({ ...dimension, selection, canRebuttal,selectedColumnName,rowIndex});
    comment && icoState.on();
  };

  const onZoom = value => {
    let temp = 1;
    if (value >= 50) {
      temp = (2 * value) / 100;
    } else if (value === 0) {
      temp = 0.3;
    } else {
      temp = (value / 100) * 2;
    }
    setScale(temp);
  };


  useEffect(() => {
    if (uploadId || batchId) {
      getCommentFlag({ uploadId, batchId })
    }
  }, [uploadId, batchId])

  const getSheet = async () => {
    if (
      uploadId &&
      Object.keys(currentUpload) &&
      currentUpload.status !== AWAITING_AUDIT
    ) {
      const response = await getAuditSheet(uploadId);
      if (response.length) {
        const updated = response.map((res, i) => {
          const _reDestructuredData = createEmptyColumn(res.data);

          const reDestructuredData = _reDestructuredData.map(sd => {
            const tempObj = { ...sd, ...sd.additional_attributes };
            tempObj['audited_cpt'] = tempObj['audited_code'];
            delete tempObj.additional_attributes;
            delete tempObj?.audited_code;
            return tempObj;
          });

          const _arrangeData = arrangeData(reDestructuredData);

          const updatedData = [
            ..._arrangeData,
            ...createEmptyObject(auditSheetCount - res.data.length),
          ];


          return {
            ...res,
            index: i + 1,
            data: updatedData,
          };

        });
        setSheets(updated);
        disableSubmitFunc(response)

      }
    } else {
      const response = await getBatchAuditSheet(batchId);
      if (response.length) {
        const updated = response.map((res, i) => {

          const _reDestructuredData = createEmptyColumn(res.data);

          const reDestructuredData = _reDestructuredData.map(sd => {
            const tempObj = { ...sd, ...sd.additional_attributes };
            tempObj['audited_cpt'] = tempObj['audited_code'];
            delete tempObj.additional_attributes;
            delete tempObj?.audited_code;

            return tempObj;
          });


          const _arrangeData = arrangeData(reDestructuredData);


          const updatedData = [
            ..._arrangeData,
            ...createEmptyObject(auditSheetCount - res.data.length),
          ];
          return {
            ...res,
            index: i + 1,
            data: updatedData,
          };
        });

        setSheets(updated);
        disableSubmitFunc(response)

      }
    };
  }

  useEffect(() => {
    getUpload();
   
    getIndustryCodes();
    getUsers()
    getAllUsers()
    if (uploadId) {
      auditSheetContext.fetchProviderDropdown(uploadId)
    }
  }, []);


  useEffect(()=>{

    getSheet();

  },[uploadId,providerOptions])

  const onAuditSubmit = async () => {
    // call audithour
    await auditSheetContext.monitorAuditHour(batchId);
    // create audit sheet
    const sanitizedSheet = sanitize(sheets, providerOptions);
    const response = await auditSheetContext.updateAuditSheet(
      sanitizedSheet,
      batchId
    );
    if (response) {
      ioState.off();
      navigate(ROUTES.DASHBOARD);
      const toastProps = {
        header: 'Success!',
        description: `${'Document submitted for review.'}`,
      };
      successToast(toast, toastProps);
    }
  };

  const updateAuditSheet = async () => { };

  const disableSubmitFunc = () => {
    const sanitizedSheet = sanitize(sheets, providerOptions);
    if (sanitizedSheet.every(sheet => sheet.data.length === 0)) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(submitRule(sanitizedSheet));
    }
  };

  const addNewSheet = async () => {
    const updatedSheets = [
      ...sheets,
      {
        index: sheets.length + 1,
        data: createEmptyObject(auditSheetCount),
        sheet_name: `Sheet${sheets.length + 1}`,
      },
    ];
    setSheets([...updatedSheets]);
    setActiveSheetIndex(sheets.length);
  };



  const changeSheetName = async (index, name) => {
    sheets[index] = { ...sheets[index], sheet_name: name };
    setSheets([...sheets]);
    const sanitizedSheet = sanitize(sheets, providerOptions);
    await auditSheetContext.createAuditSheet(sanitizedSheet, batchId);
  };

  const onLeftNavigate = () => {
    if (activeSheetIndex > 0) {
      setActiveSheetIndex(activeSheetIndex - 1);
    }
  };

  const onRightNavigate = () => {
    if (activeSheetIndex < sheets.length - 1) {
      setActiveSheetIndex(activeSheetIndex + 1);
    }
  };

  const updateSheet = sheetData => {
    sheets[activeSheetIndex].data = sheetData;
    setSheets([...sheets]);
    disableSubmitFunc(sheets)

  };

  const removeRows = (rowIndexes) => {
    const data = sheets[activeSheetIndex].data.filter((obj, i) => !rowIndexes.includes(i));
    sheets[activeSheetIndex].data = data;
    setSheets([...sheets]);
    disableSubmitFunc(sheets)

  }

  const onDeleteSheet = async index => {
    if (sheets.length > 1) {
      let sheetIndex = 1;
      let newSheet = [];
      sheets.map((p, i) => {
        if (index !== i) {
          newSheet.push({ ...p, index: sheetIndex });
          sheetIndex = sheetIndex + 1;
        }
      });
      setSheets([...newSheet]);
      if (activeSheetIndex === index && index > 0) {
        const data = activeSheetIndex - 1;
        setActiveSheetIndex(data);
      }
      const sanitizedSheet = sanitize(newSheet, providerOptions);
      await auditSheetContext.createAuditSheet(sanitizedSheet, batchId);
    }
  };

  const onRebuttalClick = () => {
    if (metaData.canRebuttal) {
      icoState.on();
    }
  };

  const onOpenNotes = (obj) => {
    if (obj && Object.keys(obj).length) {
      if (obj.all) {
        delete auditSheetContext.commentFilters.sheet_name
        auditSheetContext.setCommentFilters({
          ...auditSheetContext.commentFilters,
          page: 1
        })
      } else if (obj.current) {
        auditSheetContext.setCommentFilters({
          ...auditSheetContext.commentFilters,
          sheet_name: sheets[activeSheetIndex].sheet_name,
          page: 1
        })
      }
    } else {
      auditSheetContext.setCommentFilters({
        ...auditSheetContext.commentFilters,
        uploadId,
        batchId,
        sheet_name: sheets[activeSheetIndex].sheet_name,
        reverse: true,
        page: 1
      })
    }

  }

  function handleSheetClick(e) {
    setCoords({
      x:e.clientX,
      y:e.clientY
    })
  }
  
 

  return (
    <Box h={'100vh'} bgColor={AppColors.bgColor}>
      <Box w={'100%'} ref={topbar}>
        <Topbar

          batchId={batchId}
          onAuditSubmit={() => ioState.on()}
          updateAuditSheet={updateAuditSheet}
          view={view}
          disableSubmit={isSubmitDisabled}
          lastSaved={lastSaved}
          onChange={on => {
            if (on) {
              ilState.on();
            } else {
              ilState.off();
            }
          }}
          onRebuttalClick={onRebuttalClick}
          onOpenNotes={onOpenNotes}
        />
      </Box>

      <Box id="domEl" ref={domEl} onClick={handleSheetClick} >
        <AuditSheet
          height={window.innerHeight - topbar?.current?.clientHeight - footer?.current?.clientHeight}
          scale={scale}
          sheet={sheets[activeSheetIndex]}
          batchId={batchId}
          view={view}
          batch={batch}
          uploadId={uploadId}
          updateAuditSheet={updateAuditSheet}
          updateSheet={updateSheet}
          onCommentClick={onCommentClick}
          removeRows={removeRows}
          setSheets={setSheets}
          closeComment={() => {
            icoState.off();
            rcState.on();
          }}
        />
      </Box>

      <Box w={'100%'} position={'fixed'} bottom={0} ref={footer}>
        <AuditSheetFooter
          view={view}
          onSheetChange={setActiveSheetIndex}
          sheets={sheetNames}
          activeSheetIndex={activeSheetIndex}
          addNewSheet={addNewSheet}
          changeSheetName={changeSheetName}
          onLeftNavigate={onLeftNavigate}
          onRightNavigate={onRightNavigate}
          onZoom={onZoom}
          onDeleteSheet={onDeleteSheet}
        />
      </Box>
      <SubmitModal
        isOpen={isOpen}
        onClose={() => ioState.off()}
        onAuditSubmit={onAuditSubmit}
      />
      <Modal size={'sm'} isOpen={isLoading} isCentered bgColor={'transparent'}>
        <ModalOverlay style={{ backgroundColor: AppColors.lightSkyBlue }} />
        <ModalContent bgColor={'transparent'} shadow={'none'}>
          <ModalBody style={{ alignSelf: 'center' }}>
            <Spinner />
          </ModalBody>
        </ModalContent>
      </Modal>
      <CommentBox
        batch={batch}
        showMenu={isCommentOpen}
        role={CLIENT}
        metaData={metaData}
        currentUpload={currentUpload}
        closeComment={() => {
          icoState.off();
          rcState.on();
        }}
        users={users}
        sheet={sheets[activeSheetIndex]}
        isSelected={rebuttalChecked}
        onRebuttalCheck={flag => {
          if (flag) {
            rcState.on();
          } else {
            rcState.off();
          }
        }}
        coords={coords}
      />
    </Box>)
};
export default withProvider(AuditSheetProvider, AuditSheetContainer);
