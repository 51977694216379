/* eslint-disable no-useless-escape */
import {
  Box,
  Button,
  Center,
  Flex,
  HStack,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import {
  FormPasswordInput,
  FormTextInput,
} from '@prasanna-transcend/code-quick-components';
import React from 'react';
import AppColors from '../../../../../constants/AppColors';
import {
  EyeHiddenIcon,
  EyeIcon,
  LockIcon,
  MailIcon,
} from '../../../../../constants/IconData';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';

function LoginFormComponent({ renderComponent, email }) {
  const userCreateSchema = yup.object({
    email: yup.string().email(),
    password: yup.string().required('Password is required'),
  });

  const form = useForm({
    resolver: yupResolver(userCreateSchema),
  });
  const { control, handleSubmit, setValue } = form;
  const handleSaveData = email => {
    renderComponent.off();
  };
  return (
    <VStack flexDirection={'column'} marginTop={'100px'}>
      <Box>
        <Center textAlign={'center'} mb={2} fontSize={'18px'}>
          Login using the password you received in the onboarding email invite.
        </Center>
      </Box>

      {/* ---- */}

      <Flex
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <form onSubmit={handleSubmit(handleSaveData)}>
          <Box mt={5} width={'380px'}>
            <FormTextInput
              name="email"
              control={control}
              id="email"
              value={email}
              leftIcon={<MailIcon />}
              leftIconProps={{ height: '50px' }}
              height="50px"
              size="md"
              placeholder="name@gmail.com"
              onChange={e => {
                setValue('email', e.target.value);
              }}
            />
            <Box mt={3} />
            <FormPasswordInput
              control={control}
              name="password"
              id="password"
              placeholder="Password"
              leftIcon={<LockIcon />}
              passwordShowIcon={<EyeHiddenIcon />}
              passwordHideIcon={<EyeIcon />}
              size="md"
              onChange={e => {
                setValue('password', e.target.value);
              }}
            />
          </Box>

          <HStack mt={10} alignItems={'center'} justifyContent={'center'}>
            <Button
              width={'190px'}
              height={'50px'}
              borderRadius={'full'}
              paddingInline={'20px'}
              bgColor={'transparent'}
              style={{ border: `2px solid ${AppColors.primary}` }}
              _hover={{ bg: 'none' }}
              _focus={{ border: 'none' }}
              bg={'#fff'}
              mt={3}
              variant="outline"
              type="submit"
            >
              Send
            </Button>
          </HStack>
        </form>
      </Flex>
    </VStack>
  );
}

function SetPasswordComponent({ renderProviderLogin, currentState }) {
  const userCreateSchema = yup.object({
    password: yup
      .string()
      .required('Password is required')
      .min(6, 'Your password must be longer than 5 characters.')
      .matches(/^(?=.{6,})/, 'Must Contain 6 Characters')
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])/,
        'Must Contain One Uppercase, One Lowercase'
      )
      .matches(
        /^(?=.*[!@#\$%\^&\*])/,
        'Must Contain One Special Case Character'
      )
      .matches(/^(?=.{6,20}$)\D*\d/, 'Must Contain One Number'),
    confirm_password: yup
      .string()
      .oneOf([yup.ref('password'), null], 'Passwords Must Match'),
  });
  const form = useForm({
    resolver: yupResolver(userCreateSchema),
  });
  const { control, handleSubmit, setValue } = form;
  const handleSaveData = email => {
    renderProviderLogin(currentState + 1);
  };
  return (
    <VStack flexDirection={'column'} marginTop={'100px'}>
      <Box>
        <Center mb={2} fontSize={'18px'}>
          Welcome!
        </Center>
        <Center mb={2} fontSize={'18px'}>
          Please create a new password.
        </Center>
      </Box>

      {/* ---- */}

      <Flex
        flexDirection={'column'}
        justifyContent={'center'}
        alignItems={'center'}
      >
        <form onSubmit={handleSubmit(handleSaveData)}>
          <Box mt={5} width={'380px'}>
            <FormPasswordInput
              control={control}
              name="password"
              id="password"
              placeholder="Password"
              leftIcon={<LockIcon />}
              passwordShowIcon={<EyeHiddenIcon />}
              passwordHideIcon={<EyeIcon />}
              size="md"
              onChange={e => {
                setValue('password', e.target.value);
              }}
            />
            <Box mt={3} />
            <FormPasswordInput
              control={control}
              name="confirm_password"
              id="confirm_password"
              placeholder="Confirm Password"
              leftIcon={<LockIcon />}
              passwordShowIcon={<EyeHiddenIcon />}
              passwordHideIcon={<EyeIcon />}
              size="md"
              onChange={e => {
                setValue('confirm_password', e.target.value);
              }}
            />
          </Box>

          <HStack mt={10} alignItems={'center'} justifyContent={'center'}>
            <Button
              width={'190px'}
              height={'50px'}
              borderRadius={'full'}
              paddingInline={'20px'}
              bgColor={'transparent'}
              style={{ border: `2px solid ${AppColors.primary}` }}
              _hover={{ bg: 'none' }}
              _focus={{ border: 'none' }}
              bg={'#fff'}
              mt={3}
              variant="outline"
              type="submit"
            >
              Save
            </Button>
          </HStack>
        </form>
      </Flex>
    </VStack>
  );
}

function ProviderLogin({ renderProviderLogin, currentState, email }) {
  const [isLoginComponent, ilcState] = useBoolean(true);

  const renderComponent = () => {
    if (isLoginComponent) {
      return <LoginFormComponent renderComponent={ilcState} email={email} />;
    } else {
      return (
        <SetPasswordComponent
          renderProviderLogin={renderProviderLogin}
          currentState={currentState}
        />
      );
    }
  };
  return (
    <>
      <Flex>
        <Flex width={'100vw'} justifyContent={'center'}>
          <Flex
            borderRadius={'xl'}
            p={3}
            height={'622px'}
            width={'966px'}
            m={4}
            flexDirection={'column'}
            alignContent={'space-between'}
            justifyContent={'space-between'}
            bg={'white'}
          >
            <Flex
              height={'100%'}
              flexDirection={'column'}
              alignContent={'space-between'}
            >
              <Flex
                borderRadius={'md'}
                height={'114px'}
                bg={AppColors.loginFormBg}
                alignItems={'center'}
                justifyContent={'center'}
              >
                <Center color={AppColors.secondary} fontSize={'30px'}>
                  Log In
                </Center>
              </Flex>
              {renderComponent()}
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </>
  );
}

export default ProviderLogin;
