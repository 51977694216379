import { List, ListItem, SlideFade, Text, VStack } from '@chakra-ui/react';
import { useCQMediaQuery } from '@prasanna-transcend/code-quick-components';
import React from 'react';
import { ListIcon, CircleMarkIcon } from '../../constants/IconData';
import { FONT_FAMILY } from '../../constants/Theme';

function DownloadChartTutorial({ slideRight }) {
  const renderFontSize = useCQMediaQuery({
    lg: '36px',
    md: '26',
    sm: '20',
  });

  const renderPadding = useCQMediaQuery({
    lg: '30px',
    md: '20px',
    sm: '15px',
  });
  return (
    <>
      <SlideFade in={true} offsetX={slideRight ? '80px' : '-80px'}>
        <VStack padding={renderPadding}>
          <CircleMarkIcon />
          <Text
            textAlign={'center'}
            fontFamily={FONT_FAMILY.baiJamurjee}
            fontSize={renderFontSize}
            mb={5}
          >
            Download your charts
          </Text>

          <List spacing={'35px'}>
            <ListItem display={'flex'} alignItems={'baseline'}>
              <ListIcon />
              <Text paddingLeft={3} fontWeight={500}>
                Once your charts have been uploaded, they will appear in the My
                Charts Section.
              </Text>
            </ListItem>
            <ListItem display={'flex'} alignItems={'baseline'}>
              <ListIcon />
              <Text fontWeight={500} paddingLeft={3}>
                Just click the Download button and your finished chart will be
                downloaded to your computer as an Excel document.
              </Text>
            </ListItem>
            <ListItem display={'flex'} alignItems={'baseline'}>
              <ListIcon />
              <Text fontWeight={500} paddingLeft={3}>
                Need to access an old chart? All of your charts are available by
                clicking on View Archived Charts.
              </Text>
            </ListItem>
          </List>
        </VStack>
      </SlideFade>
    </>
  );
}

export default DownloadChartTutorial;
