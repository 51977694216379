/* eslint-disable react-hooks/exhaustive-deps */
import { Box, Grid, GridItem, Skeleton, Stack, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useRef, useState } from 'react';
import CQContainer from '../../../components/layout/CQContainer';
import BatchCQscore from './sections/BatchCQscore';
import BatchDashboardHeader from './sections/BatchDashboardHeader';
import BatchRevenueOppurtunity from './sections/BatchRevenueOppurtunity';
import RVUbreakdownTable from './sections/RVUbreakdownTable';
import UploadChartActivity from './sections/UploadChartActivity';
import BatchChartAccuracyTable from './sections/BatchChartAccuracyTable';
import BatchRebuttals from './sections/BatchRebuttals';
import BatchProvider, { BatchContext } from '../../../providers/BatchProvider';
import { withProvider } from '../../../hoc/withProvider';
import { useParams } from 'react-router-dom';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';
import RVUOpportunity from '../components/RVUOpportunity';
import AIRiskMonitor from '../components/AIRiskMonitor';
import { add } from 'date-fns';
import { getDateInterval } from '../getDateInterval';
import { useBoolean } from '@prasanna-transcend/code-quick-components';
import TilePopup from '../components/TilePopup';

function BatchContainer() {
  const auditorQualityRef = useRef(null);

  const firstRowRef = useRef(null);
  const secondRowRef = useRef(null);
  const oppBreakDownRef = useRef(null);
  const chartAccurayRef = useRef(null);
  const rebuttalsRef = useRef(null);

  const batchContext = useContext(BatchContext);
  const [isTileModalOpen, itmState] = useBoolean(false);
  const [currentTile, setCurrentTile] = useState(null);

  const {
    getAllUsers,
    getRVUOpp,
    filterParams,
    handleApplyFilter,
    rvuOpp,
    isRVUOppLoading,
    isFileLoading,
    getRVUOppDropdown,
    dropdown,
    setRVUOppFilter,
  } = useContext(BatchContext);
  const { batchId } = useParams();
  const [practiceChartData, setPracticeChartData] = useState({});
  const [stateChartData, setStateChartData] = useState({});
  const [checked, setChecked] = useState('');
  const yesterday = add(new Date(), {
    days: -1,
  });
  const selectedFilter = JSON.parse(localStorage.getItem('selectedFilter'));

  const pageRefData = [
    {
      ref: firstRowRef,
      height: () => 160,
      width: 560,
      paddingTop: 40,
      padding: 0,
    },
    {
      ref: secondRowRef,
      height: () => 160,
      width: 560,
      paddingTop: 200,
      padding: 0,
    },
    {
      ref: oppBreakDownRef,
      height: () => 180,
      width: 560,
      paddingTop: 360,
      padding: 0,
    },
    {
      ref: chartAccurayRef,
      height: () => 180,
      width: 560,
      paddingTop: 560,
      padding: 0,
    },
  ];

  const page2RefData = [
    {
      ref: rebuttalsRef,
      height: () => 160,
      width: 560,
      paddingTop: 20,
    },
  ];

  useEffect(() => {
    getAllUsers();
  }, []);

  useEffect(() => {
    let initialFilterParams = {};
    if (selectedFilter) {
      if (selectedFilter.toLowerCase() === 'custom') {
        const selectedCustomDates = JSON.parse(
          window.localStorage.getItem('customDateFilter')
        );
        initialFilterParams = {
          label: 'custom',
          start_date: new Date(selectedCustomDates.start_date),
          end_date: new Date(selectedCustomDates.end_date),
          previous_start_date: new Date(
            selectedCustomDates.previous_start_date
          ),
          previous_end_date: new Date(selectedCustomDates.previous_end_date),
        };
      } else {
        const data = getDateInterval(selectedFilter);
        initialFilterParams = {
          label: selectedFilter,
          ...data,
        };
      }
    } else {
      initialFilterParams = {
        label: 'month',
        start_date: add(yesterday, {
          days: -30,
        }),
        end_date: yesterday,
        previous_start_date: add(yesterday, {
          days: -60,
        }),
        previous_end_date: add(yesterday, {
          days: -30,
        }),
      };
    }
    handleApplyFilter({
      ...filterParams,
      ...initialFilterParams,
      batch_id: batchId,
    });
  }, [batchId]);

  useEffect(() => {
    if (filterParams.batch_id) {
      if (batchContext.rvuOppFilter['rvu_opportunity_type']) {
        getRVUOpp();
      }
      batchContext.getAIRisk();
    }
  }, [filterParams, batchContext.rvuOppFilter]);

  const getBatchData = () => {
    if (filterParams.batch_id) {
      batchContext.fetchCqScore();
      batchContext.getActivityData();
      batchContext.fetchRevenueOpporturinityScore();
      batchContext.getOutstandingRevenueData();
      getRVUOppDropdown();
    }
  };

  useEffect(() => {
    if (filterParams.batch_id) {
      getBatchData();
    }
  }, [filterParams]);

  useEffect(() => {
    if (dropdown.length) {
      setChecked(dropdown[0]['value']);
      setRVUOppFilter({ rvu_opportunity_type: dropdown[0]['value'] });
    }
  }, [dropdown]);

  useEffect(() => {
    const option = batchContext.rvuOppFilter['rvu_opportunity_type'];
    if (batchContext.rvuOpp && Object.keys(batchContext.rvuOpp).length) {
      const data = batchContext.rvuOpp[option];
      let practiceChartData = { label: [], data: [] };
      if (data?.practice_distribution) {
        practiceChartData.label = Object.keys(data.practice_distribution);
        practiceChartData.data = Object.keys(data.practice_distribution).map(
          d => data.practice_distribution[d]
        );
      }
      let stateChartData = { label: [], data: [] };
      if (data?.state_distribution) {
        stateChartData.label = Object.keys(data.state_distribution);
        stateChartData.data = Object.keys(data.state_distribution).map(
          d => data.state_distribution[d]
        );
      }
      setPracticeChartData(practiceChartData);
      setStateChartData(stateChartData);
    }
  }, [batchContext.rvuOpp]);

  const radioClick = option => {
    setChecked(option);
    batchContext.setRVUOppFilter(prev => ({
      ...prev,
      rvu_opportunity_type: option,
    }));
  };

  const renderHeaderComponent = () => {
    return (
      <Box pl={4} pr={4}>
        <BatchDashboardHeader
          auditorQualityRef={auditorQualityRef}
          pageRefData={pageRefData}
          page2RefData={page2RefData}
        />
      </Box>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const tileFailure = title => {
    return (
      <Box p={5} bg={'white'} borderRadius="md" width={'100%'}>
        <Text
          fontSize={'sm'}
          color={AppColors.primary}
          fontFamily={FONT_FAMILY.ptSans}
        >
          {title ? title : 'RVU Opportunity'}
        </Text>
        <Box mt={10} textAlign="center" justifyContent={'center'}>
          <Text>Something went wrong, Please try again later</Text>
        </Box>
      </Box>
    );
  };

  const renderRVUOpportunity = () => {
    if (isRVUOppLoading || isFileLoading) {
      return renderLoader();
    }
    if (!Object.keys(rvuOpp)?.length) {
      return tileFailure('RVU Opportunity');
    }

    return (
      <Box bg={'white'} borderRadius="md" p={isTileModalOpen ? 0 : 5}>
        <Text
          fontSize={'sm'}
          color={AppColors.primary}
          fontFamily={FONT_FAMILY.ptSans}
        >
          RVU Opportunity
        </Text>
        <Box mt={4}>
          <RVUOpportunity
            stateChartData={stateChartData}
            practiceChartData={practiceChartData}
            checked={checked}
            setChecked={setChecked}
            radioClick={radioClick}
            dropdown={batchContext.dropdown}
          />
        </Box>
      </Box>
    );
  };

  const renderAIRisk = () => {
    if (batchContext.isAIRiskLoading) {
      return renderLoader();
    }
    if (!Object.keys(batchContext.aiRisk)?.length) {
      return tileFailure('Revenue Cycle Temperature Gauge');
    }

    return (
      <Box p={isTileModalOpen ? 0 : 5} bg={'white'} borderRadius="md">
        <Text
          fontSize={'sm'}
          color={AppColors.primary}
          fontFamily={FONT_FAMILY.ptSans}
        >
          Revenue Cycle Temperature Gauge
        </Text>
        <Box mt={4}>
          <AIRiskMonitor data={batchContext.aiRisk} tile={isTileModalOpen} />
        </Box>
      </Box>
    );
  };

  const renderFirstRowComponent = () => {
    return (
      <Box mt={2} pb={4} pl={4} pr={4} ref={pageRefData[0].ref}>
        <Grid templateColumns="repeat(3, 1fr)" gap={4}>
          <GridItem
            bgColor={'#fff'}
            borderRadius={'lg'}
            shadow={'md'}
            cursor="pointer"
            onClick={() => {
              setCurrentTile('CQ_SCORE');
              itmState.on();
            }}
          >
            <BatchCQscore isTileModalOpen={isTileModalOpen} />
          </GridItem>
          <GridItem
            cursor="pointer"
            bgColor={'#fff'}
            borderRadius={'lg'}
            shadow={'md'}
            onClick={() => {
              setCurrentTile('RVU_OPP');
              itmState.on();
            }}
          >
            {renderRVUOpportunity()}
          </GridItem>
          <GridItem bgColor={'#fff'} borderRadius={'lg'} shadow={'md'}>
            <BatchRevenueOppurtunity />
          </GridItem>
        </Grid>
      </Box>
    );
  };

  const renderSecondRowComponent = () => {
    return (
      <Box pt={2} pl={4} pr={4} pb={4} ref={pageRefData[1].ref}>
        <Grid templateColumns="repeat(3, 1fr)" gap={2}>
          <GridItem
            colSpan={2}
            bgColor={'#fff'}
            borderRadius={'lg'}
            shadow={'md'}
          >
            <UploadChartActivity />
          </GridItem>
          <GridItem
            cursor="pointer"
            bgColor={'#fff'}
            borderRadius={'lg'}
            shadow={'md'}
            onClick={() => {
              setCurrentTile('AI_RISK');
              itmState.on();
            }}
          >
            {/* <BatchRiskMonitor /> */}
            {renderAIRisk()}
          </GridItem>
        </Grid>
      </Box>
    );
  };

  const render_rvu_table = () => {
    return (
      <Box pt={2} pb={4} pl={4} pr={4} ref={pageRefData[2].ref}>
        <RVUbreakdownTable />
      </Box>
    );
  };

  const renderChartAccuracyTable = () => {
    return (
      <Box mt={2} pb={2} pl={4} pr={4}>
        <BatchChartAccuracyTable ref={pageRefData[3].ref} />
      </Box>
    );
  };

  const renderRebuttalcomponent = () => {
    return (
      <Box mt={2} pb={2} pl={4} pr={4}>
        <BatchRebuttals ref={page2RefData[0].ref} />
      </Box>
    );
  };

  const renderCQSore = () => {
    return <BatchCQscore isTileModalOpen={isTileModalOpen} />;
  };

  const TILE_MODAL_BODY = {
    CQ_SCORE: renderCQSore,
    RVU_OPP: renderRVUOpportunity,
    AI_RISK: renderAIRisk,
  };

  return (
    <CQContainer>
      <Stack>
        {renderHeaderComponent()}
        {renderFirstRowComponent()}
        {renderSecondRowComponent()}
        {render_rvu_table()}
        {renderChartAccuracyTable()}
        {renderRebuttalcomponent()}
      </Stack>
      <TilePopup
        renderBody={currentTile ? TILE_MODAL_BODY[currentTile] : () => null}
        isOpen={isTileModalOpen}
        onClose={() => {
          itmState.off();
        }}
      />
    </CQContainer>
  );
}
export default withProvider(BatchProvider, BatchContainer);
