import { useBoolean } from '@chakra-ui/react';
import React, { useState } from 'react';
import { useParams } from 'react-router-dom';
import AiRevenueComponent from '../../../../components/dashboard_components/AiRevenueComponent';
import { CUSTOM } from '../../../../constants/constants';
import departmentDashboardRepository from '../../../../repositories/DepartmentRepository';
import { dateFormat, setFilters } from '../../../../utils/common.utils';

function DCqAiRevenueOpporturinty({
  defaultStartDate,
  defaultEndDate,
  startDate,
  endDate,
  filter,
}) {
  const { departmentId } = useParams();
  const [isFetching, ifState] = useBoolean(false);
  const [revenueOpporturintyScore, setRevenueOpporturintyScore] =
    useState(null);
  const selectedDates = JSON.parse(
    window.localStorage.getItem('customDateFilter')
  );

  const setCustomDates = filterParams => {
    for (const prop in selectedDates) {
      filterParams[prop] = dateFormat(
        new Date(selectedDates[prop]),
        'yyyy-MM-dd'
      );
    }
  };

  const fetchRevenueOpporturinityScore = async () => {
    try {
      ifState.on();
      const filterParams = {};
      filterParams.filter = filter;
      if (filter.toLowerCase() === CUSTOM.toLowerCase()) {
        setCustomDates(filterParams);
        // setFilters(
        //   filterParams,
        //   dateFormat(defaultStartDate, 'yyyy-MM-dd'),
        //   dateFormat(defaultEndDate, 'yyyy-MM-dd'),
        //   dateFormat(startDate, 'yyyy-MM-dd'),
        //   dateFormat(endDate, 'yyyy-MM-dd')
        // );
        delete filterParams.filter;
      }
      filterParams.department_id = departmentId;
      const response =
        await departmentDashboardRepository.getrevenueopporturityScore(
          filterParams
        );
      setRevenueOpporturintyScore(response);
      return response;
    } catch (error) {
    } finally {
      ifState.off();
    }
  };

  React.useEffect(() => {
    fetchRevenueOpporturinityScore();
    // eslint-disable-next-line
  }, [defaultStartDate, defaultEndDate, filter]);

  const uiStartDate = dateFormat(defaultStartDate, 'MM/dd/yyyy');
  const uiEndDate = dateFormat(defaultEndDate, 'MM/dd/yyyy');

  return (
    <AiRevenueComponent
      defaultStartDate={uiStartDate}
      defaultEndDate={uiEndDate}
      revenueOpporturintyScore={revenueOpporturintyScore}
      isFetching={isFetching}
      filter={filter}
    />
  );
}

export default DCqAiRevenueOpporturinty;
