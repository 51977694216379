/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  GridItem,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useContext } from 'react';
import AppColors from '../../../../constants/AppColors';
import { FONT_FAMILY } from '../../../../constants/Theme';
import { BatchContext } from '../../../../providers/BatchProvider';

function UploadChartActivity() {

  const batchContext = useContext(BatchContext);
  const { uploadActivityData, isUploadFetching } = batchContext

  const activityData = [
    {
      label: 'Modifier',
      score: uploadActivityData?.modifier_percentage,
    },
    {
      label: 'RVU Opportunities',
      score: uploadActivityData?.rvu_percentage,
    },
    {
      label: 'Upcoded',
      score: uploadActivityData?.upcoded_percentage,
    },
    {
      label: 'Downcoded',
      score: uploadActivityData?.downcoded_percentage,
    },
  ];

  const progressContainer = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <Box bg={AppColors.white} borderRadius={'md'} padding={5}>
          <HStack justifyContent={'space-between'} gap={'50px'}>
            <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
              Uploaded Chart Activity
            </Text>
          </HStack>

          <HStack p={5} justifyContent={'space-between'} flexWrap={'wrap'}>
            {activityData.map((ad, i) => {
              return (
                <GridItem key={`${i}-activity`}>
                  <VStack>
                    <CircularProgress
                      value={ad.score}
                      size="150px"
                      thickness="2px"
                      color={AppColors.primary}
                    >
                      <CircularProgressLabel>
                        <VStack justifyContent={'center'} position={'relative'}>
                          <HStack>
                            <Text fontSize={'27px'} fontWeight={'semibold'}>
                              {ad.score || 0}%
                            </Text>
                          </HStack>
                        </VStack>
                      </CircularProgressLabel>
                    </CircularProgress>
                    <Text>{ad.label}</Text>
                  </VStack>
                </GridItem>
              );
            })}
          </HStack>
        </Box>
      </ScaleFade>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isUploadFetching) {
      return renderLoader();
    } else if (!uploadActivityData) {
      return renderFailedPage();
    } else {
      return progressContainer();
    }
  };

  return renderMainContent();
}

export default UploadChartActivity;
