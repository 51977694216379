/* eslint-disable array-callback-return */
import { createContext, useState } from "react";
import { useNavigate } from "react-router-dom";
import uploadRepository from "../repositories/UploadRepository";
import { useBoolean, useToast } from '@chakra-ui/react'
import ROUTES from "../constants/Routes";
export const UploadContext = createContext({});

export const UPLOAD_API = {
    CHART_UPLOAD: 'chart_upload',
    SUBMIT_CHART: 'submit_chart',
    DELETE_CHART: 'delete_chart'
};

const UploadProvider = props => {
    const toast = useToast()
    const navigate = useNavigate();
    let newCharts = []
    const [isSubmitting, isState] = useBoolean(false)
    const [apiError, setApiError] = useState({
        [UPLOAD_API.CHART_UPLOAD]: null,
        [UPLOAD_API.SUBMIT_CHART]: null,
        [UPLOAD_API.DELETE_CHART]: null,

    })
    const [uploadedCharts, setUploadedCharts] = useState([])
    const [complaincePlanCharts, setComplaincePlanCharts] = useState([]);
    const [complainacePlanUploadedCharts, setComplainacePlanUploadedCharts] = useState([]);
    const [isLoadingGetCharts, setIsLoadingGetCharts] = useBoolean(false)


    const uploadCharts = async (files, index) => {
        const formData = new FormData()
        if (index === 0) {
            newCharts = files.map(f => {
                return {
                    name: f.name,
                    size: `${Math.floor(f.size / 1000)}kb`,
                    value: 0
                }
            })
        }
        formData.append('upload_chart', files[index])
        //upload the chart and set the uploadedChart
        const uploadProgress = (data) => {
            newCharts[index] = { ...newCharts[index], value: Math.round((data.loaded / data.total) * 100) }
            setUploadedCharts([...newCharts])
        }

        if (files.length > index) {
            try {
                const response = await uploadRepository.uploadCharts(formData, uploadProgress)
                if (response.status === 201) {
                    newCharts[index] = {
                        ...newCharts[index],
                        id: response.data.file_obj.id,
                        preview_url: response.data.file_obj.preview_url,
                        uploaded: true,
                        cancelled: false,
                        value: 100
                    }
                    setUploadedCharts([...newCharts])
                }
            } catch (e) {
                setApiError({ [UPLOAD_API.CHART_UPLOAD]: "error message" })
                toast({
                    title: 'Chart Upload',
                    description: `${e.toString()}`,
                    status: 'success',
                    duration: 9000,
                    isClosable: true,
                    position: 'top-right'
                })
            } finally {
                uploadCharts(files, index + 1)
            }
        }
    }

    const submitUploadedCharts = async () => {
        //submit the uploaded chart and redirect to next page
        //submit onlu the none deleted ones
        try {
            isState.on()
            let fileIds = []
            uploadedCharts.map(f => {
                if (!f.cancelled) {
                    fileIds.push(f.id)
                }
            })
            const formData = new FormData()
            formData.append('file_ids', fileIds.toString())
            await uploadRepository.submitUploadedCharts(formData)
            setUploadedCharts([])
            toast({
                title: 'Chart Uploaded',
                description: "",
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            })
            navigate(ROUTES.DASHBOARD);

        } catch (e) {
            setApiError({ [UPLOAD_API.SUBMIT_CHART]: "error message" })
            toast({
                title: 'Chart Upload',
                description: `${e.toString()}`,
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            })
        } finally {
            isState.off();

        }
    }
    const deleteChart = async (id) => {
        //delete the chart and set uploadedChart data to deleted
        try {
            await uploadRepository.deleteChart(id)
        } catch (e) {
            setApiError({ [UPLOAD_API.DELETE_CHART]: "error message" })
            toast({
                title: 'Delete Chart',
                description: `${e.toString()}`,
                status: 'success',
                duration: 9000,
                isClosable: true,
                position: 'top-right'
            })
        } finally {
            const index = uploadedCharts.findIndex(f => f.id === id)
            uploadedCharts[index] = { ...uploadedCharts[index], uploaded: false, cancelled: true }
            setUploadedCharts([...uploadedCharts])
        }
    }

    const uploadComplaincePlanCharts = async (files, index) => {
        const formData = new FormData()
        if (index === 0) {
            newCharts = files.map(f => {
                return {
                    name: f.name,
                    size: `${Math.floor(f.size / 1000)}kb`,
                    value: 0
                }
            })
        }
        formData.append('upload_chart', files[index])
        //upload the chart and set the uploadedChart
        const uploadProgress = (data) => {
            newCharts[index] = { ...newCharts[index], value: Math.round((data.loaded / data.total) * 100) }
            setUploadedCharts([...newCharts])
        }

        if (files.length > index) {
            try {
                const response = await uploadRepository.uploadComplaincePlanCharts(formData, uploadProgress)
                if (response.status === 201) {
                    newCharts[index] = {
                        ...newCharts[index],
                        id: response.data.file_obj.id,
                        preview_url: response.data.file_obj.preview_url,
                        uploaded: true,
                        cancelled: false,
                        value: 100
                    }
                    setUploadedCharts([...newCharts])
                }
            } catch (e) {
                setApiError({ [UPLOAD_API.CHART_UPLOAD]: "error message" })
                toast({
                    title: 'Chart Upload',
                    description: `${e.toString()}`,
                    status: 'success',
                    duration: 5000,
                    isClosable: true,
                    position: 'top-right'
                })
            } finally {
                uploadCharts(files, index + 1)
            }
        }
    }

    const submitUploadedComplaincePlanCharts = async () => {
        //submit only the none deleted ones
        try {
            isState.on()
            let fileIds = []
            uploadedCharts.map(f => {
                if (!f.cancelled) {
                    fileIds.push(f.id)
                }
            })
            const formData = new FormData()
            formData.append('file_ids', fileIds.toString())
            await uploadRepository.submitUploadedComplaincePlanCharts(formData)
            // setComplaincePlanCharts([...complaincePlanCharts, ...uploadCharts])
            setUploadedCharts([])
            toast({
                title: 'Chart Uploaded',
                description: "",
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
            // navigate(ROUTES.DASHBOARD);

        } catch (e) {
            setApiError({ [UPLOAD_API.SUBMIT_CHART]: "error message" })
            toast({
                title: 'Chart Upload',
                description: `${e.toString()}`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
        } finally {
            isState.off();

        }
    }

    const getUploadedComplaincePlanCharts = async () => {
        try {
            setIsLoadingGetCharts.on()
            const response = await uploadRepository.getUploadedComplaincePlanCharts();
            setComplaincePlanCharts(response.results);
        } catch (error) {
            toast.showError({
                description: `${error.toString()}`,
              });
        } finally{
            setIsLoadingGetCharts.off()
        }
    }

    const deleteComplaincePlanCharts = async (id) => {
        //delete the chart and set uploadedChart data to deleted
        try {
            await uploadRepository.deleteComplaincePlanCharts(id)

            let dataAfterDelete = complaincePlanCharts.filter((cp) => {
                if (cp.id !== id) return cp;
            })
            setComplaincePlanCharts(dataAfterDelete)
            toast({
                title: 'Success',
                description: `Chart Deleted`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
        } catch (e) {
            setApiError({ [UPLOAD_API.DELETE_CHART]: "error message" })
            toast({
                title: 'Delete Chart',
                description: `${e.toString()}`,
                status: 'success',
                duration: 5000,
                isClosable: true,
                position: 'top-right'
            })
        } finally {
            const index = uploadedCharts.findIndex(f => f.id === id)
            uploadedCharts[index] = { ...uploadedCharts[index], uploaded: false, cancelled: true }
            setUploadedCharts([...uploadedCharts])
        }
    }

    const mContext = {
        isSubmitting,
        uploadedCharts,
        apiError,
        uploadCharts,
        submitUploadedCharts,
        deleteChart,
        setUploadedCharts,
        complaincePlanCharts,
        complainacePlanUploadedCharts,
        setComplainacePlanUploadedCharts,
        uploadComplaincePlanCharts,
        submitUploadedComplaincePlanCharts,
        getUploadedComplaincePlanCharts,
        deleteComplaincePlanCharts,
        isLoadingGetCharts,
    };

    return (
        <UploadContext.Provider value={mContext}>
            {props.children}
        </UploadContext.Provider>
    );
};

export default UploadProvider;
