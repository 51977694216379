import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { FONT_FAMILY } from '../constants/Theme';
import annotationPlugin from 'chartjs-plugin-annotation';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  annotationPlugin
);
const CQLineChart = ({ chartData, xLabel, yLabel, xAxis, optionProps, chartProps = {}, maxTicksLimit }) => {

  
  
  const options = {
    animation: {
      duration: 0,
    },
    // maintainAspectRatio: false,
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
    },
    scales: {
      y: {
        beginAtZero: true,
        ticks: {
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 10,
          },
        },
        title: {
          display: true,
          text: yLabel,
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 12,
            weight: 'bold',
          },
        },
      },
      x: {
        ticks: {
          autoSkip: true,
          maxTicksLimit: maxTicksLimit ? maxTicksLimit : 12,
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 10,
          },
        },
        title: {
          display: true,
          text: xLabel,
          color: 'black',
          font: {
            family: FONT_FAMILY.ptSans,
            size: 12,
            weight: 'bold',
          },
        },
      },
    },
    elements: {
      point: {
        pointRadius: (data) => {
          return data?.parsed?.y === 0 ? 1 : 3
        }
      }
    },
    ...optionProps,
  };

  const labels = xAxis;

  if (!chartData) {
    return null;
  }
  if (chartData && !chartData.length) {
    return null;
  }

  const data = {
    labels,
    datasets: chartData,
  };
  return (
    <>
      <Line options={options} data={data} {...chartProps} />
    </>
  );
};
export default CQLineChart;
