import { Stack, Image, Flex } from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import { MailIcon } from '../../../constants/IconData';
import { QWatermark } from '../../../constants/ImageData';
import { CQForgotPassword } from '@prasanna-transcend/code-quick-components';
import { useContext } from 'react';
import { AuthContext } from '../../../providers/AuthProvider';
import { renderCoverImage } from '../../../components/AuthCoverImage';
import useDeviceScreen from '../../../hooks/useDeviceScreen';

export default function ForgotPasswordContainer() {
  const authContext = useContext(AuthContext);

  const { isTablet, isMobile } = useDeviceScreen();

  return (
    <Flex
      bgColor={AppColors.primary}
      // py={'5%'}
      // px={isMobile ? '20%' : '10%'}
      alignItems={'center'}
      justifyContent={'center'}
      minH={'100vh'}
    >
      {isMobile ? (
        <Image
          top={'5vh'}
          left={30}
          style={{ height: '95vh' }}
          position={'absolute'}
          src={QWatermark}
          alt="q-watermark"
        />
      ) : (
        <Image
          top={'5vh'}
          left={5}
          style={{ height: '80vh' }}
          position={'absolute'}
          src={QWatermark}
          alt="q-watermark"
        />
      )}
      <Stack
        spacing={-8}
        height={'730px'}
        direction={{ base: 'column', md: 'row' }}
        // marginTop={!isTablet ? '30%' : ''}
        // width={'100%'}
        px={!isTablet ? '7%' : '0'}
        width={'1150px'}
      >
        {renderCoverImage()}

        <CQForgotPassword
          onForgotPassword={authContext.onForgotPassword}
          isSubmitting={authContext.isSubmitting}
          title={'Forgot Password'}
          description={'Enter your email address to request a password reset'}
          buttonName={'Submit'}
          buttonProps={{ height: '50px', _hover: { bg: AppColors.secondary } }}
          emailProps={{
            leftIcon: <MailIcon style={{ marginTop: '10px' }} />,
            height: '50px',
          }}
        />
      </Stack>
    </Flex>
  );
}
