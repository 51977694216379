/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Box, HStack, Skeleton, Stack, Text } from '@chakra-ui/react';
import {
  CQReactTable,
  PrimaryButton,
  useBoolean,
} from '@prasanna-transcend/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
// import '../style.css';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../utils/my_audits.utils';
import AppColors from '../../../constants/AppColors';
import { EyeAvatarIcon, EyeHiddenIcon } from '../../../constants/IconData';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { DashboardContext } from '../../../providers/DashboardProvider';
import ROUTES from '../../../constants/Routes';
import BatchFileModal from './BatchFileModal';
import { STATUS } from '../../../constants/constants';
import { getUserType, replaceRoute } from '../../../utils/common.utils';
import { priceConverter } from '../../../utils/priceConverter';
import { toTitleCase } from '../../../utils/common.utils';
import { AuthContext } from '../../../providers/AuthProvider';

const columnHelper = createColumnHelper();

const ChartTable = route => {
  // states
  const [ordering, setOrdering] = useState('');
  const [currentBatch, setCurrentBatch] = useState({});

  // booleans
  const [isModalOpen, imoState] = useBoolean(false);

  // context data's
  const dashboardContext = useContext(DashboardContext);
  const authContext = useContext(AuthContext);
  const { isAllChartLoading, getAuditCharts, chartFilters } = dashboardContext;
  const { handleApplyFilter, filterParams } = dashboardContext;
  const { auditResults, isFetchingAuditResults } = dashboardContext;

  // other hooks
  const navigate = useNavigate();
  const loc = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const { state } = loc;

  const userTypeID = getUserType(authContext.currentUser);

  // states
  const storedPage = Number(searchParams.get('page') ?? 0);

  useEffect(() => {
    if (state && Object.keys(state).length) {
      handleApplyFilter(prev => ({ ...prev, ...state }));
    }
    dashboardContext.setUserType(userTypeID);
  }, []);

  useEffect(() => {
    loadChartData();
  }, [
    dashboardContext.userTypes,
    chartFilters,
    getAuditCharts.showOrdering,
    filterParams,
  ]);

  function loadChartData() {
    if (!Object.keys(filterParams).length) {
      return;
    }

    if (!Object.keys(dashboardContext.userTypes).length) {
      return;
    }

    if (!storedPage) {
      return;
    }

    if (storedPage !== getAuditCharts.currentPage) {
      return;
    }

    // call the API

    getAuditCharts.loadAPI();
  }

  useEffect(() => {
    const params = { ordering };

    if (storedPage) {
      params.page = storedPage;
    } else {
      setSearchParams({ page: getAuditCharts.currentPage });
    }

    getAuditCharts.setParams(params);
  }, [ordering, storedPage]);

  const handleAuditSheetView = useCallback(
    cellValue => {
      const auditSheetURL = replaceRoute(ROUTES.VIEW_AUDIT_SHEET, {
        batchId: cellValue,
      });

      setSearchParams({ page: getAuditCharts.currentPage });

      navigate(auditSheetURL);
    },
    [getAuditCharts.currentPage]
  );

  const columns = useMemo(
    () => [
      columnHelper.accessor('batch_id', {
        header: () => 'Upload #',
        id: 'batch_id',
        cell: info => {
          const chartData = info.row.original;
          const cellValue = info.getValue();
          return (
            <Text
              _hover={{ textDecoration: 'underline' }}
              cursor={'pointer'}
              onClick={() => {
                const batchDashboard = replaceRoute(ROUTES.BATCH_DASHBOARD, {
                  batchId: cellValue,
                });
                navigate(batchDashboard, {
                  state: { chartData: chartData },
                });
              }}
            >
              {info.renderValue()}
            </Text>
          );
        },
      }),
      columnHelper.accessor('cq_score', {
        id: 'cq_score',
        header: () => <span>RI Score</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return Math.round(cellValue);
        },
      }),
      columnHelper.accessor('provider_name', {
        id: 'provider_name',
        header: () => <span>Uploaded by</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return <Text>{toTitleCase(cellValue)}</Text>;
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        id: 'status',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return STATUS[cellValue];
        },
      }),
      columnHelper.accessor('outstanding_revenue', {
        header: () => 'Opportunity',
        id: 'outstanding_revenue',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return priceConverter(cellValue);
        },
      }),
      columnHelper.accessor('rvu', {
        header: () => 'No. of Errors',
        id: 'rvu',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('urgency', {
        header: () => 'Urgency',
        id: 'urgency',
        cell: info => {
          const cellValue = info.getValue();
          return cellValue ?? <Text color={AppColors.gray}>N/A</Text>;
        },
      }),

      columnHelper.accessor(row => row.batch_id, {
        id: 'actions',
        header: () => '',
        enableSorting: false,
        cell: info => {
          const cellValue = info.getValue();
          return (
            <HStack justifyContent={'center'}>
              <PrimaryButton
                bg={AppColors.secondary}
                fontSize={'14px'}
                fontWeight="normal"
                size="md"
                borderRadius="3xl"
                px="10"
                upload_id
                _hover={{
                  transition: 'all .3s ease',
                  bgColor: AppColors.white,
                  color: AppColors.secondary,
                  outline: `2px solid ${AppColors.secondary}`,
                }}
                onClick={() => handleAuditSheetView(cellValue)}
              >
                View
              </PrimaryButton>
              <EyeAvatarIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCurrentBatch(info.row.original);
                  imoState.on();
                }}
              />
            </HStack>
          );
        },
      }),
    ],
    [isAllChartLoading, getAuditCharts.currentPage]
  );

  function handlePreviousPage() {
    getAuditCharts.loadPrevious();
  }

  const handleNextPage = () => {
    getAuditCharts.loadNext();
  };

  function handleSizeChange(size) {
    getAuditCharts({ size });
  }

  const getTableData = async () => {
    const res = await dashboardContext.getFileObjectByBatchId(
      currentBatch.batch_id
    );
    return res;
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton w={'40%'} height="5px" />
        <Skeleton w={'50%'} height="5px" />
        <Skeleton w={'60%'} height="5px" />
        <Skeleton w={'70%'} height="5px" />
        <Skeleton w={'80%'} height="5px" />
        <Skeleton w={'90%'} height="5px" />
      </Stack>
    );
  };

  return (
    <Box>
      {isFetchingAuditResults ? (
        renderLoader()
      ) : (
        <CQReactTable
          isLoading={isAllChartLoading}
          data={auditResults}
          columns={columns}
          {...defaultReactTableProp}
          {...paginationProps(getAuditCharts)}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          onSizeChange={handleSizeChange}
          onAsc={header => {
            setOrdering(header);
          }}
          onDesc={header => {
            setOrdering(`-${header}`);
          }}
        />
      )}

      <BatchFileModal
        isOpen={isModalOpen}
        onClose={imoState.off}
        currentBatch={currentBatch}
        getTableData={getTableData}
        isLoading={dashboardContext.isFileLoading}
      />
    </Box>
  );
};
export default ChartTable;
