/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  Center,
  Circle,
  Flex,
  HStack,
  Skeleton,
  Stack,
  Text,
} from '@chakra-ui/react';
import { formatDistanceStrict } from 'date-fns';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate, useParams } from 'react-router-dom';
import AppColors from '../../../../constants/AppColors';
import { RebuttalIcon, RebuttalTickIcon } from '../../../../constants/IconData';
import ROUTES from '../../../../constants/Routes';
import { FONT_FAMILY } from '../../../../constants/Theme';
import batchDashboardRepository from '../../../../repositories/BatchRepository';
import { replaceRoute, toTitleCase } from '../../../../utils/common.utils';
import * as yup from 'yup';
import './batchcss.css';
import { yupResolver } from '@hookform/resolvers/yup';
import { FormTextInput } from '@prasanna-transcend/code-quick-components';
import RenderMessage from '../../../audit_sheet/components/RenderMessage';
import { BatchContext } from '../../../../providers/BatchProvider';

const BatchRebuttals = React.forwardRef((props, ref) => {
  const batchContext = useContext(BatchContext);

  const commentSchema = yup.object({
    comment: yup.string().required(),
  });

  const form = useForm({
    resolver: yupResolver(commentSchema),
  });

  const { control, handleSubmit, reset, setValue, getValues } = form;

  const [commentAdded, setCommentAdded] = useState(null);
  const { batchId } = useParams();
  const navigate = useNavigate();
  const {
    rebuttalData,
    isRebuttalLoading,
    fetchRebuttalData,
    setRebuttalData,
    filterParams,
  } = batchContext;
  React.useEffect(() => {
    if (filterParams.batch_id) {
      fetchRebuttalData();
    }
  }, [filterParams, commentAdded]);

  const addComment = async (parentComment, pi) => {
    try {
      const comment = getValues(`add-commeent-input-${pi}`);
      const body = {
        parent: parentComment?.id,
        audit_sheet_rows: parentComment?.audit_sheet_rows,
        audit_sheet_columns: parentComment?.audit_sheet_columns,
        tagged_user: parentComment?.tagged_user
          ? parentComment?.tagged_user
          : '',
        comment: comment,
        action: 'OPEN',
        batch: parentComment.batch,
      };

      // await rebuttalData.results.splice(pi, 1);
      const response = await batchDashboardRepository.addComment(body);
      reset({ comment: '' });
      let newCommentData = await parentComment?.reply_comments?.push(response);
      setRebuttalData([...rebuttalData, newCommentData]);
      setCommentAdded(true);
      return response;
    } catch (error) {
      setCommentAdded(null);
    }
  };

  const renderRebutalcomponent = () => {
    return (
      <Flex
        gap={5}
        p={5}
        // height={'600px'}
        flexDirection={'column'}
        overflowY={'scroll'}
        alignItems={'baseline'}
      >
        {/* ----comments------- */}
        {!rebuttalData ? (
          <Text>Loading Comments...</Text>
        ) : (
          rebuttalData.results.map((parentComment, pi) => {
            const parentCommentedFirstName = parentComment?.user?.first_name;
            const parentCommentedLastName = parentComment?.user?.last_name;
            return (
              <HStack alignItems={'baseline'} width={'100%'}>
                <Stack>
                  <Circle
                    className="rebutall_divider"
                    size="75px"
                    bg={AppColors.secondary}
                    color="white"
                    _hover={{
                      transition: 'all .1s ease',
                      bgColor: AppColors.secondary,
                      color: AppColors.white,
                    }}
                  >
                    <Center>
                      <Text p={2} fontSize={'sm'}>
                        {parentComment.file_name}
                      </Text>
                    </Center>
                  </Circle>
                  <Center className="rebutall_divider">
                    <Box height={'40px'} width={'3px'} bg={AppColors.gray} />
                  </Center>
                  <Center className="rebutall_divider">
                    <Circle size="20px" bg={AppColors.gray} color="white" />
                  </Center>
                  {parentComment.reply_comments.length ? (
                    <>
                      <Center className="rebutall_divider">
                        <Box
                          height={'40px'}
                          width={'3px'}
                          bg={AppColors.gray}
                        />
                      </Center>

                      <Center className="rebutall_divider">
                        <Circle size="20px" bg={AppColors.gray} color="white" />
                      </Center>
                    </>
                  ) : null}
                </Stack>

                <Stack width={'100%'}>
                  <Box width={'100%'} mb={'5px'}>
                    <Stack width={'100%'} mb={'50px'}>
                      <HStack width={'90%'}>
                        <FormTextInput
                          name={`add-commeent-input-${pi}`}
                          control={control}
                          id="comment"
                          size="md"
                          placeholder="Leave a comment..."
                          onChange={e => {
                            setValue('comment', e.target.value);
                          }}
                          onKeyDown={e => {
                            if (e.keyCode === 13) {
                              handleSubmit(addComment(parentComment, pi));
                            }
                          }}
                        />
                        <Button
                          _hover={{ bg: AppColors.secondary }}
                          color={AppColors.white}
                          borderRadius={'full'}
                          width={'200px'}
                          onClick={() => {
                            const auditSheetRoute = replaceRoute(
                              ROUTES.VIEW_AUDIT_SHEET,
                              {
                                batchId: batchId,
                              }
                            );
                            navigate(auditSheetRoute);
                          }}
                          bg={AppColors.secondary}
                        >
                          View
                        </Button>
                      </HStack>

                      <Stack key={pi} overflowY={'scroll'}>
                        <HStack mt={5} alignItems={'flex-end'}>
                          <Text
                            color={AppColors.secondary}
                            fontSize={'md'}
                            fontWeight={600}
                          >
                            {toTitleCase(
                              `${parentCommentedFirstName} ${parentCommentedLastName}`
                            )}
                          </Text>
                          <Text
                            color={AppColors.yellowColor}
                            fontSize={'sm'}
                            fontWeight={'semibold'}
                          >
                            added a rebuttal
                          </Text>

                          <RebuttalIcon
                            style={{ width: '20px', height: '20px' }}
                          />
                          <Text color={AppColors.gray} fontSize={'13px'}>
                            {formatDistanceStrict(
                              new Date(parentComment?.commented_date),
                              new Date(),
                              {
                                addSuffix: true,
                              }
                            )}
                          </Text>
                        </HStack>
                        <RenderMessage
                          comment={parentComment}
                          allUsers={batchContext.allUsers}
                        />
                        {parentComment?.reply_comments
                          ?.sort(
                            (replyComment, nextReplyComment) =>
                              new Date(nextReplyComment?.commented_date) -
                              new Date(replyComment?.commented_date)
                          )
                          ?.map((replyComments, ri) => {
                            const replyCommentFirstName =
                              replyComments?.user?.first_name;
                            const replyCommentLastName =
                              replyComments?.user?.last_name;
                            return (
                              <Stack key={ri}>
                                <HStack mt={5} alignItems={'baseline'}>
                                  {replyComments?.action === 'RESOLVED' && (
                                    <RebuttalTickIcon
                                      style={{
                                        width: '25px',
                                        height: '25px',
                                      }}
                                    />
                                  )}
                                  <Text color={AppColors.gray} fontWeight={600}>
                                    {' '}
                                    {toTitleCase(
                                      `${replyCommentFirstName} ${replyCommentLastName}`
                                    )}{' '}
                                  </Text>
                                  <RenderMessage
                                    comment={replyComments}
                                    allUsers={batchContext.allUsers}
                                  />
                                  <Text
                                    fontSize={'11px'}
                                    color={AppColors.gray}
                                  >
                                    {formatDistanceStrict(
                                      new Date(replyComments?.commented_date),
                                      new Date(),
                                      {
                                        addSuffix: true,
                                      }
                                    )}
                                  </Text>
                                </HStack>
                              </Stack>
                            );
                          })}
                      </Stack>
                    </Stack>
                  </Box>
                </Stack>
              </HStack>
            );
          })
        )}
      </Flex>
    );
  };

  const renderContainer = () => {
    return (
      <Box
        marginTop={3}
        // width={'100%'}
        bg={AppColors.white}
        // maxHeight={'350px'}
        padding={4}
        borderRadius={'lg'}
        shadow={'md'}
      >
        {/* <HStack width={'100%'} justifyContent={'space-between'} mb={3}>
          <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.gray}>
            Rebuttals
          </Text>
        </HStack> */}
        {renderTileHeader('Rebuttals')}
        {rebuttalData?.results?.length !== 0 ? (
          renderRebutalcomponent()
        ) : (
          <Center>No Rebuttals Comments</Center>
        )}
      </Box>
    );
  };

  const renderLoader = () => {
    return (
      <Stack
        mt={3}
        borderRadius={'lg'}
        shadow={'md'}
        bg={AppColors.white}
        p={4}
      >
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderTileHeader = (title, noDate) => {
    return (
      <HStack justifyContent={'space-between'}>
        {title ? (
          <Text color={AppColors.primary} fontFamily={FONT_FAMILY.ptSans}>
            {title}
          </Text>
        ) : null}
        {/* {!noDate ? (
          <Text
            fontSize={'xs'}
            color={AppColors.black}
            fontFamily={FONT_FAMILY.ptSans}
          >
            {filterParams.label === 'custom'
              ? null
              : filterParams.label === 'Day'
                ? dateFormat(filterParams.start_date)
                : `${dateFormat(filterParams.start_date)} - ${dateFormat(
                  filterParams.end_date
                )}`}
          </Text>
        ) : null} */}
      </HStack>
    );
  };
  const tileFailure = (title, message) => {
    return (
      <Box p={5} bg={'white'} borderRadius="md" width={'100%'}>
        {renderTileHeader(title)}
        <Box mt={10} textAlign="center" justifyContent={'center'}>
          <Text>
            {message ? message : 'Something went wrong, Please try again later'}
          </Text>
        </Box>
      </Box>
    );
  };

  const renderMainContent = () => {
    if (isRebuttalLoading) {
      return renderLoader();
    } else if (!rebuttalData) {
      return tileFailure('Rebuttals');
    } else {
      return renderContainer();
    }
  };

  return <Box ref={ref}>{renderMainContent()}</Box>;
});

export default BatchRebuttals;
