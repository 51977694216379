import { Box, Button, Center, Flex, FormLabel, Grid, GridItem, HStack, Progress, SimpleGrid, Spacer, Stack, Text } from '@chakra-ui/react';
import React, { useContext, useEffect, useMemo } from 'react';import useDeviceScreen from '../../../hooks/useDeviceScreen';
import { CrossIcon, PinIcon, RedCrossIcon, TickIcon, UploadIcon } from '../../../constants/IconData';
import { UploadContext } from '../../../providers/UploadProvider';
import { baseStyle , acceptStyle, focusedStyle, rejectStyle } from '../../upload/styles';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { DeleteIcon } from '@chakra-ui/icons';
import { useDropzone } from 'react-dropzone';
import AppColors from '../../../constants/AppColors';
import { FONT_FAMILY } from '../../../constants/Theme';

const CompliancePlan = () => {

    const {
        isSubmitting,
        uploadedCharts,
        setUploadedCharts,
        complaincePlanCharts,
        deleteChart,
        submitUploadedComplaincePlanCharts,
        uploadComplaincePlanCharts,
        deleteComplaincePlanCharts,
        getUploadedComplaincePlanCharts,
        isLoadingGetCharts
      } = useContext(UploadContext);

    useEffect(() => {
        getUploadedComplaincePlanCharts();
      }, []);

    const submitCharts = async () => {
      await submitUploadedComplaincePlanCharts();
      await getUploadedComplaincePlanCharts();
    };

    const {
      getRootProps,
      getInputProps,
      isFocused,
      isDragAccept,
      isDragReject,
    } = useDropzone({
      accept: {
        'text/doc': ['.doc', '.docx', '.pdf'],
      },
      useFsAccessApi: false,
      onDrop: async acceptedFiles => {
        // call upload chart here
        await uploadComplaincePlanCharts(acceptedFiles, 0);
      },
    });

    const { isWeb } = useDeviceScreen();

    const style = useMemo(
      () => ({
        ...baseStyle,
        ...(isFocused ? focusedStyle : {}),
        ...(isDragAccept ? acceptStyle : {}),
        ...(isDragReject ? rejectStyle : {}),
      }),
      [isFocused, isDragAccept, isDragReject]
    );

    const handleRemoveFile = async id => {
      await deleteChart(id);
    };

    const handleRemoveState = index => {
      const data = uploadedCharts.filter((chart, i) => i !== index);
      setUploadedCharts([...data]);
    };

    const handleDocumentDelete = id => {
      deleteComplaincePlanCharts(id);
    };

    return (
      <Box
        p={3}
        justifyContent={'space-between'}
        width={'100%'}
        m={'auto'}
        //   my={4}
        mt={16}
        fontSize={'xl'}
        bgColor={AppColors.white}
        borderRadius={'lg'}
        boxShadow={'md'}
      >
        <Stack direction="row" justifyContent={'space-between'} w={'99%'}>
          <Text
            color={AppColors.secondary}
            fontSize={'md'}
            fontWeight="bold"
            // pb={3}
          >
            Compliance Plan
          </Text>
        </Stack>
        <Box>
          <Center>
            <Flex
              borderRadius={10}
              minHeight="200px"
              width={isWeb ? '60%' : '100%'}
              // mt={10}
              bgColor={'white'}
              flexDirection={'column'}
            >
              <Flex
                m={5}
                className="container"
                style={{ cursor: 'pointer' }}
                flexDirection={'column'}
              >
                <Flex flexDirection={'column'} {...getRootProps({ style })}>
                  <input {...getInputProps()} />
                  <UploadIcon />
                  <HStack
                    flexWrap={'wrap'}
                    justifyContent={'center'}
                    alignItems={'center'}
                    marginTop="10px"
                    marginBottom="10px"
                  >
                    <PinIcon />
                    <Text
                      color={'black'}
                      fontSize={['8px', '10px', '12px', '16px']}
                    >
                      Add files here
                    </Text>
                  </HStack>
                  <HStack
                    width={'100%'}
                    flexWrap={'wrap'}
                    justifyContent={'center'}
                    alignItems={'center'}
                  >
                    <Text
                      color={'black'}
                      fontSize={['8px', '10px', '12px', '16px']}
                      fontWeight="normal"
                    >
                      Format :
                    </Text>
                    <Text
                      color={'black'}
                      fontSize={['8px', '10px', '12px', '16px']}
                      fontWeight="bold"
                    >
                      DOC, DOCX, PDF
                    </Text>
                  </HStack>
                </Flex>
              </Flex>
              {uploadedCharts?.length ? (
                <PerfectScrollbar
                  style={{
                    maxHeight: '200px',
                    margin: 35,
                    paddingRight: 10,
                    paddingBottom: 10,
                  }}
                >
                  {uploadedCharts.map((chart, index) => {
                    return (
                      <Box mt={3} mr={2} key={`uploaded-chart-${index}`}>
                        <HStack mb={2}>
                          <Text fontFamily={FONT_FAMILY.ptSans}>
                            {chart.name}
                          </Text>
                          <Spacer />
                          <Text fontFamily={FONT_FAMILY.ptSans}>
                            {chart.size}
                          </Text>
                          {chart.uploaded ? (
                            <CrossIcon
                              onClick={() => handleRemoveFile(chart.id)}
                              style={{ cursor: 'pointer' }}
                            />
                          ) : null}
                          {chart.uploaded ? <TickIcon /> : null}
                          {chart.cancelled ? (
                            <RedCrossIcon
                              style={{ cursor: 'pointer' }}
                              onClick={() => handleRemoveState(index)}
                            />
                          ) : null}
                        </HStack>
                        <Progress
                          value={chart.value}
                          size="xs"
                          colorScheme={chart.cancelled ? 'red' : 'teal'}
                          borderRadius={10}
                        />
                      </Box>
                    );
                  })}
                </PerfectScrollbar>
              ) : null}
              {uploadedCharts?.length ? (
                <Center mb={8}>
                  <Button
                    isLoading={isSubmitting}
                    onClick={() => submitCharts()}
                    variant="outline"
                    borderRadius={20}
                    borderWidth={2}
                    px={10}
                    _hover={{
                      transition: 'all .1s ease',
                      bgColor: AppColors.white,
                      color: AppColors.secondary,
                      outline: `2px solid ${AppColors.secondary}`,
                    }}
                    borderColor={AppColors.secondary}
                    disabled={
                      !(
                        uploadedCharts.every(chart => chart.id) &&
                        uploadedCharts.some(chart => chart.uploaded)
                      )
                    }
                  >
                    Submit
                  </Button>
                </Center>
              ) : null}
            </Flex>
          </Center>
        </Box>
        <Box
          p={3}
          maxHeight={200}
          overflow="scroll"
          overflowX={'hidden'}
          sx={{
            '&::-webkit-scrollbar': {
              width: '8px',
            },
            '&::-webkit-scrollbar-track': {
              boxShadow: 'inset 0 0 8px lightGrey',
              width: '8px',
              borderRadius: '8px',
            },
            '&::-webkit-scrollbar-thumb': {
              background: 'grey',
              borderRadius: '8px',
            },
          }}
        >
          {complaincePlanCharts?.map((r,i) => (
            <Grid gridTemplateColumns={'80% 20%'} key={`compliance-plan-chart-${i}`}>
              <Text
                _hover={{ textDecoration:'underline' }}
                fontSize={'md'}
                onClick={() => window.open(r.preview_url)}
                cursor={'pointer'}
                mt={1}
              >
                {r.name}
              </Text>
              <DeleteIcon
                color={'gray'}
                boxSize={'16px'}
                mt={1}
                textAlign={'right'}
                cursor={'pointer'}
                onClick={() => handleDocumentDelete(r.id)}
              />
            </Grid>
          ))}
        </Box>
      </Box>
    );
  };

export default CompliancePlan