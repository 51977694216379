import {
  auditedRuvColumns,
  defaultColumns,
  firstSetColumnKeyOrder,
  rvuColumnsOrder,
} from '../constants/constants';
import {
  AUDIT_COLUMN_HEADER,
  columnType,
  columnWidth,
  dynamicAuditColumnHeader,
} from '../pages/audit_sheet/constants';

// arrange column

function sortData(obj) {
  return Object.keys(obj)
    .sort((a, b) => {
      const hasNumberA = /\d+$/.test(a);
      const hasNumberB = /\d+$/.test(b);

      if (hasNumberA && hasNumberB) {
        const numberA = parseInt(a.match(/\d+$/)[0]);
        const numberB = parseInt(b.match(/\d+$/)[0]);
        return numberA - numberB;
      } else if (hasNumberA) {
        return 1;
      } else if (hasNumberB) {
        return -1;
      } else {
        return a.localeCompare(b);
      }
    })
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

/**
 *
 * @param { Array } sheetData
 * @param { Number } windowSize
 * @param { Boolean } view
 * @param { Array } providerOptions
 * @param { Boolean } isAuditedDollarDisabled
 * @param { Boolean } isProviderDollarDisabled
 *
 */

// agree and disAgree  column validator functions
function agreeValidator(value, callback) {
    setTimeout(() => {
      if (value.toLowerCase() === 'yes' || value === '-') {
        callback(true);
      } else {
        callback(false);
      }
    }, 1000);
  }

function arrangeColumns(columnsData) {
  const firstSetColumns = [];

  const billedIctColumns = [];
  const billedCptColumns = [];
  const billedModifierColumns = [];

  const providerRvuColumns = [];

  const auditedIctColumns = [];
  const auditedCptColumns = [];
  const auditedModifierColumns = [];

  const _auditedRuvColumns = [];
  const _defaultColumns = [];

  let output = [];

  columnsData.forEach(cd => {
    if (firstSetColumnKeyOrder.includes(cd?.data)) {
      firstSetColumns.push(cd);
    } else if (rvuColumnsOrder.includes(cd?.data)) {
      providerRvuColumns.push(cd);
    } else if (auditedRuvColumns.includes(cd?.data)) {
      _auditedRuvColumns.push(cd);
    } else if (defaultColumns.includes(cd?.data)) {
      _defaultColumns.push(cd);
    } else if (cd?.data.match('srvcs_no')) {
      billedCptColumns.push(cd);
    } else if (cd?.data.match('billed_icd')) {
      billedIctColumns.push(cd);
    } else if (cd?.data.match('billed_modifier')) {
      billedModifierColumns.push(cd);
    } else if (cd?.data.match('audited_icd')) {
      auditedIctColumns.push(cd);
    } else if (cd?.data.match('audited_cpt')) {
      auditedCptColumns.push(cd);
    } else if (cd?.data.match('audited_modifier')) {
      auditedModifierColumns.push(cd);
    }
  });
  _defaultColumns.sort((a, b) => defaultColumns.indexOf(a.data) - defaultColumns.indexOf(b.data))

  const ictCol = [...Object.values(sortData(billedIctColumns))];
  const cptCol = [...Object.values(sortData(billedCptColumns))];
  const modCol = [...Object.values(sortData(billedModifierColumns))];

  const aIctCol = [...Object.values(sortData(auditedIctColumns))];
  const aCptCol = [...Object.values(sortData(auditedCptColumns))];
  const aModCol = [...Object.values(sortData(auditedModifierColumns))];

  const col = [
    ...firstSetColumns,
    ...ictCol,
    ...cptCol,
    ...modCol,
    ...providerRvuColumns,

    ...aIctCol,
    ...aCptCol,
    ...aModCol,
    ..._auditedRuvColumns,
    ..._defaultColumns,
  ];

  output.push(col);

  return output.flat();
}

function prepareSrvcColumn(key, windowSize, view) {
  return {
    data: key,
    type: 'text',
    width: windowSize * 0.15,
    readOnly: view,
    renderer: 'customStylesRenderer',
  };
}

function prepareAuditedColumn(key, windowSize, view) {
  if (key.match('audited_icd')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.18,
      readOnly: view,
    };
  } else if (key.match('audited_cpt')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.19,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else if (key.match('audited_modifier')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else {
    return;
  }
}

/**
 *
 * @param {String} key
 * @param {Number} windowSize
 * @param {Boolean} view
 * @returns
 */

function prepareBilledColumn(key, windowSize, view) {
  if (key.match('billed_cpt')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else if (key.match('billed_modifier')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else if (key.match('billed_icd')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
    };
  } else {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  }
}

export function prepareAuditSheetColumn(
  _sheetData,
  windowSize,
  view,
  providerOptions,
  isAuditedDollarDisabled,
  isProviderDollarDisabled
) {
  const sheetData = [_sheetData[0]];

  const auditSheetColumns = [];

  sheetData.forEach(sd => {
    let columnProps = {};

    if (sd && Object.keys(sd).length) {
      Object?.keys(sd).forEach(key => {
        if (dynamicAuditColumnHeader[key]) {
          columnProps['data'] = key;
          columnProps['type'] = columnType[key];
          columnProps['width'] = windowSize * columnWidth[key];
          columnProps['readOnly'] = view;
          columnProps['renderer'] = 'customStylesRenderer';

          if (key === AUDIT_COLUMN_HEADER.RENDERING) {
            const providerNames = providerOptions.map(p => p.value);
            columnProps['source'] = providerNames;
          }

          if (key === AUDIT_COLUMN_HEADER.ENC_DT) {
            columnProps['dateFormat'] = 'MM-DD-YYYY';
            columnProps['datePickerConfig'] = {
              disableDayFn(date) {
                return date > new Date();
              },
            };
          }

          if (key === AUDIT_COLUMN_HEADER.PROVIDER_DOLLER_VALUE) {
            columnProps['readOnly'] = view || isProviderDollarDisabled;
          }

          if (key === AUDIT_COLUMN_HEADER.AUDITOR_DOLLER_VALUE) {
            columnProps['readOnly'] = view || isAuditedDollarDisabled;
          }

          if (
            key === AUDIT_COLUMN_HEADER.AGREE ||
            key === AUDIT_COLUMN_HEADER.DISAGREE
          ) {
            columnProps['validator'] = agreeValidator;
            columnProps['renderer'] = 'agreeRenderer';
          }
          auditSheetColumns.push(columnProps);
          columnProps = {};
        } else if (key?.match('srvcs_no')) {
          const srvcColumnProps = prepareSrvcColumn(key, windowSize, view);
          auditSheetColumns.push(srvcColumnProps);
        } else if (key?.match('billed')) {
          const billedColumnProps = prepareBilledColumn(key, windowSize, view);
          auditSheetColumns.push(billedColumnProps);
        } else if (key?.match('audited')) {
          const auditedColumnProps = prepareAuditedColumn(
            key,
            windowSize,
            view
          );
          auditSheetColumns.push(auditedColumnProps);
        }
      });
    }
  });

  const arrangedColumns = arrangeColumns(auditSheetColumns);

  return arrangedColumns;
}
