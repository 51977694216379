/* eslint-disable react-hooks/exhaustive-deps */
import { HStack, Box, Center } from "@chakra-ui/react"
import { useEffect } from "react"
import { useState } from "react"
import CQLineChart from "../../../components/CQLineChart"
import AppColors from "../../../constants/AppColors"
import { FONT_FAMILY } from "../../../constants/Theme"
import {
    CQSelect,
} from '@prasanna-transcend/code-quick-components';

const RVUOpportunity = ({ practiceChartData, stateChartData, radioClick, checked, dropdown }) => {
    const commonSelectStyles = {
        control: props => ({
            ...props,
            borderRadius: 7,
            paddingRight: 10,
            paddingLeft: 10,
            fontSize: '12px',
            color: AppColors.secondary,
            borderColor: AppColors.secondary
        }),
        placeholder: props => ({
            ...props,
            color: AppColors.secondary,
            fontWeight: 'bold'
        }),
        dropdownIndicator: props => ({
            ...props,
            color: AppColors.secondary
        }),
        singleValue: props => ({
            ...props,
            fontWeight: 'bold',
            color: AppColors.secondary,
        }),
        option: props => ({
            ...props,
            fontSize: '12px',
            zIndex: 100
        }),
        menu: props => ({
            ...props,
            zIndex: 100
        }),
    }
    const [selectedOption, setSelectedOption] = useState('')

    useEffect(() => {
        if (checked) {
            const temp = dropdown.find(op => op.value === checked)
            setSelectedOption(temp)
        }
    }, [checked, dropdown])

    return <Box>
        <CQLineChart
            chartData={[
                {
                    label: practiceChartData.text ? practiceChartData.text : 'Practice',
                    data: practiceChartData?.data,
                    borderColor: AppColors.primary,
                    backgroundColor: AppColors.primary,
                    tension: 0.4,
                },
                {
                    label: stateChartData.text ? stateChartData.text : 'State',
                    data: stateChartData?.data,
                    borderColor: AppColors.gray,
                    backgroundColor: AppColors.gray,
                    tension: 0.4,
                },
            ]}
            xAxis={stateChartData.label}
            optionProps={{
                plugins: {
                    legend: {
                        onClick: () => { },
                        display: true,
                        position: 'bottom',
                        align: 'end',
                        labels: {
                            boxWidth: 20,
                            boxHeight: 0.5,
                            color: 'black',
                            font: {
                                size: 10,
                                family: FONT_FAMILY.ptSans,
                            },
                        },
                    },
                },
                scales: {
                    y: {
                        max: 100,
                        beginAtZero: true,
                        ticks: {
                            // Include a dollar sign in the ticks
                            callback: function (value, index, ticks) {
                                return value + '%';
                            },
                            color: 'black',
                            font: {
                                family: FONT_FAMILY.ptSans,
                                size: 10,
                            }
                        },
                    },
                    x: {
                        beginAtZero: true,
                        ticks: {
                            color: 'black',
                            font: {
                                family: FONT_FAMILY.ptSans,
                                size: 10,
                            }
                        },
                    }
                },
            }}
        />
        <HStack mt={5} justifyContent='center'>
            <Box onClick={(e) => {
                e.stopPropagation()
            }}>
                {dropdown.length ? <CQSelect
                    options={dropdown}
                    placeholder='Select Option'
                    variant='primary'
                    isSearchable={false}
                    styles={commonSelectStyles}
                    value={selectedOption}
                    onChange={(data) => {
                        radioClick(data.value)
                    }}
                /> : <Center>
                    No data present for this account
                </Center>}
            </Box>
        </HStack>
    </Box>
}
export default RVUOpportunity