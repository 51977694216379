/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Box,
  Button,
  Circle,
  HStack,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalOverlay,
  SimpleGrid,
  Skeleton,
  Text,
} from '@chakra-ui/react';
import { Heading } from '@prasanna-transcend/code-quick-components';
import AddNewOption from './AddNewOption';
import { BackArrowIcon } from '../../constants/IconData';
import CustomCheckbox from './CustomCheckbox';
import AppColors from '../../constants/AppColors';

const scrollStyle = {
  '&::-webkit-scrollbar': {
    width: '10px',
  },
  '&::-webkit-scrollbar-track': {
    boxShadow: 'inset 0 0 10px lightGrey',
    width: '10px',
    borderRadius: '10px',
  },
  '&::-webkit-scrollbar-thumb': {
    background: 'grey',
    borderRadius: '10px',
  },
};

const SelectFormModal = ({
  isOpen,
  onClose,
  title = 'Add Health System',
  subTitle = 'Select',
  onSubmit,
  selectedValues = [],
  options = [],
  isFetching = true,
  isCreating = false,
  onCreateNewOption,
  addNewText = 'Add Others',
  renderSpecialtySelect,
  dataValue,
}) => {
  const [selectedOptions, setSelectedOptions] = useState(selectedValues || []);

  useEffect(() => {
    setSelectedOptions(selectedValues || []);
  }, [selectedValues]);

  useEffect(() => {
    if (dataValue && Object.keys(dataValue).length) {
      handleCheck(dataValue, true);
    }
  }, [dataValue]);
  /** --- DATA HANDLING ---  */
  function handleSubmit() {
    if (onSubmit && typeof onSubmit === 'function') {
      onSubmit(selectedOptions);
    }

    if (onClose && typeof onClose === 'function') {
      onClose();
    }
  }

  function handleCheck(option, isChecked) {
    let nextSelectedOptions = [...selectedOptions];
    const selectionOptionIndex = nextSelectedOptions.findIndex(
      ns => ns == option.value
    );
    if (isChecked && selectionOptionIndex < 0) {
      nextSelectedOptions.push(option.value);
    }

    if (!isChecked && selectionOptionIndex > -1) {
      nextSelectedOptions.splice(selectionOptionIndex, 1);
    }

    setSelectedOptions(nextSelectedOptions);
  }

  /** --- DATA RENDERING ---  */

  function renderGridItems() {
    if (isFetching) {
      return (
        <React.Fragment>
          <Skeleton h={8} />
          <Skeleton h={8} />
          <Skeleton h={8} />
          <Skeleton h={8} />
          <Skeleton h={8} />
          <Skeleton h={8} />
        </React.Fragment>
      );
    }
    return (
      <React.Fragment>
        {options.map(p => {
          const isChecked = selectedOptions.some(so => so == p.value);

          return p.label ? (
            <CustomCheckbox
              isChecked={isChecked}
              onChange={e => handleCheck(p, e.target.checked)}
              key={`form.value.${p.value}`}
            >
              {p.label}
            </CustomCheckbox>
          ) : null;
        })}
        {renderSpecialtySelect ? (
          renderSpecialtySelect()
        ) : (
          <AddNewOption
            key={`${addNewText}-add-new-option-${options.length + 1}`}
            label={addNewText}
            onSave={onCreateNewOption}
            isLoading={isCreating}
            options={options}
          />
        )}
      </React.Fragment>
    );
  }

  const onModalClose = () => {
    // setSelectedOptions([]);
    onClose();
  };

  return (
    <Modal
      size={'4xl'}
      isOpen={isOpen}
      onClose={onModalClose}
      isCentered={true}
    >
      <ModalOverlay style={{ backgroundColor: '#001A41' }} />
      <ModalContent position={'fixed'} px={16}>
        <HStack mt={4} py={4} justifyContent={'space-between'} w={'100%'}>
          <Box cursor={'pointer'} position={'absolute'} onClick={onModalClose}>
            <BackArrowIcon />
          </Box>
          <Heading textAlign={'center'} flex={1} variant={'h4'}>
            {title}
          </Heading>
        </HStack>

        <Box
          style={{
            zIndex: '1',
          }}
          position={'absolute'}
          top={-10}
          right={-3}
        >
          <Circle>
            <ModalCloseButton
              size={'sm'}
              style={{ color: AppColors.white }}
              backgroundColor={'#8894A6'}
              p={3}
              onClick={onClose}
              borderRadius={50}
            />
          </Circle>
        </Box>
        <Heading
          color={AppColors.secondary}
          textAlign={'center'}
          mb={8}
          variant={'h5'}
        >
          {subTitle}
        </Heading>
        <Box
          sx={scrollStyle}
          overflowY={'scroll'}
          className={'modalBody'}
          maxHeight={'400px'}
        >
          <ModalBody p={0} mb={2} minH={150}>
            <SimpleGrid columns={{ sm: 2, md: 3 }} spacing={4}>
              {renderGridItems()}
            </SimpleGrid>
          </ModalBody>
        </Box>

        <ModalFooter justifyContent={'center'}>
          <Button
            mr={3}
            borderRadius={'full'}
            px={12}
            borderWidth={2}
            borderColor={AppColors.secondary}
            onClick={onModalClose}
            variant={'outline'}
          >
            <Text fontWeight={'normal'} fontSize={'sm'}>
              Cancel
            </Text>
          </Button>
          <Button
            colorScheme={'blue'}
            bgColor={AppColors.secondary}
            borderRadius={'full'}
            px={12}
            onClick={handleSubmit}
          >
            <Text color={'white'} fontSize={'sm'}>
              Select
            </Text>
          </Button>
        </ModalFooter>
      </ModalContent>
    </Modal>
  );
};

export default React.memo(SelectFormModal);
