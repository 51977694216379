import { ChevronDownIcon } from "@chakra-ui/icons";
import { Box, Button, HStack, Text } from "@chakra-ui/react";
import AppColors from "../../constants/AppColors";
import { WhiteCalendarIcon } from "../../constants/IconData";
import { FONT_FAMILY } from "../../constants/Theme";
import { useComponentVisible } from "../../hooks";
import { dateFormat } from "../../utils/common.utils";
import NewDatePickerPopup from "../date_picker_popup/NewDatePickerPopup";

const CustomDateFilter = ({ filterParams, openDateFilter, isOpenDateFilter, onDateClose, onDateSubmit, isLoading, onSelectClick }) => {
    const { ref, isComponentVisible, setIsComponentVisible } = useComponentVisible(false);

    return (
        <Box position='relative'>
            <Button
                color={'white'}
                _hover={{
                    bg: AppColors.secondary,
                }}
                ml={2}
                bg={AppColors.secondary}
                borderRadius={'md'}
                fontFamily={FONT_FAMILY.ptSans}
                fontWeight="normal"
                onClick={() => {
                    //open date modal

                    setIsComponentVisible(prev => !prev)
                }}
            >
                <HStack>
                    <Text>{dateFormat(filterParams.start_date, 'MMM dd, yyyy')} - {dateFormat(
                        filterParams.end_date, 'MMM dd, yyyy'
                    )}</Text>
                    <WhiteCalendarIcon />
                    <ChevronDownIcon/>
                </HStack>
            </Button>
            <Box ref={ref}
            >
                <NewDatePickerPopup
                    onClose={onDateClose}
                    onSubmit={onDateSubmit}
                    isLoading={isLoading}
                    selectedValue={filterParams?.label?.toLowerCase()}
                    onSelectClick={onSelectClick}
                    isComponentVisible={isComponentVisible}
                    setIsComponentVisible={setIsComponentVisible}
                    filterParams={filterParams}
                />
            </Box>

        </Box>
    );
};
export default CustomDateFilter