/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  Button,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  Tooltip,
  useBoolean,
  VStack,
} from '@chakra-ui/react';
import { CQReactTable } from '@prasanna-transcend/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import AppColors from '../../../../constants/AppColors';
import ROUTES from '../../../../constants/Routes';
import { FONT_FAMILY } from '../../../../constants/Theme';
import { BatchContext } from '../../../../providers/BatchProvider';
import { defaultReactTableProp } from '../../../../utils/my_audits.utils';
import { priceConverter } from '../../../../utils/priceConverter';

const columnHelper = createColumnHelper();

const ChartAccuracyTable = ({
  chartAccuracydata,
  isLoading,
  setOrdering,
  navigate,
}) => {
  const columns = React.useMemo(
    () => [
      columnHelper.accessor('batch_id', {
        header: () => 'File Id',
        id: 'batch_id',
        cell: info => {
          return <Text>{info?.renderValue()}</Text>;
        },
      }),
      columnHelper.accessor('location', {
        header: () => (
          <Tooltip
            bg={'white'}
            color={'black'}
            w={200}
            placement="top"
            label="This is the line number in the audit sheet where the error was identified. Click on 'View' to see the error."
            aria-label="A tooltip"
          >
            Location
          </Tooltip>
        ),
        id: 'location',
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('error', {
        header: () => <span>Error</span>,
        id: 'error',
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('suggestion', {
        header: () => 'Suggestion',
        id: 'suggestion',
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('rvu_opportunity', {
        header: () => 'Opportunity',
        id: 'rvu_opportunity',
        cell: info => {
          const cellValue = info?.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return (
            <>
              {cellValue > 0 ? (
                <Text color={'#77C586'}>{priceConverter(cellValue)}</Text>
              ) : (
                <Text color={'red'}>{`-${priceConverter(-cellValue)}`}</Text>
              )}
            </>
          );
        },
      }),
      columnHelper.accessor('file_id', {
        header: () => 'Action',
        enableSorting: false,
        cell: info => {
          return (
            <Button
              size={'sm'}
              bg={AppColors.secondary}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.secondary,
                color: AppColors.white,
              }}
              color={'white'}
              borderRadius="3xl"
              px={10}
              fontWeight="normal"
              fontSize={'sm'}
              onClick={() => {
                navigate(
                  `${ROUTES.SINGLE_AUDIT_SHEET.replace(
                    ':uploadId',
                    info.row.original.chart_id
                  )}/?location=${info.row.original.location}`
                );
              }}
            >
              View
            </Button>
          );
        },
      }),
    ],
    // eslint-disable-next-line
    [chartAccuracydata]
  );

  const getTableCellProps = (cell, data) => {
    const startingHeaderId = ['error'];

    return {
      fontFamily: FONT_FAMILY.ptSans,
      textAlign: startingHeaderId.includes(data.column.id) ? 'left' : 'center',
      color: startingHeaderId.includes(data.column.id)
        ? AppColors.black
        : AppColors.gray,
    };
  };

  function getHeaderCellProps(header) {
    const startingHeaderId = ['error'];
    return {
      textTransform: 'none',
      fontFamily: FONT_FAMILY.ptSans,
      textAlign: startingHeaderId.includes(header.id) ? 'left' : 'center',
      fontWeight: 'bold',
      color: AppColors.black,
      justifyContent: startingHeaderId.includes(header.id)
        ? 'flex-start'
        : 'center',
    };
  }

  return (
    <Box
      id="fixedTableHeader"
      className="tableWraper"
      maxHeight={'400px'}
      overflowY={'scroll'}
    >
      <CQReactTable
        isLoading={isLoading}
        data={chartAccuracydata}
        columns={columns}
        {...defaultReactTableProp}
        getHeaderCellProps={header => {
          return {
            ...getHeaderCellProps(header),
          };
        }}
        getCellProps={getTableCellProps}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />
    </Box>
  );
};

const BatchChartAccuracyTable = React.forwardRef((props, ref) => {
  const navigate = useNavigate();

  const { batchId } = useParams();
  const [isFetching, ifState] = useBoolean(false);
  const [chartAccuracydata, setChartAccuracyData] = useState(null);
  const [ordering, setOrdering] = useState('');
  const {
    getChartAccuracy,
    chartAccuracyData,
    setChartAccuracyFilters,
    filterParams,
    chartAccuracyFilters,
    isChartAccuracyLoading,
  } = useContext(BatchContext);

  React.useEffect(() => {
    if (ordering) {
      setChartAccuracyFilters(prev => ({ ...prev, ordering }));
    }
  }, [ordering]);

  useEffect(() => {
    if (filterParams.batch_id) {
      getChartAccuracy();
    }
  }, [filterParams, chartAccuracyFilters]);

  const renderContent = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <ChartAccuracyTable
          chartAccuracydata={chartAccuracyData}
          isLoading={isChartAccuracyLoading}
          setOrdering={setOrdering}
          navigate={navigate}
        />
      </ScaleFade>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderMainContent = () => {
    if (isChartAccuracyLoading) {
      return renderLoader();
    } else if (!chartAccuracyData) {
      return renderFailedPage();
    } else {
      return renderContent();
    }
  };

  return (
    <Box
      ref={ref}
      marginTop={3}
      width={'100%'}
      bg={AppColors.white}
      padding={4}
      borderRadius={'lg'}
      shadow={'md'}
    >
      <HStack width={'100%'} justifyContent={'space-between'} mb={3}>
        <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
          Chart Accuracy
        </Text>
      </HStack>

      {renderMainContent()}
    </Box>
  );
});

export default BatchChartAccuracyTable;
