/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useState } from 'react';
import {
  Text,
  HStack,
  Box,
  Flex,
  Button,
  Center,
  Divider,
  useBoolean,
} from '@chakra-ui/react';
import { DateRange } from 'react-date-range';
import 'react-date-range/dist/styles.css';
import 'react-date-range/dist/theme/default.css';
import './datePicker.css';
import { add, format } from 'date-fns';
import AppColors from '../../constants/AppColors';
import { FONT_FAMILY } from '../../constants/Theme';
import { CQSelect } from '@prasanna-transcend/code-quick-components';
import { toTitleCase } from '../../utils/common.utils';

function NewDatePickerPopup(props) {
  const current = new Date();
  current.setMonth(current.getMonth() - 1);

  const { filterParams } = props;
  const { isComponentVisible, setIsComponentVisible, selectedValue } = props;
  const [isDateSelected, idsState] = useBoolean(false);
  const [selectedOption, setSelectedOption] = useState({});
  const selectedFilter = JSON.parse(localStorage.getItem('selectedFilter'));
  const selectedDates = JSON.parse(localStorage.getItem('customDateFilter'));

  const [toDate, setToDate] = useState([
    {
      startDate: filterParams.previous_start_date
        ? new Date(filterParams.previous_start_date)
        : new Date(),
      endDate: filterParams.previous_end_date
        ? new Date(filterParams.previous_end_date)
        : new Date(),
      key: 'selection',
    },
  ]);
  const [fromDate, setFromDate] = useState([
    {
      startDate: filterParams.start_date
        ? new Date(filterParams.start_date)
        : add(new Date(), { days: -30 }),
      endDate: filterParams.end_date
        ? new Date(filterParams.end_date)
        : new Date(),
      key: 'selection',
    },
  ]);

  useEffect(() => {
    if (
      selectedDates &&
      selectedFilter === 'custom' &&
      Object.keys(selectedDates)?.length
    ) {
      idsState.on();
      setFromDate([
        {
          startDate: new Date(filterParams.start_date),
          endDate: new Date(filterParams.end_date),
          key: 'selection',
        },
      ]);
      setToDate([
        {
          startDate: new Date(filterParams.previous_start_date),
          endDate: new Date(filterParams.previous_end_date),
          key: 'selection',
        },
      ]);
    }
  }, [filterParams]);

  useEffect(() => {
    if(selectedValue){
      setSelectedOption({
        label: toTitleCase(selectedValue),
        value: selectedValue,
      });
    }
    
  }, [selectedValue]);

  const commonSelectStyles = {
    control: props => ({
      ...props,
      borderRadius: 7,
      paddingRight: 10,
      paddingLeft: 10,
      fontSize: '12px',
      color: AppColors.secondary,
      borderColor: AppColors.secondary,
    }),
    placeholder: props => ({
      ...props,
      color: AppColors.secondary,
      fontWeight: 'bold',
    }),
    dropdownIndicator: props => ({
      ...props,
      color: AppColors.secondary,
    }),
    singleValue: props => ({
      ...props,
      fontWeight: 'bold',
      color: AppColors.secondary,
    }),
    menuList: (provided, state) => ({
      ...provided,
      paddingTop: 0,
      paddingBottom: 0,
    }),
    // option: (props, { data, isSelected }) => ({
    //     ...props,
    //     fontSize: '12px',
    //     zIndex: 100,
    //     background: isSelected ? AppColors.hoverColor : data.color,
    //     color: isSelected ? AppColors.hoverColor : data.color
    // }),
    menu: props => ({
      ...props,
      zIndex: 100,
    }),
  };

  const averageDates = async (
    fromStartDate,
    fromEndDate,
    toStartDate,
    toEndDate
  ) => {
    try {
      // await props.onSubmit({
      //     start_date: toStartDate ? new Date(toStartDate) : '',
      //     end_date: toEndDate ? new Date(toEndDate) : '',
      //     previous_start_date: fromStartDate ? new Date(fromStartDate) : '',
      //     previous_end_date: fromEndDate ? new Date(fromEndDate) : '',
      // });

        const formatDate = date => {
        let convertDate;
          const parts = date.split('-');

          const year = parseInt(parts[0], 10);
          const month = parseInt(parts[1], 10) - 1;
          const day = parseInt(parts[2], 10);
          const parsedDate = new Date(year, month, day);

          if (!isNaN(parsedDate.getTime())) {
            const formattedDate = `${(parsedDate.getMonth() + 1)
              .toString()
              .padStart(2, '0')}-${parsedDate
              .getDate()
              .toString()
              .padStart(2, '0')}-${parsedDate.getFullYear()}`;
            convertDate = formattedDate;
          }
          return new Date(convertDate);
        };

      const selectedDates = {
        start_date: formatDate(toStartDate),
        end_date: formatDate(toEndDate),
        previous_start_date: formatDate(fromStartDate),
        previous_end_date: formatDate(fromEndDate),
    };
      await props.onSubmit({ ...selectedDates });
      window.localStorage.setItem(
        'customDateFilter',
        JSON.stringify(selectedDates)
      );
    } catch (error) {
    } finally {
      if (props.onClose) {
        props.onClose();
      }
    }
  };

  const onToDateChange = item => {
    idsState.on();
    setToDate([item.selection]);
  };
  const onFromDateChange = item => {
    idsState.on();
    setFromDate([item.selection]);
  };
  return isComponentVisible ? (
    <Box
      background={'white'}
      fontFamily={FONT_FAMILY.ptSans}
      zIndex={10}
      boxShadow={'lg'}
      borderRadius={'md'}
      fontSize="sm"
      fontWeight={'normal'}
      position="absolute"
      right={'2px'}
      top={'50px'}
    >
      <HStack>
        {selectedOption.value === 'custom' ? (
          <Box
            borderRadius={'md'}
            backgroundColor={AppColors.white}
            transition="all .5s ease-in-out"
          >
            <Flex justifyContent={'center'} gap={3}>
              <DateRange
                className="datepicker"
                onChange={item => onFromDateChange(item)}
                moveRangeOnFirstSelection={false}
                ranges={fromDate}
                maxDate={new Date()}
                rangeColors={[AppColors.primary]}
                showDateDisplay={false}
                dateDisplayFormat={'dd/MM/yyyy'}
                endDatePlaceholder={'dd/mm/yyyy'}
                color={AppColors.primary}
              />
              <Center height="300px">
                <Divider orientation="vertical" />
              </Center>
              <DateRange
                className="datepicker1"
                onChange={item => onToDateChange(item)}
                moveRangeOnFirstSelection={false}
                ranges={toDate}
                rangeColors={[AppColors.secondary]}
                showDateDisplay={false}
                maxDate={new Date()}
                dateDisplayFormat={'dd/MM/yyyy'}
                endDatePlaceholder={'dd/mm/yyyy'}
                color={AppColors.secondary}
              />
            </Flex>
          </Box>
        ) : null}
        <Box
          borderRadius={'md'}
          backgroundColor={AppColors.white}
          width={'400px'}
          // height={'350px'}
          p={5}
        >
          <CQSelect
            options={[
              { label: 'Week', value: 'week' },
              { label: 'Month', value: 'month' },
              { label: 'Year', value: 'year' },
              { label: 'Custom', value: 'custom' },
            ]}
            placeholder="Select Option"
            variant="primary"
            isSearchable={false}
            styles={commonSelectStyles}
            value={selectedOption}
            onChange={data => {
              setSelectedOption(data);
              if (data.value === 'custom') {
                localStorage.setItem(
                  'selectedFilter',
                  JSON.stringify('custom')
                );
              } else {
                props.onSelectClick(data);
                setIsComponentVisible(false);
              }
            }}
          />
          {selectedOption.value === 'custom' ? (
            <Box>
               <Text mt={2} fontWeight={"bold"}>Recent Dates </Text>
              <HStack mb={10} mt={2}>
                <Box width={'100%'}>
                 
                  <Text fontFamily={FONT_FAMILY.ptSans}>From</Text>
                  <Text
                    borderWidth={1}
                    borderColor={'grey'}
                    borderRadius={'md'}
                    px={2}
                    py={2}
                  >
                    {isDateSelected
                      ? format(fromDate[0].startDate, 'MM/dd/yyyy')
                      : 'MM/DD/YYYY'}
                  </Text>
                </Box>

                <Box width={'100%'}>
                  <Text fontFamily={FONT_FAMILY.ptSans}>To</Text>
                  <Text
                    borderWidth={1}
                    borderColor={'grey'}
                    borderRadius={'md'}
                    px={2}
                    py={2}
                  >
                    {isDateSelected
                      ? format(fromDate[0].endDate, 'MM/dd/yyyy')
                      : 'MM/DD/YYYY'}
                  </Text>
                </Box>
              </HStack>
             
              <Text fontWeight={'bold'} color={AppColors.secondary}>
                Compare with
              </Text>
              <Text fontWeight={'bold'} >
                Previous Dates 
              </Text>
              <HStack>
                <Box width={'100%'}>
                  <Text fontFamily={FONT_FAMILY.ptSans}>From</Text>
                  <Text
                    borderWidth={1}
                    borderColor={'grey'}
                    borderRadius={'md'}
                    px={2}
                    py={2}
                  >
                    {isDateSelected
                      ? format(toDate[0].startDate, 'MM/dd/yyyy')
                      : 'MM/DD/YYYY'}
                  </Text>
                </Box>

                <Box width={'100%'}>
                  <Text fontFamily={FONT_FAMILY.ptSans}>To</Text>
                  <Text
                    borderWidth={1}
                    borderColor={'grey'}
                    borderRadius={'md'}
                    px={2}
                    py={2}
                  >
                    {isDateSelected
                      ? format(toDate[0].endDate, 'MM/dd/yyyy')
                      : 'MM/DD/YYYY'}
                  </Text>
                </Box>
              </HStack>

              <HStack marginTop={10} width={'100%'}>
                <Button
                  fontWeight={'normal'}
                  fontSize="sm"
                  variant="outline"
                  backgroundColor={'white'}
                  width={'100%'}
                  onClick={() => {
                    props.onClose();
                    setSelectedOption({
                      label: toTitleCase(selectedValue),
                      value: selectedValue,
                    });
                    setToDate([
                      {
                        startDate: filterParams.previous_start_date
                          ? new Date(filterParams.previous_start_date)
                          : new Date(),
                        endDate: filterParams.previous_end_date
                          ? new Date(filterParams.previous_end_date)
                          : new Date(),
                        key: 'selection',
                      },
                    ]);
                    setFromDate([
                      {
                        startDate: filterParams.start_date
                          ? new Date(filterParams.start_date)
                          : add(new Date(), { days: -30 }),
                        endDate: filterParams.end_date
                          ? new Date(filterParams.end_date)
                          : new Date(),
                        key: 'selection',
                      },
                    ]);
                    setIsComponentVisible(false);
                  }}
                >
                  Cancel
                </Button>
                <Button
                  fontWeight={'normal'}
                  fontSize="sm"
                  width={'100%'}
                  color={'white'}
                  backgroundColor={AppColors.primary}
                  onClick={() => {
                    averageDates(
                      format(toDate[0].startDate, 'yyyy-MM-dd'),
                      format(toDate[0].endDate, 'yyyy-MM-dd'),
                      format(fromDate[0].startDate, 'yyyy-MM-dd'),
                      format(fromDate[0].endDate, 'yyyy-MM-dd')
                    );
                    setIsComponentVisible(false);

                    // if (isSelected) {
                    // } else {
                    //     averageDates(
                    //         null,
                    //         null,
                    //         format(fromDate[0].startDate, 'yyyy-MM-dd'),
                    //         format(fromDate[0].endDate, 'yyyy-MM-dd'),
                    //     );
                    // }
                  }}
                  isLoading={props.isLoading}
                  spinnerPlacement="end"
                  loadingText="Applying"
                  _hover={{ backgroundColor: AppColors.primary }}
                  disabled={selectedOption.value !== 'custom'}
                >
                  Apply
                </Button>
              </HStack>
            </Box>
          ) : null}
        </Box>
      </HStack>
    </Box>
  ) : null;
}

export default NewDatePickerPopup;
