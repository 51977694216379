/* eslint-disable react-hooks/exhaustive-deps */
import {
  Box,
  CircularProgress,
  CircularProgressLabel,
  Grid,
  GridItem,
  HStack,
  Skeleton,
  Switch,
  Text,
} from '@chakra-ui/react';
import React, { useContext, useEffect, useState } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import {
  Heading,
  PrimaryButton,
  useBoolean,
} from '@prasanna-transcend/code-quick-components';
import { FONT_FAMILY } from '../../constants/Theme';
import AppColors from '../../constants/AppColors';
import CQLineChart from '../../components/CQLineChart';
import { TriangleIcon, TriangleRedIcon } from '../../constants/IconData';
import RevenueTile from '../../components/RevenueTile';
import { priceConverter } from '../../utils/priceConverter';
import DashboardProvider, {
  DashboardContext,
} from '../../providers/DashboardProvider';
import ProvidersTable from './components/ProvidersTable';
import { withProvider } from '../../hoc/withProvider';
import UploadTable from './components/UploadTable';
import HospitalsTable from './components/HospitalTable';
import { getDateInterval } from './getDateInterval';
import { add, format } from 'date-fns';
import { XAXISLABEL } from '../../constants/constants';
import TilePopup from './components/TilePopup';
import { getChartData } from './getChartData';
import { AuthContext } from '../../providers/AuthProvider';
import {
  getCurrentUserTypeId,
  getCurrentUserTypeName,
} from '../../utils/getCurrentUserTypeId';
import DepartmentTable from './components/DepartmentTable';
import PhysicianTable from './components/PhysicianTable';
import RVUOpportunity from './components/RVUOpportunity';
import AIRiskMonitor from './components/AIRiskMonitor';
import {
  convertToPositiveDollar,
  replaceRoute,
} from '../../utils/common.utils';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useRef } from 'react';
import CustomDateFilter from '../../components/dashboard_components/DashboardFilter';
import CustomIcon from '../audit_sheet/components/CustomIcon';
import ROUTES from '../../constants/Routes';
import { utcToZonedTime } from 'date-fns-tz';

const DashboardContainer = props => {
  const authContext = useContext(AuthContext);
  const dashboardContext = useContext(DashboardContext);
  const {
    filterParams,
    getRVUOppDropdown,
    dropdown,
    setRVUOppFilter,
    showComparison,
    setShowComparison,
    providers,
  } = dashboardContext;
  const [chartActivity, setChartActivity] = useState({});
  const [revenueOpp, setRevenueOpp] = useState({});
  const [isOpenDateFilter, iodfState] = useBoolean(false);
  const [isLoading] = useBoolean(false);
  const [isTileModalOpen, itmState] = useBoolean(false);
  const [currentTile, setCurrentTile] = useState(null);
  const [currentUserType, setCurrentUserType] = useState({});
  const [name, setName] = useState('');
  const [practiceChartData, setPracticeChartData] = useState({});
  const [stateChartData, setStateChartData] = useState({});
  const [checked, setChecked] = useState('');
  const { currentUser } = authContext;
  const [queryParameters] = useSearchParams('search');
  const table = queryParameters.get('table');
  const navigate = useNavigate();
  const isUserProvider = currentUser?.user_type === 'PROVIDER';

  const yesterday = add(new Date(), {
    days: -1,
  });
  const providerRef = useRef();

  const initialSelectedFilter = localStorage.getItem('selectedFilter')
    ? localStorage.getItem('selectedFilter')
    : JSON.stringify('month');

  useEffect(() => {
    localStorage.setItem('selectedFilter', initialSelectedFilter);
    if (table) {
      window.scrollTo(0, providerRef.current.offsetTop);
    }
  }, []);

  const selectedFilter = JSON.parse(localStorage.getItem('selectedFilter'));

  useEffect(() => {
    const data = getCurrentUserTypeName(authContext.currentUser);
    setName(data);
    const obj = getCurrentUserTypeId(authContext.currentUser);
    setCurrentUserType(obj);
    let initialFilterParams = {};
    if (selectedFilter) {
      if (selectedFilter.toLowerCase() === 'custom') {
        const selectedCustomDates = JSON.parse(
          window.localStorage.getItem('customDateFilter')
        );
        const haveSelectedCustomDates =
          selectedCustomDates && Object.keys(selectedCustomDates)?.length;
        initialFilterParams = {
          label: 'custom',
          start_date: haveSelectedCustomDates
            ? new Date(selectedCustomDates?.start_date)
            : new Date(),
          end_date: haveSelectedCustomDates
            ? new Date(selectedCustomDates?.end_date)
            : new Date(),
          previous_start_date: haveSelectedCustomDates
            ? new Date(selectedCustomDates?.previous_start_date)
            : new Date(),
          previous_end_date: haveSelectedCustomDates
            ? new Date(selectedCustomDates?.previous_end_date)
            : new Date(),
        };
      } else {
        const data = getDateInterval(selectedFilter);
        initialFilterParams = {
          label: selectedFilter,
          ...data,
        };
      }
    } else {
      initialFilterParams = {
        label: 'month',
        start_date: add(yesterday, {
          days: -30,
        }),
        end_date: yesterday,
        previous_start_date: add(yesterday, {
          days: -60,
        }),
        previous_end_date: add(yesterday, {
          days: -30,
        }),
      };
    }
    dashboardContext.handleApplyFilter({
      ...filterParams,
      ...initialFilterParams,
      ...obj,
    });
  }, [authContext.currentUser]);

  const getData = async () => {
    if (
      filterParams.health_system_id ||
      filterParams.hospital_id ||
      filterParams.department_id ||
      filterParams.provider_id
    ) {
      dashboardContext.getTotalUploads();
      dashboardContext.getCQScore();
      dashboardContext.getCQEstimatedRev();
      dashboardContext.getAIRisk();
      if (dashboardContext.rvuOppFilter['rvu_opportunity_type']) {
        dashboardContext.getRVUOpp();
      }
      const revenue = await dashboardContext.getRevenueOpp();
      setRevenueOpp(revenue);
      const response = await dashboardContext.getChartActivity(currentUserType);
      setChartActivity(response);
    }
  };

  useEffect(() => {
    if (dropdown.length) {
      setChecked(dropdown[0]['value']);
      setRVUOppFilter({ rvu_opportunity_type: dropdown[0]['value'] });
    }
  }, [dropdown]);

  useEffect(() => {
    if (
      (filterParams.health_system_id ||
        filterParams.hospital_id ||
        filterParams.department_id ||
        filterParams.provider_id) &&
      dashboardContext.rvuOppFilter['rvu_opportunity_type']
    ) {
      dashboardContext.getRVUOpp();
    }
  }, [dashboardContext.rvuOppFilter]);

  useEffect(() => {
    const option = dashboardContext.rvuOppFilter['rvu_opportunity_type'];
    if (
      dashboardContext.rvuOpp &&
      Object.keys(dashboardContext.rvuOpp).length
    ) {
      const data = dashboardContext.rvuOpp[option];
      let practiceChartData = { label: [], data: [] };
      if (data?.practice_distribution) {
        practiceChartData.label = Object.keys(data.practice_distribution);
        practiceChartData.data = Object.keys(data.practice_distribution).map(
          d => data.practice_distribution[d]
        );
      }
      let stateChartData = { label: [], data: [] };
      if (data?.state_distribution) {
        stateChartData.label = Object.keys(data.state_distribution);
        stateChartData.data = Object.keys(data.state_distribution).map(
          d => data.state_distribution[d]
        );
      }
      setPracticeChartData(practiceChartData);
      setStateChartData(stateChartData);
    }
  }, [dashboardContext.rvuOpp]);

  useEffect(() => {
    if (
      filterParams.health_system_id ||
      filterParams.hospital_id ||
      filterParams.department_id ||
      filterParams.provider_id
    ) {
      getRVUOppDropdown();
      if (Object.keys(currentUserType)?.length !== 0) {
        getData();
      }
    }
  }, [filterParams]);

  useEffect(() => {
    if (
      filterParams.health_system_id ||
      filterParams.hospital_id ||
      filterParams.department_id ||
      filterParams.provider_id
    ) {
      // eslint-disable-next-line
      dashboardContext.getProviders();
    }
  }, [dashboardContext.providerFilter, dashboardContext.filterParams]);

  const radioClick = option => {
    setChecked(option);
    setRVUOppFilter(prev => ({ ...prev, rvu_opportunity_type: option }));
  };

  const onDateSubmit = dates => {
    const dateObject = {};
    if (Object.keys(dates)?.length) {
      Object.keys(dates).forEach(key => {
        dateObject[key] = dates[key] ? new Date(dates[key]) : '';
      });
    }
    dashboardContext.handleApplyFilter({
      ...filterParams,
      ...dateObject,
      label: 'custom',
    });
    localStorage.setItem('customDateFilter', JSON.stringify({ ...dateObject }));
    setShowComparison(false);
    iodfState.off();
  };

  const onDateClose = () => {
    iodfState.off();
    // if (filterParams.label === 'custom') {
    //   const data = getDateInterval('Month');
    //   dashboardContext.handleApplyFilter({
    //     ...filterParams,
    //     ...data,
    //     label: 'Month',
    //   });
    // }
  };

  const tileLoader = () => {
    return (
      <Box mx={3}>
        <Skeleton mt={5} w={'100%'} h={25} borderRadius="md" />
        <Skeleton mt={10} w={'100%'} h={200} borderRadius="md" />
        <Skeleton my={5} w={'50%'} h={25} borderRadius="md" />
      </Box>
    );
  };

  const tileFailure = (title, message) => {
    return (
      <Box
        p={isTileModalOpen ? 0 : 5}
        bg={'white'}
        borderRadius="md"
        width={'100%'}
      >
        {renderTileHeader(title)}
        <Box mt={10} textAlign="center" justifyContent={'center'}>
          <Text>
            {message ? message : 'Something went wrong, Please try again later'}
          </Text>
        </Box>
      </Box>
    );
  };

  const renderTileHeader = (title, noDate) => {
    return (
      <HStack justifyContent={'space-between'}>
        {title ? (
          <Text
            fontSize={isTileModalOpen ? 'lg' : '18px'}
            color={AppColors.primary}
            fontFamily={FONT_FAMILY.ptSans}
          >
            {title}
          </Text>
        ) : null}
        {/* {!noDate ? (
          <Text
            fontSize={'xs'}
            color={AppColors.black}
            fontFamily={FONT_FAMILY.ptSans}
          >
            {filterParams.label === 'custom'
              ? null
              : filterParams.label === 'Day'
                ? dateFormat(filterParams.start_date)
                : `${dateFormat(filterParams.start_date)} - ${dateFormat(
                  filterParams.end_date
                )}`}
          </Text>
        ) : null} */}
      </HStack>
    );
  };

  const renderTileFooter = ({ diff, score }) => {
    return isTileModalOpen ? (
      <Box>
        <HStack mt={4} ml={7} justifyContent="center">
          <Text fontSize={'4xl'} ml={7} fontFamily={FONT_FAMILY.ptSans}>
            {score ?? 0}
          </Text>
          {diff >= 0 ? (
            <TriangleIcon style={{ height: 20, width: 20 }} />
          ) : (
            <TriangleRedIcon style={{ height: 20, width: 20 }} />
          )}
          <Text
            fontFamily={FONT_FAMILY.ptSans}
            fontSize={'5xl'}
            fontWeight="bold"
            mt={3}
          >
            {convertToPositiveDollar(diff)}
          </Text>
        </HStack>
      </Box>
    ) : (
      <Box>
        <HStack mt={4} ml={7}>
          {diff >= 0 ? <TriangleIcon /> : <TriangleRedIcon />}
          <Text fontSize={'xs'} fontFamily={FONT_FAMILY.ptSans}>
            {convertToPositiveDollar(diff)}
          </Text>
        </HStack>
        <Text
          fontFamily={FONT_FAMILY.ptSans}
          fontSize={'5xl'}
          fontWeight="bold"
          mt={3}
          ml={7}
        >
          {score ?? 0}
        </Text>
      </Box>
    );
  };

  const renderTotalUploads = () => {
    if (dashboardContext.isTotalUploadLoading) {
      return tileLoader();
    }
    if (dashboardContext.error['TOTAL_UPLOADS']) {
      return tileFailure(
        'Total Charts',
        dashboardContext.error['TOTAL_UPLOADS']
      );
    }
    const {
      upload_difference,
      current_graph: graph,
      previous_graph,
      unit_type,
      current_total_uploads_count,
      filter_dates,
    } = dashboardContext.totalUpload;

    let chartData = { label: [], data: [] };
    let previousChartData = { label: [], data: [] };



    if (graph) {

      chartData = getChartData(
        filterParams.label,
        graph,
        unit_type,
        previous_graph,
        showComparison
      );
      previousChartData = getChartData(
        filterParams.label,
        previous_graph,
        unit_type,
        [],
        showComparison
      );
    }

    const startDateUTC = utcToZonedTime(
      new Date(filter_dates ? new Date(filter_dates['start_date']) : new Date())
    );

    const endDateUTC = utcToZonedTime(
      new Date(filter_dates ? new Date(filter_dates['end_date']) : new Date())
    );

    let chartDataProp = [
      {
        label: `${format(new Date(startDateUTC), 'MMM dd, yyyy')} - ${format(
          new Date(endDateUTC),
          'MMM dd, yyyy'
        )}`,
        data: chartData.data,
        borderColor: AppColors.primary,
        backgroundColor: AppColors.primary,
        tension: 0.4,
      },
    ];

    if (filterParams.label.toLowerCase() === 'custom' && showComparison) {
      chartDataProp = [
        {
          label: `${format(new Date(startDateUTC), 'MMM dd, yyyy')} - ${format(
            new Date(endDateUTC),
            'MMM dd, yyyy'
          )}`,
          data: chartData.data,
          borderColor: AppColors.primary,
          backgroundColor: AppColors.primary,
          tension: 0.4,
        },
        {
          label: `${format(
            new Date(filterParams?.previous_start_date),
            'MMM dd, yyyy'
          )} - ${format(
            new Date(filterParams.previous_end_date),
            'MMM dd, yyyy'
          )}`,
          data: previousChartData?.data,
          borderColor: AppColors.secondary,
          backgroundColor: AppColors.secondary,
          tension: 0.4,
        },
      ];
    }

    return (
      <Box
        p={isTileModalOpen ? 0 : 5}
        bg={'white'}
        borderRadius="md"
        width={'100%'}
      >
        {renderTileHeader('Total Charts')}
        <Box mt={4}>
          <CQLineChart
            chartData={chartDataProp}
            yLabel="# of Uploads"
            xLabel={XAXISLABEL[filterParams.label]}
            xAxis={chartData.label}
            maxTicksLimit={12}
            optionProps={{
              plugins: {
                legend: {
                  onClick: () => {},
                  // display: filterParams.label === 'custom' ? true : false,
                  display: true,
                  position: 'bottom',
                  align: 'end',
                  labels: {
                    boxWidth: 20,
                    boxHeight: 0.5,
                    color: 'black',
                    font: {
                      size: 10,
                      family: FONT_FAMILY.ptSans,
                    },
                  },
                },
                tooltip: {
                  callbacks: {
                    label: context => {
                      return context.parsed.y;
                    },
                    title: data => {
                      const context = data[0];
                      if (
                        context.datasetIndex === 1 &&
                        chartData.comparisonLabel.length
                      ) {
                        return chartData.comparisonLabel[context.parsed.x];
                      }
                      return context.label;
                    },
                  },
                },
              },
            }}
          />
        </Box>
        {renderTileFooter({
          diff: upload_difference,
          score: current_total_uploads_count,
        })}
      </Box>
    );
  };

  const renderCQScore = () => {
    if (dashboardContext.isCQScoreLoading) {
      return tileLoader();
    }
    if (dashboardContext.error['CQ_SCORE']) {
      return tileFailure('RI Score', dashboardContext.error['CQ_SCORE']);
    }
    const {
      grade,
      cq_score_diff,
      current_graph: graph,
      current_cq_score,
      previous_graph,
      unit_type,
      filter_dates,
    } = dashboardContext.cqScore;
    let chartData = { label: [], data: [] };
    let previousChartData = { label: [], data: [] };

    let legendDate = graph && Object.keys(graph);

    if (graph) {
      chartData = getChartData(
        filterParams.label,
        graph,
        unit_type,
        previous_graph,
        showComparison
      );
      previousChartData = getChartData(
        filterParams.label,
        previous_graph,
        unit_type,
        [],
        showComparison
      );
    }

    const previous_cq_score = dashboardContext.cqScore['previous_cq_score '];

    const startDateUTC = utcToZonedTime(
      new Date(filter_dates ? new Date(filter_dates['start_date']) : new Date())
    );

    const endDateUTC = utcToZonedTime(
      new Date(filter_dates ? new Date(filter_dates['end_date']) : new Date())
    );
    // 
    let chartDataProp = [
      {
        label: `${format(
          new Date(startDateUTC),
          'MMM dd, yyyy'
        )} - ${format(
          new Date(endDateUTC),
          'MMM dd, yyyy'
        )}`,
        data: chartData.data,
        borderColor: AppColors.primary,
        backgroundColor: AppColors.primary,
        tension: 0.4,
      },
    ];

    if (filterParams.label.toLowerCase() === 'custom' && showComparison) {
      chartDataProp = [
        {
          label: `${format(new Date(startDateUTC), 'MMM dd, yyyy')} - ${format(
            new Date(endDateUTC),
            'MMM dd, yyyy'
          )}`,
          data: chartData.data,
          borderColor: AppColors.primary,
          backgroundColor: AppColors.primary,
          tension: 0.4,
        },
        {
          label: `${format(
            new Date(filter_dates.previous_start_date),
            'MMM dd, yyyy'
          )} - ${format(
            new Date(filter_dates.previous_end_date),
            'MMM dd, yyyy'
          )}`,
          data: previousChartData?.data,
          borderColor: AppColors.secondary,
          backgroundColor: AppColors.secondary,
          tension: 0.4,
        },
      ];
    }
    return (
      <Box p={isTileModalOpen ? 0 : 5} bg={'white'} borderRadius="md">
        {renderTileHeader('RI Score')}
        <Box mt={4}>
          <CQLineChart
            chartData={chartDataProp}
            yLabel="Score"
            xLabel={XAXISLABEL[filterParams.label]}
            xAxis={chartData.label}
            maxTicksLimit={12}
            optionProps={{
              plugins: {
                legend: {
                  onClick: () => {},
                  display: true,
                  position: 'bottom',
                  align: 'end',
                  labels: {
                    boxWidth: 20,
                    boxHeight: 0.5,
                    color: 'black',
                    font: {
                      size: 10,
                      family: FONT_FAMILY.ptSans,
                    },
                  },
                },
                annotation: {
                  annotations: {
                    label1: {
                      type: 'label',
                      backgroundColor: 'transparent',
                      color: '#03317a',
                      content:
                        typeof previous_cq_score === 'number' && showComparison
                          ? [`${previous_cq_score}%`]
                          : [],
                      font: {
                        size: 25,
                        weight: 'bold',
                      },
                      yAdjust: -15,
                    },
                    label2: {
                      type: 'label',
                      backgroundColor: 'transparent',
                      color: '#22afa8',
                      content:
                        typeof current_cq_score === 'number'
                          ? [`${current_cq_score}%`]
                          : [],
                      font: {
                        size: 25,
                        weight: 'bold',
                      },
                      yAdjust:
                        typeof previous_cq_score === 'number' && showComparison
                          ? 15
                          : 0,
                    },
                  },
                },
                tooltip: {
                  callbacks: {
                    label: context => {
                      return Math.round(context.parsed.y);
                    },
                    title: data => {
                      const context = data[0];
                      if (
                        context.datasetIndex === 1 &&
                        chartData.comparisonLabel.length
                      ) {
                        return chartData.comparisonLabel[context.parsed.x];
                      }
                      return context.label;
                    },
                  },
                },
              },
            }}
          />
        </Box>
        {renderTileFooter({ diff: cq_score_diff, score: grade })}
      </Box>
    );
  };

  const renderRVUOpportunity = () => {
    if (dashboardContext.isRVUOppLoading || dashboardContext.isFileLoading) {
      return tileLoader();
    }
    if (dashboardContext.error['RVU_OPPORTUNITY']) {
      return tileFailure(
        'RVU OPPORTUNITY',
        dashboardContext.error['RVU_OPPORTUNITY']
      );
    }
    return (
      <Box p={isTileModalOpen ? 0 : 5} bg={'white'} borderRadius="md">
        {renderTileHeader('RVU OPPORTUNITY')}
        <Box mt={4}>
          <RVUOpportunity
            stateChartData={stateChartData}
            practiceChartData={practiceChartData}
            checked={checked}
            setChecked={setChecked}
            radioClick={radioClick}
            tile={isTileModalOpen}
            dropdown={dropdown}
          />
        </Box>
      </Box>
    );
  };

  const renderCQOutstanding = () => {
    if (dashboardContext.isRevenueOppLoading) {
      return tileLoader();
    }
    if (dashboardContext.error['OUTSTANDING_REVENUE']) {
      return tileFailure(
        'Outstanding Revenue Opportunity',
        dashboardContext.error['OUTSTANDING_REVENUE']
      );
    }
    return (
      <Box p={5} bg={'white'} borderRadius="md">
        {renderTileHeader('Outstanding Revenue Opportunity')}
        <RevenueTile
          diff={revenueOpp?.difference}
          dollarValue={
            revenueOpp?.difference
              ? priceConverter(revenueOpp?.difference)
              : '$0'
          }
          description="This is the amount of revenue possibly missing out on reimbursement."
          label={
            revenueOpp?.cq_outstanding_rev_opp
              ? priceConverter(revenueOpp?.cq_outstanding_rev_opp)
              : '$0'
          }
          filterParams={filterParams}
        />
      </Box>
    );
  };

  const renderCQEstimatedRevenue = () => {
    if (dashboardContext.isCQEstimatedRevLoading) {
      return tileLoader();
    }

    if (dashboardContext.error['CQ_ESTIMATED_REVENUE']) {
      return tileFailure(
        '% Revenue Opportunity',
        dashboardContext.error['CQ_ESTIMATED_REVENUE']
      );
    }

    const { cqEstimatedRev } = dashboardContext;
    return (
      <Box p={5} bg={'white'} borderRadius="md">
        {renderTileHeader('% Revenue Opportunity')}
        <RevenueTile
          diff={cqEstimatedRev?.difference}
          dollarValue={cqEstimatedRev?.difference}
          description="This is the percent of revenue possibly missing out on reimbursement."
          label={
            cqEstimatedRev?.cq_estimated_revenue
              ? `${cqEstimatedRev?.cq_estimated_revenue}%`
              : '0%'
          }
          filterParams={filterParams}
        />
      </Box>
    );
  };

  const renderCQAIRiskMonitor = () => {
    if (dashboardContext.isAIRiskLoading) {
      return tileLoader();
    }

    if (dashboardContext.error['AI_RISK']) {
      return tileFailure(
        'Revenue Cycle Temperature Gauge',
        dashboardContext.error['AI_RISK']
      );
    }

    return (
      <Box p={isTileModalOpen ? 0 : 5} bg={'white'} borderRadius="md">
        {renderTileHeader('Revenue Cycle Temperature Gauge')}
        <Box mt={4}>
          <AIRiskMonitor
            data={dashboardContext.aiRisk}
            tile={isTileModalOpen}
          />
        </Box>
      </Box>
    );
  };

  const renderChartActivity = () => {
    const LABEL = {
      modifier_percentage: 'Modifier',
      rvu_percentage: 'RVU Opportunities',
      upcoded_percentage: 'Upcoded',
      downcoded_percentage: 'Downcoded',
    };

    return (
      <Box p={5} bg={'white'} borderRadius="md">
        {renderTileHeader('Uploaded Chart Activity')}
        <Grid
          templateColumns={
            !isUserProvider ? 'repeat(2, 1fr)' : 'repeat(4, 1fr)'
          }
          gap={2}
          justifyContent="center"
          mt={5}
        >
          {chartActivity && Object.keys(chartActivity)?.length
            ? Object.keys(LABEL).map(chart => {
                return (
                  <GridItem>
                    <Box display="flex" justifyContent={'center'}>
                      <CircularProgress
                        value={chartActivity[chart] ? chartActivity[chart] : 0}
                        size="150px"
                        thickness="3px"
                        color={AppColors.primary}
                      >
                        <CircularProgressLabel>
                          <Heading
                            variant="h1"
                            fontFamily={FONT_FAMILY.ptSans}
                            fontWeight="md"
                          >
                            {chartActivity[chart] ? chartActivity[chart] : 0}%
                          </Heading>
                        </CircularProgressLabel>
                      </CircularProgress>
                    </Box>
                    <Text
                      textAlign={'center'}
                      fontFamily={FONT_FAMILY.ptSans}
                      fontSize="md"
                    >
                      {LABEL[chart]}
                    </Text>
                  </GridItem>
                );
              })
            : null}
        </Grid>
      </Box>
    );
  };

  const renderChartActivityTile = () => {
    if (dashboardContext.isChartActivityLoading) {
      return tileLoader();
    } else {
      return renderChartActivity();
    }
  };

  const renderProviderTable = () => {
    return (
      <Box p={5} ref={providerRef}>
        <HStack justifyContent={'space-between'} mb={2}>
          <Text
            fontSize={'lg'}
            color={AppColors.primary}
            fontFamily={FONT_FAMILY.ptSans}
          >
            Providers
            {/* <span style={{ color: 'black' }}>
              (
              {filterParams.label === 'Day'
                ? dateFormat(filterParams.start_date)
                : `${dateFormat(filterParams.start_date)} - ${dateFormat(
                  filterParams.end_date
                )}`}
              )
            </span> */}
          </Text>
        </HStack>
        <ProvidersTable />
      </Box>
    );
  };

  const renderProviderChartAnalytics = () => {
    return (
      <Box p={5} mt={5} bg={'white'} borderRadius="md">
        {renderTileHeader('Provider Chart Analytics')}
        <HStack
          width={'100%'}
          justifyContent={'space-around'}
          my={4}
          w={'100%'}
        >
          <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.black}>
            My Analytics
          </Text>
          <PrimaryButton
            bg={AppColors.secondary}
            fontSize={'14px'}
            fontWeight="normal"
            size="md"
            borderRadius="3xl"
            px="10"
            onClick={() => {
              const route = replaceRoute(
                ROUTES.DEPARTMENT_PHYSICIAN_DASHBOARD,
                { physicianId: providers[0]?.provider_id }
              );
              navigate(route);
            }}
          >
            View
          </PrimaryButton>
        </HStack>
      </Box>
    );
  };

  const renderUploadTable = () => {
    return (
      <Box mt={5} p={5} bg={'white'} borderRadius="md">
        {renderTileHeader('Upload Breakdown ')}
        <UploadTable filterParams={filterParams} />
      </Box>
    );
  };

  const renderHospital = () => {
    switch (currentUser?.user_type) {
      case 'HEALTH SYSTEM':
        return (
          <Box mt={5}>
            <HospitalsTable />
          </Box>
        );
      case 'HOSPITAL':
        return <DepartmentTable />;
      case 'DEPARTMENT':
        return <PhysicianTable />;
      case 'PHYSICIANS GROUP':
        return (
          <Box mt={5}>
            <HospitalsTable />
          </Box>
        );
      default:
        return null;
    }
  };

  const TILE_MODAL_BODY = {
    TOTAL_UPLOAD: renderTotalUploads,
    CQ_SCORE: renderCQScore,
    RVU_OPPORTUNITY: renderRVUOpportunity,
    AI_RISK: renderCQAIRiskMonitor,
  };
  return (
    <CQContainer>
      <Box>
        <Box width={'100%'}>
          <HStack justifyContent={'space-between'} alignItems="center" mb={5}>
            <Heading
              variant={'h5'}
              fontSize="18px"
            >{`${name} Analytics`}</Heading>
            {/* {renderFilters()} */}
            <CustomDateFilter
              filterParams={filterParams}
              openDateFilter={() => iodfState.on()}
              isOpenDateFilter={isOpenDateFilter}
              onDateClose={onDateClose}
              onDateSubmit={onDateSubmit}
              isLoading={isLoading}
              onSelectClick={filter => {
                if (filter.value !== 'custom') {
                  const data = getDateInterval(filter.label);
                  dashboardContext.handleApplyFilter({
                    ...filterParams,
                    label: filter.label,
                    ...data,
                  });
                  localStorage.setItem(
                    'selectedFilter',
                    JSON.stringify(filter.label.toLowerCase())
                  );
                  window.localStorage.removeItem('customDateFilter');
                  iodfState.off();
                  setShowComparison(false);
                }
              }}
            />
          </HStack>
          <HStack justifyContent={'end'}>
            <Switch
              icon={<CustomIcon />}
              mb={5}
              mr={1}
              id="show-comparison"
              isChecked={showComparison}
              onChange={async e => {
                setShowComparison(e.target.checked);
              }}
              border={'black'}
              colorScheme="secondary"
              isDisabled={filterParams.label !== 'custom'}
            >
              Show Comparison Data
            </Switch>
          </HStack>
          <Grid
            templateColumns={{
              sm: 'repeat(1, 1fr)',
              md: 'repeat(2, 1fr)',
              lg: 'repeat(2, 1fr)',
              '2lg': 'repeat(2, 1fr)',
              xl: 'repeat(3, 1fr)',
              '2xl': 'repeat(3, 1fr)',
            }}
            gap={4}
            rowGap={4}
          >
            <GridItem
              borderRadius="md"
              bg={'white'}
              width={'99%'}
              cursor={'pointer'}
              onClick={() => {
                setCurrentTile('AI_RISK');
                itmState.on();
              }}
            >
              {renderCQAIRiskMonitor()}
            </GridItem>
            <GridItem
              borderRadius="md"
              bg={'white'}
              width={'99%'}
              cursor={'pointer'}
              onClick={() => {
                setCurrentTile('CQ_SCORE');
                itmState.on();
              }}
            >
              {renderCQScore()}
            </GridItem>
            <GridItem
              borderRadius="md"
              bg={'white'}
              width={'99%'}
              cursor={'pointer'}
              onClick={e => {
                setCurrentTile('RVU_OPPORTUNITY');
                itmState.on();
              }}
            >
              {renderRVUOpportunity()}
            </GridItem>
            <GridItem borderRadius="md" bg={'white'} width={'99%'}>
              {renderCQOutstanding()}
            </GridItem>
            <GridItem borderRadius="md" bg={'white'} width={'99%'}>
              {renderCQEstimatedRevenue()}
            </GridItem>
            <GridItem
              borderRadius="md"
              width={'99%'}
              bg={'white'}
              cursor={'pointer'}
              onClick={e => {
                setCurrentTile('TOTAL_UPLOAD');
                itmState.on();
              }}
            >
              {renderTotalUploads()}
            </GridItem>
          </Grid>
          {!isUserProvider ? (
            <Grid
              templateColumns={{
                sm: 'repeat(1, 1fr)',
                md: 'repeat(1, 1fr)',
                lg: 'repeat(2, 1fr)',
              }}
              gap={2}
              mt={5}
            >
              <GridItem borderRadius="md" bg={'white'} width={'99%'}>
                {/* {renderChartActivity()} */}
                {renderChartActivityTile()}
              </GridItem>
              <GridItem
                borderRadius="md"
                bg={'white'}
                width={'99%'}
                boxShadow={table ? '1px 2px 9px #33d7d0' : 'none'}
              >
                {renderProviderTable()}
              </GridItem>
            </Grid>
          ) : (
            <Box mt={5}>
              {renderChartActivityTile()}
              {renderProviderChartAnalytics()}
            </Box>
          )}
          {renderUploadTable()}
          {renderHospital()}
        </Box>
        <TilePopup
          renderBody={currentTile ? TILE_MODAL_BODY[currentTile] : () => null}
          isOpen={isTileModalOpen}
          onClose={() => {
            itmState.off();
          }}
        />
      </Box>
    </CQContainer>
  );
};

export default withProvider(DashboardProvider, DashboardContainer);
