/* eslint-disable eqeqeq */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from 'react';
import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import { FormTextInput } from '@prasanna-transcend/code-quick-components';
import { useContext } from 'react';
import {
  MailIcon,
  PersonIcon,
  FilledPlusIcon,
  LocationIcon,
  HealthSystemShortIcon,
} from '../../../../../constants/IconData';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { REGISTER } from './validation';
import NavigationFooter from '../common_files/NavigationFooter';
import AppColors from '../../../../../constants/AppColors';
import { FONT_FAMILY } from '../../../../../constants/Theme';
import { components } from 'react-select';
import AsyncCreatableSelect from 'react-select/async-creatable';
import { AuthContext } from '../../../../../providers/AuthProvider';
import { REGISTER_ERROR_PAGE, STATES } from '../../../../../constants/constants';
import StateDropDown from '../../../../dashboard/practice_info/forms/StateDropDown';

const SignupForm = ({ onPreviousClick, onNextClick }) => {
  const authContext = useContext(AuthContext);
  const {
    onboardingObject,
    setOnboardingObject,
    healthSystemDropdown,
    setHealthSystemDropdown,
    registerError,
    currentPage,
    registerBody,
  } = authContext;

  const [dataValue, setDataValue] = useState({});
  const [newHealthSystem, setNewHealthSystem] = useState(false);

  const form = useForm({
    resolver: yupResolver(REGISTER),
    mode:"onChange"
  });
  const { control, handleSubmit, setValue, reset, setError, clearErrors,formState:{errors},getValues , } =
    form;

  const clearErrorData = (key, data) => {
    if (registerBody[key] && data !== registerBody[key]) {
      clearErrors(key);
    } else if (data === registerBody[key] && registerError[key]) {
      setError(key, { type: 'custom', message: registerError[key] });
    }
  };

  const formatCreateLabel = inputValue => {
    return (
      <Box>
        <Text
          fontSize={'small'}
          color={AppColors.gray}
        >{` No results matching " ${inputValue} "`}</Text>
        <HStack bg={'rgba(67,218,212,0.07)'} p={1} fontSize={'xs'}>
          <FilledPlusIcon style={{ height: 15, width: 15 }} />
          <span color={AppColors.secondary}>
            {' '}
            Add <span style={{ fontWeight: 'bolder' }}>{inputValue}</span> to
            RevIntegrity{' '}
          </span>
        </HStack>
      </Box>
    );
  };

  const formatOptionLabel = props => {
    return (
      <Box>
        <Text fontFamily={FONT_FAMILY.ptSans} fontSize="sm">
          {props.label}
          <span
            style={{ fontSize: 10, color: AppColors.darkGray, marginLeft: 5 }}
          >
            {props.address}
          </span>
        </Text>
      </Box>
    );
  };

  const handleHelthSystemCreate = inputValue => {
    setNewHealthSystem(true);
    setHealthSystemDropdown(prev => [
      ...prev,
      { label: inputValue, value: inputValue, address: '' },
    ]);
    setDataValue({ label: inputValue, value: inputValue, address: '' });
    setOnboardingObject(prev => ({ ...prev, health_system_name: inputValue }));
    setValue('health_system_name', inputValue);
    setValue('new', true);
  };

  const onSubmitData = data => {
    setOnboardingObject({ ...onboardingObject, ...data });
    onNextClick();
  };

  useEffect(() => {
    authContext.getHealthSystem();
    if (onboardingObject && Object.keys(onboardingObject)?.length) {
      if (onboardingObject.new) {
        setNewHealthSystem(true);
        if (onboardingObject.health_system_name) {
          setDataValue({
            label: onboardingObject.health_system_name,
            value: onboardingObject.health_system_name,
            back: true,
          });
        }
        reset({
          first_name: onboardingObject.first_name,
          last_name: onboardingObject.last_name,
          email: onboardingObject.email,
          health_system_name: onboardingObject.health_system_name,
          state: onboardingObject.state,
          new: true,
        });
      } else {
        setNewHealthSystem(false);
        if (onboardingObject.health_system_name) {
          setDataValue({
            label: onboardingObject.health_system_name,
            value: onboardingObject.health_system_id,
            back: true,
          });
        }
        reset({
          first_name: onboardingObject.first_name,
          last_name: onboardingObject.last_name,
          email: onboardingObject.email,
          health_system_name: onboardingObject.health_system_name,
          health_system_id: onboardingObject.health_system_id,
          new: false,
        });
      }
    }
    if (registerError && Object.keys(registerError)?.length) {
      const registerErrorKeys = Object.keys(registerError);
      REGISTER_ERROR_PAGE[currentPage].forEach(key => {
        if (
          registerErrorKeys.includes(key) &&
          onboardingObject[key] === registerBody[key]
        ) {
          if (key === 'health_system') {
            setError('health_system_name', {
              type: 'custom',
              message: registerError[key],
            });
          } else {
            setError(key, { type: 'custom', message: registerError[key] });
          }
        }
      });
    }
  }, []);

  useEffect(() => {
    if (
      healthSystemDropdown?.length &&
      onboardingObject.primaryEmailOnboarding
    ) {
      const data = healthSystemDropdown.find(
        d => d.value == onboardingObject.health_system_id
      );
      setDataValue({
        label: data.label,
        value: onboardingObject.health_system_id,
      });
      setValue('health_system_name', data.label);
      setOnboardingObject(prev => ({
        ...prev,
        health_system_name: data.label,
      }));
    }
  }, [healthSystemDropdown]);


  const filterOptions = inputValue => {
    return healthSystemDropdown.filter(healthSystem => {
      const healthSystemName = healthSystem.label?.toLowerCase();
      const searchedValue = inputValue.toLowerCase();
      return healthSystemName.startsWith(searchedValue);
    });
  };

  const promiseOptions = inputValue => {
    if (inputValue.length >= 5) {
      return new Promise(resolve => {
        // setTimeout(() => {
        resolve(filterOptions(inputValue));
        // }, 100);
      });
    }
  };

  return (
    <>
      <form onSubmit={handleSubmit(onSubmitData)}>
        <HStack spacing={7} display={'flex'} alignItems="baseline">
          <FormTextInput
            name="first_name"
            id="first_name"
            placeholder="First Name"
            style={{ opacity: '1' }}
            leftIcon={<PersonIcon />}
            leftIconProps={{ height: '50px' }}
            height="50px"
            size="md"
            borderRadius="lg"
            control={control}
          />
          <FormTextInput
            name="last_name"
            id="last_name"
            placeholder="Last Name"
            style={{ opacity: '1' }}
            leftIcon={<PersonIcon />}
            leftIconProps={{ height: '50px' }}
            height="50px"
            size="md"
            borderRadius="lg"
            control={control}
          />
        </HStack>
        <HStack mt={5}>
          <FormTextInput
            name="email"
            id="email"
            placeholder="name@gmail.com"
            style={{ opacity: '1' }}
            leftIcon={<MailIcon />}
            leftIconProps={{ height: '50px' }}
            height="50px"
            size="md"
            borderRadius="lg"
            control={control}
            disabled={onboardingObject.primaryEmailOnboarding}
          />
        </HStack>
        <VStack mt={5}>
          <Box w="100%" mb="3">
            <AsyncCreatableSelect
              name="health_system_name"
              id="health_system_name"
              control={control}
              formatCreateLabel={formatCreateLabel}
              isDisabled={onboardingObject.primaryEmailOnboarding}
              onChange={newValue => {
                setDataValue(newValue);
                setValue('health_system_name', newValue.label);
                if (!newValue.id) {
                  setNewHealthSystem(true);
                  setValue('new', true);
                } else {
                  setValue('health_system_id', newValue.value);
                  setNewHealthSystem(false);
                  setValue('new', false);
                }
                clearErrorData('health_system_name', newValue.label);
              }}
              isSearchable={true}
              placeholder="Health System Name ( Enter minimum 5 characters to search )"
              // options={healthSystemDropdown}
              defaultOptions={[]}
              loadOptions={promiseOptions}
              value={
                dataValue && Object.keys(dataValue)?.length === 0
                  ? ''
                  : dataValue
              }
              styles={{
                noOptionsMessage: (provided, state) => ({
                  ...provided,
                  backgroundColor: 'white',
                }),
                menu: props => ({
                  ...props,
                  zIndex: 10,
                }),
              }}
              formatOptionLabel={formatOptionLabel}
              components={{
                Control: ({ children, ...props }) => {
                  return (
                    <components.Control {...props}>
                      <span style={{ marginLeft: 10 }}>
                        <HealthSystemShortIcon />
                      </span>
                      {children}
                    </components.Control>
                  );
                },
              }}
              onCreateOption={handleHelthSystemCreate}
            />
          </Box>
          {newHealthSystem && (
            <Box mt={5} w={'100%'}>
              <StateDropDown
                value={STATES.find(state => state.value === getValues('state'))}
                name="state"
                width={'100%'}
                id="state"
                onChange={e => {
                  setValue('state', e.value);
                  clearErrors('state');
                }}
                control={control}
                errors={errors}
              />
              {/* <FormTextInput
                name="state"
                control={control}
                id="state"
                placeholder="Select State "
                style={{ opacity: '1' }}
                size="md"
                leftIcon={<LocationIcon />}
                leftIconProps={{ height: '50px' }}
                height="50px"
                onChange={e => {
                  setValue('state', e.target.value);
                }}
              /> */}
            </Box>
          )}
        </VStack>
        <Box mt={14} />
        <NavigationFooter
          onPreviousClick={onPreviousClick}
          currentPageTitle={onboardingObject.userType}
          pageTitle={'CreateAccount'}
        />
      </form>
    </>
  );
};
export default SignupForm;
