import {
  useBoolean,
  usePagination,
  useToastr,
} from '@prasanna-transcend/code-quick-components';
import { add, format } from 'date-fns';
import { createContext, useState } from 'react';
import { SELF } from '../constants/constants';
import { getDateInterval } from '../pages/dashboard/getDateInterval';
import dashboardRepository from '../repositories/DashboardRepository';
import departmentDashboardRepository from '../repositories/DepartmentRepository';
import hospitalDashboardRepository from '../repositories/HospitalRepository';
import myChartRepository from '../repositories/MyChartRepository';
import { useLocation, useParams } from 'react-router-dom';

export const DashboardContext = createContext({
  getProviders: async () => {},
  getHospitals: async () => {},
  getUploads: async () => {},

  getDepartments: async () => {},
  getPhysicians: async () => {},

  providers: [],
  hospitals: [],
  uploads: [],

  filterParams: {},
  handleApplyFilter: () => {},

  isProviderLoading: false,
  isHospitalLoading: false,
  isUploadsLoading: false,
  isChartActivityLoading: false,
  isRevenueOppLoading: false,
  hospitalFilter: {},
  setHospitalFilter: () => {},
  providerFilter: {},
  setProviderFilter: () => {},

  isTotalUploadLoading: false,
  totalUpload: {},
  getTotalUploads: async () => {},
  isCQScoreLoading: false,
  cqScore: {},
  getCQScore: async () => {},
  isRVUOppLoading: false,
  rvuOpp: {},
  getRVUOpp: async () => {},
  getChartActivity: async () => {},
  getRevenueOpp: async () => {},
  getCQEstimatedRev: async () => {},
  cqEstimatedRev: {},
  isCQEstimatedRevLoading: false,
  charts: [],
  chartFilters: {},
  setChartFilters: () => {},
  getCharts: async () => {},
  getFileObjectByBatchId: async () => {},
  isFileLoading: false,
  getAIRisk: () => {},
  aiRisk: {},
  isAIRiskLoading: false,
  getRVUOppDropdown: async () => {},
  dropdown: [],
  setRVUOppFilter: () => {},
  rvuOppFilter: {},
  error: {},
  showComparison: false,
  setShowComparison: () => {},
  getFilterParams: () => {},

  //audit results
  getAuditResults: async paginationParams => {},
  auditResults: [],
  isFetchingAuditResults: false,
  setUserType: () => {},
  userTypes: {},

  chartCurrentPage:null,
  setChartCurrentpage:()=>{},
});

const yesterday = add(new Date(), {
  days: -1,
});
const selectedFilter = JSON.parse(localStorage.getItem('selectedFilter'));
let initialFilterParams = {};
if (selectedFilter) {
  if (selectedFilter.toLowerCase() === 'custom') {
    const selectedCustomDates = JSON.parse(
      window.localStorage.getItem('customDateFilter')
    );
    initialFilterParams = {
      label: 'custom',
      start_date: selectedCustomDates?.start_date
        ? new Date(selectedCustomDates?.start_date)
        : new Date(),
      end_date: selectedCustomDates?.end_date
        ? new Date(selectedCustomDates?.end_date)
        : new Date(),
      previous_start_date: selectedCustomDates?.previous_start_date
        ? new Date(selectedCustomDates?.previous_start_date)
        : new Date(),
      previous_end_date: selectedCustomDates?.previous_end_date
        ? new Date(selectedCustomDates?.previous_end_date)
        : new Date(),
    };
  } else {
    const data = getDateInterval(selectedFilter);
    initialFilterParams = {
      label: selectedFilter,
      ...data,
    };
  }
} else {
  initialFilterParams = {
    label: 'month',
    start_date: add(yesterday, {
      days: -30,
    }),
    end_date: yesterday,
    previous_start_date: add(yesterday, {
      days: -60,
    }),
    previous_end_date: add(yesterday, {
      days: -30,
    }),
    show_comparison: false,
  };
}
const DashboardProvider = props => {
  const toast = useToastr();
  const [providers, setProviders] = useState([
    {
      provider_name: 'Dr.James mary',
      cq_score: 0,
      cq_score_diff: 0.0,
      archived_date: '2022-08-13T07:51:28Z',
    },
  ]);
  const [hospitals, setHospitals] = useState([]);
  const [uploads, setUploads] = useState([]);
  const [isProviderLoading, iplState] = useBoolean(false);
  const [isHospitalLoading, ihlState] = useBoolean(false);
  const [isUploadsLoading, iulState] = useBoolean(false);
  const [isChartActivityLoading, icalState] = useBoolean(false);
  const [isRevenueOppLoading, irolState] = useBoolean(false);

  const [isTotalUploadLoading, itulState] = useBoolean(false);
  const [isCQScoreLoading, icqsState] = useBoolean(false);
  const [isRVUOppLoading, irvuoState] = useBoolean(false);
  const [isCQEstimatedRevLoading, icerState] = useBoolean(false);

  const [totalUpload, setTotalUpload] = useState({});
  const [cqScore, setCqScore] = useState({});
  const [rvuOpp, setRvuOpp] = useState({});
  const [cqEstimatedRev, setCQEstimatedRev] = useState({});
  const [isAIRiskLoading, iarlState] = useBoolean(false);
  const [error, setError] = useState({
    TOTAL_UPLOADS: '',
    CQ_SCORE: '',
    RVU_OPPORTUNITY: '',
    AI_RISK: '',
  });

  const [aiRisk, setAIRisk] = useState({});
  const [charts, setCharts] = useState([]);
  const [chartFilters, setChartFilters] = useState({ search: '' });
  const [filterParams, setFilterParams] = useState(
    Object.assign({}, initialFilterParams)
  );
  const [providerFilter, setProviderFilter] = useState({ search: '' });
  const [hospitalFilter, setHospitalFilter] = useState({ search: '' });

  const [userTypes, setUserType] = useState({});

  const [auditResults, setAuditResults] = useState([]);
  const [isFetchingAuditResults, ifarState] = useBoolean(false);

  const [isFileLoading, iflState] = useBoolean(false);
  const [dropdown, setDropdown] = useState([]);
  const [rvuOppFilter, setRVUOppFilter] = useState({
    rvu_opportunity_type: '',
  });
  const [showComparison, setShowComparison] = useState(false);
  const [chartCurrentPage,setChartCurrentpage]=useState(null);



  const getFilterParams = () => {
    const queryParams = {};
    const { start_date, end_date, label } = filterParams;
    if (label) queryParams['filter'] = label.toLowerCase();
    if (filterParams.health_system_id)
      queryParams['health_system_id'] = filterParams.health_system_id;
    if (filterParams.hospital_id)
      queryParams['hospital_id'] = filterParams.hospital_id;
    if (filterParams.department_id)
      queryParams['department_id'] = filterParams.department_id;
    if (filterParams.provider_id)
      queryParams['provider_id'] = filterParams.provider_id;

    if (filterParams.label.toLowerCase() === 'custom') {
      queryParams['start_date'] = format(start_date, 'yyyy-MM-dd');
      queryParams['end_date'] = format(end_date, 'yyyy-MM-dd');
      if (filterParams.previous_start_date) {
        queryParams['previous_start_date'] = format(
          filterParams.previous_start_date,
          'yyyy-MM-dd'
        );
        queryParams['previous_end_date'] = format(
          filterParams.previous_end_date,
          'yyyy-MM-dd'
        );
      }

      delete queryParams['filter'];
    }
    return queryParams;
  };

  const handleApplyFilter = selectedFilters => {
    if (selectedFilters?.label?.toLowerCase() !== 'custom') {
      setShowComparison(false);
    }
    setFilterParams(selectedFilters);
  };

  const getProviders = async () => {
    try {
      const filter = getFilterParams();
      iplState.on();
      const response = await dashboardRepository.getProviders({
        ...filter,
        ...providerFilter,
      });
      setProviders(response);
      return response;
    } catch (err) {
      setProviders([]);
    } finally {
      iplState.off();
    }
  };

  const getUploads = async paginationParams => {
    try {
      const filter = getFilterParams();

      iulState.on();
      const response = await dashboardRepository.getChartUploads({
        ...filter,
        ...paginationParams,
      });
      setUploads(response.results);
      return response;
    } catch (err) {
      setUploads([]);
    } finally {
      iulState.off();
    }
  };
  const getHospitals = async paginationParams => {
    try {
      ihlState.on();
      // const filter = getFilterParams();
      const response = await dashboardRepository.getHospitals({
        // ...filter,
        ...hospitalFilter,
        ...paginationParams,
      });
      if (response.results) {
        setHospitals(response.results);
      }
      return response;
    } catch (err) {
      toast.showError({
        description: `Something went wrong.`,
      });
      setHospitals([]);
    } finally {
      ihlState.off();
    }
  };

  const getDepartments = async paginationParams => {
    try {
      ihlState.on();
      // const filter = getFilterParams();
      const response = await hospitalDashboardRepository.getHospitalDepartments(
        {
          // ...filter,
          ...hospitalFilter,
          ...paginationParams,
        }
      );
      setHospitals(response);
      return response;
    } catch (err) {
      toast.showError({
        description: `Something went wrong.`,
      });
      setHospitals([]);
    } finally {
      ihlState.off();
    }
  };
  const getPhysicians = async paginationParams => {
    try {
      ihlState.on();
      // const filter = getFilterParams();
      const response = await departmentDashboardRepository.getPhysicians({
        // ...filter,
        ...hospitalFilter,
        ...paginationParams,
      });
      setHospitals(response);
      if (response.results) {
        setHospitals(response.results);
      }
      return response;
    } catch (err) {
      toast.showError({
        description: `Something went wrong.`,
      });
      setHospitals([]);
    } finally {
      ihlState.off();
    }
  };
  const getChartActivity = async queryParams => {
    try {
      const filter = getFilterParams();
      icalState.on();
      const response = await dashboardRepository.getChartActivity({
        ...filter,
        ...queryParams,
      });
      return response;
    } catch (err) {
    } finally {
      icalState.off();
    }
  };

  const getRevenueOpp = async () => {
    try {
      const filter = getFilterParams();
      irolState.on();
      const response = await dashboardRepository.getOutstandingRevenue(filter);
      setError(prev => ({ ...prev, OUTSTANDING_REVENUE: null }));
      return response;
    } catch (err) {
      const response = err.cause;
      if (response.message) {
        setError(prev => ({ ...prev, OUTSTANDING_REVENUE: response.message }));
      }
    } finally {
      irolState.off();
    }
  };

  const getTotalUploads = async () => {
    const filter = getFilterParams();
    try {
      itulState.on();
      const response = await dashboardRepository.getTotalUploads(filter);
      setError(prev => ({ ...prev, TOTAL_UPLOADS: null }));
      setTotalUpload(response);
    } catch (err) {
      const response = err.cause;
      if (response.message) {
        setError(prev => ({ ...prev, TOTAL_UPLOADS: response.message }));
      }
      setTotalUpload({});
    } finally {
      itulState.off();
    }
  };
  const getCQScore = async () => {
    const filter = getFilterParams();
    try {
      icqsState.on();
      const response = await dashboardRepository.getCQScore(filter);
      setError(prev => ({ ...prev, CQ_SCORE: null }));
      setCqScore(response);
    } catch (err) {
      const response = err.cause;
      if (response.message) {
        setError(prev => ({ ...prev, CQ_SCORE: response.message }));
      }
      setCqScore({});
    } finally {
      icqsState.off();
    }
  };
  const getRVUOpp = async () => {
    const filter = getFilterParams();
    try {
      irvuoState.on();
      const response = await dashboardRepository.getRVUOpp({
        ...filter,
        ...rvuOppFilter,
      });
      setError(prev => ({ ...prev, RVU_OPPORTUNITY: null }));
      setRvuOpp(response);
    } catch (err) {
      const response = err.cause;
      if (response.message) {
        setError(prev => ({ ...prev, RVU_OPPORTUNITY: response.message }));
      }
      setRvuOpp({});
    } finally {
      irvuoState.off();
    }
  };
  const getCQEstimatedRev = async () => {
    const filter = getFilterParams();
    try {
      icerState.on();
      const response = await dashboardRepository.getCQEstimatedRev(filter);
      setError(prev => ({ ...prev, CQ_ESTIMATED_REVENUE: null }));
      setCQEstimatedRev(response);
    } catch (err) {
      const response = err.cause;
      if (response.message) {
        setError(prev => ({ ...prev, CQ_ESTIMATED_REVENUE: response.message }));
      }
      setCQEstimatedRev({});
    } finally {
      icerState.off();
    }
  };

  const getCharts = async paginationParams => {
    try {
      chartFilters['user'] = SELF;
      chartFilters['status'] = ['RECEIVED', 'PROCESSING'];
      const filter = getFilterParams();
      ihlState.on();
      const response = await myChartRepository.getCharts({
        ...paginationParams,
        ...chartFilters,
        ...filter,
      });
      setCharts(response.results);
      return response;
    } catch (err) {
      toast.showError({
        description: `Something went wrong.`,
      });
      setCharts([]);
    } finally {
      ihlState.off();
    }
  };

  
  const getAuditResults = async paginationParams => {
    try {
      const filter = getFilterParams();
      delete filter?.start_date;
      delete filter?.previous_end_date;
      delete filter?.previous_start_date;
      delete filter?.end_date;
      ifarState.on();

      if (chartCurrentPage) {
        paginationParams.page = chartCurrentPage;
      }


      const response = await myChartRepository.getAuditResults({
        ...paginationParams,
        ...chartFilters,
        filter: 'year',
        ...filter,
        ...userTypes,
      });

      setAuditResults(response.results);

      return response;
    } catch (err) {
      // toast.showError({
      //   description: `Something went wrong.`,
      // });
      setAuditResults([]);
    } finally {
      ifarState.off();
    }
  };

  const getFileObjectByBatchId = async batchId => {
    try {
      iflState.on();
      const response = await dashboardRepository.getFileObjectByBatchId(
        batchId
      );
      return response;
    } catch (err) {
      toast.showError({
        description: `Something went wrong.`,
      });
    } finally {
      iflState.off();
    }
  };

  const getAIRisk = async () => {
    try {
      iarlState.on();
      const filter = getFilterParams();
      const response = await dashboardRepository.getAIRisk(filter);
      let label = '';
      if (filterParams.health_system_id) label = 'Hospitals';
      if (filterParams.hospital_id) label = 'Departments';
      if (filterParams.department_id) label = 'Physicians';
      // if (filterParams.provider_id) label = ''
      setAIRisk({ ...response, label });
      setError(prev => ({ ...prev, AI_RISK: null }));
    } catch (err) {
      const response = err.cause;
      if (response.message) {
        setError(prev => ({ ...prev, AI_RISK: response.message }));
      }
      setCharts({});
    } finally {
      iarlState.off();
    }
  };

  const getRVUOppDropdown = async queryParams => {
    try {
      iflState.on();
      irvuoState.on();
      const filter = getFilterParams();
      const response = await dashboardRepository.getRVUOppDropdown({
        ...queryParams,
        ...filter,
      });
      let temp = [];
      if (Object.keys(response).length) {
        Object.keys(response).forEach(r => {
          temp.push({
            label: response[r],
            value: r,
          });
        });
      } else {
        setRvuOpp({});
        irvuoState.off();
      }
      setDropdown(temp);
    } catch (err) {
      toast.showError({
        description: `Something went wrong.`,
      });
      setDropdown([]);
    } finally {
      iflState.off();
      irvuoState.off();
    }
  };

  const getHospitals1 = usePagination(getHospitals);
  const getDepartments1 = usePagination(getDepartments);
  const getPhysicians1 = usePagination(getPhysicians);
  const getUploads1 = usePagination(getUploads);
  const getCharts1 = usePagination(getCharts);
  const getAuditCharts1 = usePagination(getAuditResults);

  const mContext = {
    getProviders,
    getHospitals: getHospitals1,
    getDepartments: getDepartments1,
    getPhysicians: getPhysicians1,
    getUploads: getUploads1,
    providers,
    hospitals,
    uploads,
    filterParams,
    handleApplyFilter,

    isProviderLoading,
    isHospitalLoading,
    isUploadsLoading,
    isChartActivityLoading,
    isRevenueOppLoading,
    hospitalFilter,
    setHospitalFilter,
    providerFilter,
    setProviderFilter,
    getChartActivity,
    getRevenueOpp,

    isTotalUploadLoading,
    totalUpload,
    getTotalUploads,
    isCQScoreLoading,
    cqScore,
    getCQScore,
    isRVUOppLoading,
    rvuOpp,
    getRVUOpp,
    charts,
    chartFilters,
    setChartFilters,
    getCharts: getCharts1,
    getFileObjectByBatchId,
    isFileLoading,
    getCQEstimatedRev,
    cqEstimatedRev,
    isCQEstimatedRevLoading,
    getAIRisk,
    aiRisk,
    isAIRiskLoading,
    getRVUOppDropdown,
    dropdown,
    setRVUOppFilter,
    rvuOppFilter,
    error,
    showComparison,
    setShowComparison,
    getFilterParams,

    //audit results

    getAuditCharts:getAuditCharts1,
    auditResults,
    isFetchingAuditResults,
    setUserType,

    userTypes,

    chartCurrentPage,
    setChartCurrentpage,
  };

  return (
    <DashboardContext.Provider value={mContext}>
      {props.children}
    </DashboardContext.Provider>
  );
};
export default DashboardProvider;
