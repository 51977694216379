import {
  useBoolean,
  useToastr,
} from '@prasanna-transcend/code-quick-components';
import { createContext, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { CLIENT } from '../constants/constants';
import { ACCESS_TOKEN } from '../constants/PreferenceKeys';
import ROUTES, { PUBLIC_PATH } from '../constants/Routes';
import authRepository from '../repositories/AuthRepository';

export const AUTH_FORMS = {
  LOGIN: 'login',
  FORGOT_PASSWORD: 'forgot_password',
  RESET_PASSWORD: 'reset_password',
};

export const AuthContext = createContext({
  isAuthenticated: false,
  isDetermined: false,
  currentUser: null,

  userLookup: async () => {},
  onAuthSuccess: async () => {},

  iotState: () => {},
  isOpenTutorial: false,

  onLogout: () => {},
  onLogin: async ({ email, password }) => {},
  isLoggingIn: false,
  forms: {
    [AUTH_FORMS.LOGIN]: { error: null },
    [AUTH_FORMS.FORGOT_PASSWORD]: { error: null },
  },
  clearFormErrors: formType => {},

  onboardingObject: {},
  setOnboardingObject: () => {},
  physicianDropdown: [],
  setPhysicianDropdown: () => {},
  specialtiesDropdown: [],
  setSpecialtiesDropdown: () => {},
  isSpecialtiesLoading: false,
  healthSystemDropdown: [],
  setHealthSystemDropdown: () => {},
  isRegisterLoading: false,
  onRegister: async () => {},
  registerError: {},
  getSpecialties: async () => {},
  getPhysicianGroup: async () => {},
  getPhysicianSpecialties: async () => {},
  getHealthSystem: async () => {},
  getHospital: async () => {},
  currentPage: null,
  setCurrentPage: () => {},
  registerBody: {},
  practiceNameDropdown: [],
  setPracticeNameDropdown: () => {},
  isCreateModalOpen: false,
  closeCreateModal: () => {},
  openCreateModal: () => {},
  isFetchingSpecialtyOption: false,
  isCreatingSpecialtyOption: false,

  departmentUsers: [],
  getDepartmentUsers: async () => {},

  isMailSending: false,
  sentProviderMail: async mailIds => {},
  renderInputComponent: true,
  ricState: () => {},

  getHeathSystemHospitals: async () => {},
  getHeathSystemDepartments: async () => {},
  getAllDepartment: async () => {},
  getHealthSystemById: async () => {},
  getHospitalById: async () => {},
  getHospitalDepartments: async () => {},
  getDepartmentById: async () => {},
  fetchSpecialtyOptions: async () => {},
  createSpecialtyOption: async specialtyName => {},

  chartCurrentPage:null,
  setChartCurrentpage:()=>{},
});

const AuthProvider = props => {
  const location = useLocation();
  const navigate = useNavigate();

  const [isAuthenticated, iaState] = useBoolean(false);
  const [isDetermined, idState] = useBoolean(false);
  const [isLoggingIn, iliState] = useBoolean(false);
  const [isOpenTutorial, iotState] = useBoolean(false);

  const [currentUser, setCurrentUser] = useState(null);
  const [forms, setForms] = useState({
    [AUTH_FORMS.LOGIN]: { error: null },
    [AUTH_FORMS.FORGOT_PASSWORD]: { error: null },
  });
  const [isSubmitting, isState] = useBoolean(false);
  const [forgotPasswordEmail, setForgotPasswordEmail] = useState('');
  const toast = useToastr();

  const [onboardingObject, setOnboardingObject] = useState({});
  const [isSpecialtiesLoading, islState] = useBoolean(false);

  const [isRegisterLoading, irlState] = useBoolean(false);
  const [registerError, setRegisterError] = useState({});
  const [registerBody, setRegisterBody] = useState({});
  const [physicianDropdown, setPhysicianDropdown] = useState([]);
  const [specialtiesDropdown, setSpecialtiesDropdown] = useState([]);
  const [healthSystemDropdown, setHealthSystemDropdown] = useState([]);
  const [practiceNameDropdown, setPracticeNameDropdown] = useState([]);
  const [departmentUsers, setDepartmentUser] = useState([]);
  const [currentPage, setCurrentPage] = useState(null);
  const [isCreateModalOpen, icmState] = useBoolean(false);
  const [isMailSending, imsState] = useBoolean(false);
  const [renderInputComponent, ricState] = useBoolean(true);
  const [specialtyOptions, setSpecialtyOptions] = useState([]);
  const [isFetchingSpecialtyOption, ifsoState] = useBoolean(false);
  const [isCreatingSpecialtyOption, icsoState] = useBoolean(false);

  const [chartCurrentPage,setChartCurrentpage]=useState(null);
  

  function closeCreateModal() {
    icmState.off();
  }
  function openCreateModal() {
    icmState.on();
  }
  function onAuthSuccess(nextUser) {
    iaState.on();
    nextUser.user_email
      ? setCurrentUser({ ...nextUser, email: nextUser.user_email })
      : setCurrentUser(nextUser);

    if (
      PUBLIC_PATH.includes(location.pathname) ||
      ROUTES.ROOT_SCREEN === location.pathname
    ) {
      navigate(ROUTES.DASHBOARD);
    }
  }

  async function onRegisterSuccess() {
    const response = await authRepository.login({
      email: registerBody.email,
      password: registerBody.password,
      role: CLIENT,
    });
    setCurrentPage(null);
    if (response.first_login) {
      iotState.on();
    }
    iaState.on();
    // setCurrentUser({ ...response.user, email: response.user.user_email });
    userLookup();
    closeCreateModal();
    navigate(ROUTES.DASHBOARD);
  }

  async function onLogout(nextUser) {
    iaState.off();
    setCurrentUser(null);
    await authRepository.logout();
    localStorage.removeItem('selectedFilter');
    localStorage.removeItem('customDateFilter');
    localStorage.removeItem(ACCESS_TOKEN);
    navigate(ROUTES.LOGIN_SCREEN);
  }

  async function userLookup(data) {
    try {
      idState.off();
      const response = await authRepository.userLookup();
      await onAuthSuccess(response);
    } catch (e) {
      iaState.off();
      setCurrentUser(null);
      if (localStorage.getItem(ACCESS_TOKEN)) {
        // await authRepository.logout();
        localStorage.removeItem(ACCESS_TOKEN);
      }
      if (!PUBLIC_PATH.includes(location.pathname)) {
        navigate(ROUTES.LOGIN_SCREEN);
      }
    } finally {
      idState.on();
    }
  }

  function clearFormErrors(formType) {
    setForms({
      ...forms,
      [formType]: { error: null },
    });
  }

  async function onLogin({ email, password }) {
    try {
      iliState.on();
      clearFormErrors(AUTH_FORMS.LOGIN);
      const response = await authRepository.login({
        email,
        password,
        role: CLIENT,
      });

      iliState.off();
      if (response.first_login) {
        iotState.on();
      }
      const { access_token, expires_at, user, ...userData } = response;

      await userLookup();
    } catch (e) {
      setForms({
        ...forms,
        [AUTH_FORMS.LOGIN]: { error: e.toString() },
      });
      iliState.off();
    }
  }
  async function onForgotPassword(email) {
    try {
      isState.on();
      await authRepository.forgotPassword({
        email,
        role: CLIENT,
      });
      setForgotPasswordEmail(email);
    } catch (e) {
      const response = e.cause;
      if (response && response?.message) {
        setForms({
          ...forms,
          [AUTH_FORMS.FORGOT_PASSWORD]: { error: response.message },
        });
      }
    } finally {
      isState.off();
      navigate(ROUTES.CONFIRMATION_SCREEN);
    }
  }
  async function onResetPassword({ password, token, email }) {
    try {
      isState.on();
      await authRepository.resetPassword({
        email,
        role: CLIENT,
        reset_password_token: token,
        password,
      });
      navigate(ROUTES.RESET_CONFIRMATION_SCREEN);
    } catch (e) {
      setForms({
        ...forms,
        [AUTH_FORMS.RESET_PASSWORD]: { error: e.toString() },
      });
      toast.showError({
        description: `${e.message}`,
      });
    } finally {
      isState.off();
    }
  }

  async function onRegister(body) {
    try {
      irlState.on();
      setRegisterBody(body);
      await authRepository.register(body);
      openCreateModal();
    } catch (e) {
      const response = e.cause;
      if (
        response &&
        response?.errors &&
        Object.keys(response?.errors).length
      ) {
        const keys = Object.values(response.errors);
        const descriptionData =
          keys && keys.length && keys.map(key => `${key}, `);
        toast.showError({
          description: descriptionData,
        });
        setRegisterError(response.errors);
      }
    } finally {
      irlState.off();
    }
  }

  async function getPhysicianSpecialties(id) {
    try {
      islState.on();
      const response = await authRepository.getPhysicianSpecialties(id);
      const data = response.map(res => ({
        label: res.name,
        value: res.id,
      }));
      setSpecialtiesDropdown(data);
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
      islState.off();
    }
  }
  async function getSpecialties(queryParams = {}) {
    try {
      islState.on();
      const response = await authRepository.getSpecialties(queryParams);
      setSpecialtiesDropdown(response);
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
      islState.off();
    }
  }

  async function getPhysicianGroup() {
    try {
      const response = await authRepository.getPhysicianGroup();
      const data = response.map(res => ({
        label: res.name,
        value: res.id,
        address: res.address,
        id: res.id,
      }));
      setPhysicianDropdown(data);
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
    }
  }

  async function getHealthSystem() {
    try {
      const response = await authRepository.getHealthSystem();
      const data = response.map(res => ({
        id: res.id,
        label: res.name,
        value: res.id,
        address: res.address,
      }));
      setHealthSystemDropdown(data);
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
    }
  }

  async function getHospital() {
    try {
      const response = await authRepository.getHospital();
      const data = response.map(res => ({
        id: res.id,
        label: res.name,
        value: res.id,
        address: res.address,
      }));
      setPracticeNameDropdown(data);
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
    }
  }

  async function getDepartmentUsers() {
    try {
      const response = await authRepository.getDepartmentUsers();
      const options = response.map(res => ({
        label: res.email,
        value: res.id,
      }));
      setDepartmentUser(options);
    } catch (error) {
      toast.showError({
        description: 'Something went wrong',
      });
    }
  }
  async function sentProviderMail(payload) {
    try {
      imsState.on();
      const response = await authRepository.sentProviderMail(payload);
      ricState.off();
      return response;
    } catch (e) {
      const response = e.cause;
      toast.showError({
        description: response?.error || 'Something went wrong',
      });
    } finally {
      imsState.off();
    }
  }

  async function getHeathSystemHospitals(healthSystemId) {
    try {
      islState.on();
      const response = await authRepository.getHealthSystemHospitals(
        healthSystemId
      );
      const data = response.map(res => ({
        label: res.name,
        value: res.id,
        id: res.id,
        address: res.address,
      }));
      setPracticeNameDropdown(data);
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
      islState.off();
    }
  }
  async function getHeathSystemDepartments(healthSystemId) {
    try {
      islState.on();
      const response = await authRepository.getHealthSystemDepartment(
        healthSystemId
      );
      const data = response[0].map(res => ({
        label: res.name,
        value: res.id,
        id: res.id,
      }));
      setSpecialtiesDropdown(data);
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
      islState.off();
    }
  }
  async function getAllDepartment() {
    try {
      islState.on();
      const response = await authRepository.getAllDepartment();
      const data = response[0].map(res => ({
        label: res.name,
        value: res.id,
        id: res.id,
      }));
      setSpecialtiesDropdown(data);
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
      islState.off();
    }
  }
  async function getHealthSystemById(healthSystemId) {
    try {
      islState.on();
      const response = await authRepository.getHealthSystemById(healthSystemId);
      return response;
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
      islState.off();
    }
  }
  async function getHospitalById(hospitalId) {
    try {
      islState.on();
      const response = await authRepository.getHospitalById(hospitalId);
      return response;
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
      islState.off();
    }
  }

  async function getDepartmentById(departmentId) {
    try {
      islState.on();
      const response = await authRepository.getDepartmentById(departmentId);
      return response;
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
      islState.off();
    }
  }

  async function getHospitalDepartments(hospitalId, queryParams = {}) {
    try {
      islState.on();
      const response = await authRepository.getHospitalDepartments(
        hospitalId,
        queryParams
      );
      setSpecialtiesDropdown(response);
    } catch (e) {
      toast.showError({
        description: 'Something went wrong',
      });
    } finally {
      islState.off();
    }
  }

  async function fetchSpecialtyOptions(queryParams = {}) {
    try {
      ifsoState.on();
      const response = await authRepository.getSpecialtyOptions(queryParams);
      setSpecialtyOptions(response);
    } catch (e) {
      // TODO: Handle Exception
    } finally {
      ifsoState.off();
    }
  }

  async function createSpecialtyOption(specialtyName) {
    try {
      icsoState.on();
      if (specialtyOptions.findIndex(d => d.label === specialtyName) === -1) {
        setSpecialtyOptions(prev => [
          ...prev,
          { label: specialtyName, value: specialtyName },
        ]);
      }
    } catch (e) {
      toast.showError({ description: e.toString() });
    } finally {
      icsoState.off();
    }
  }

  const mContext = {
    isAuthenticated,
    isDetermined,
    currentUser,

    userLookup,
    onAuthSuccess,

    onLogout,
    onLogin,
    isLoggingIn,
    isSubmitting,
    forgotPasswordEmail,
    onForgotPassword,
    onResetPassword,

    forms,
    clearFormErrors,

    iotState,
    isOpenTutorial,

    onboardingObject,
    setOnboardingObject,
    physicianDropdown,
    setPhysicianDropdown,
    specialtiesDropdown,
    setSpecialtiesDropdown,
    isSpecialtiesLoading,
    healthSystemDropdown,
    setHealthSystemDropdown,
    onRegister,
    isRegisterLoading,
    registerError,
    getSpecialties,
    getPhysicianGroup,
    getPhysicianSpecialties,
    currentPage,
    setCurrentPage,
    registerBody,
    practiceNameDropdown,
    setPracticeNameDropdown,
    isCreateModalOpen,
    closeCreateModal,
    openCreateModal,
    onRegisterSuccess,
    getHealthSystem,
    getHospital,
    isFetchingSpecialtyOption,
    isCreatingSpecialtyOption,

    departmentUsers,
    getDepartmentUsers,

    isMailSending,
    sentProviderMail,
    renderInputComponent,
    ricState,

    getHeathSystemHospitals,
    getHeathSystemDepartments,
    getAllDepartment,
    getHealthSystemById,
    getHospitalById,
    getHospitalDepartments,
    getDepartmentById,
    fetchSpecialtyOptions,
    createSpecialtyOption,


    chartCurrentPage,
    setChartCurrentpage,
  };

  return (
    <AuthContext.Provider value={mContext}>
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthProvider;
