import { Box, HStack, Text, Tooltip } from '@chakra-ui/react'
import React from 'react'
import AppColors from '../../constants/AppColors'
import { TriangleIcon, TriangleRedIcon } from '../../constants/IconData'
import { FONT_FAMILY } from '../../constants/Theme'
import { toTitleCase } from '../../utils/common.utils'

import './thermometer.css'
import { MapInteractionCSS } from 'react-map-interaction';
import { useState } from 'react'
import { useEffect } from 'react'

const Thermometer = (props) => {

  const _generateOptions = () => {

    return {
      theme: () => props.theme === 'light' || props.theme === 'dark' ? props.theme : 'light',
      value: props.value || 0, //default 0
      max: props.max || 100, //default 100
      steps: props.steps,
      format: props.format || '',
      size: () => props.size === 'small' || props.size === 'normal' || props.size === 'large' ? props.size : 'normal',
      height: props.height || 200, //default 200
      valstr: () => ((options.value % 1) > 0 ? options.value.toFixed(1) : options.value) + options.format,
      percent: () => options.value / options.max * 100,
      reverseGradient: props.reverseGradient || false, // default false
      intervals: [],
      limit: props.limit ? props.limit : {},
      list: props.list ? props.list : [],
      tile: props.tile ? props.tile : false,
      width: props.width ? props.width : 0,
      diff: props.risk_temperature_diff ? props.risk_temperature_diff : 0
    }
  }

  const options = _generateOptions()

  const _createIntervals = () => {
    if (options.steps) {
      for (let step = 0; step <= options.steps; step++) {
        let val = ((options.max / options.steps) * step)
        let percent = (val / options.max) * 100
        let interval = { percent: percent, label: val + options.format }
        options.intervals.push(interval)
      }
    }
  }
  const _createIntervalsUI = (intervals) => {
    return intervals.map((step, i) => {
      return (
        <li key={`${i}-interval`} style={{ bottom: `calc(${step.percent}% - 1px)` }}>
          {step.label}
        </li>
      )
    })
  }

  const _createGoalIntervalsUI = (intervals) => {
    return <ul className="thermometer__goal__statistics">
      {
        intervals.below ? <li key={`${intervals.below}-goal`} style={{ bottom: `calc(${(intervals.below / options.max) * 100}% - 1px)`, borderColor: AppColors.secondary }}>
          <Tooltip label={<Box>
            <Text><span style={{ fontWeight: 'bold' }}>Bottom Parameter:</span> {intervals.below}°F</Text>
          </Box>} aria-label='A tooltip' hasArrow bg={'white'} color='black' placement='left'>
            <Box style={{
              zIndex: 1,
              width: '100%',
              position: "absolute",
              top: "initial",
              right: "initial",
              bottom: "initial",
              left: "0",
              borderBottom: '2px dashed #fff',
              cursor: 'pointer'
            }}>
            </Box>
          </Tooltip>
        </li> : null}
      {
        intervals.above ? <li key={`${intervals.above}-goal`} style={{ bottom: `calc(${(intervals.above / options.max) * 100}% - 1px)` }}>
          <Tooltip label={<Box>
            <Text><span style={{ fontWeight: 'bold' }}>Top Parameter:</span> {intervals.above}°F</Text>
          </Box>} aria-label='A tooltip' hasArrow bg={'white'} color='black' placement='left'>
            <Box style={{
              zIndex: 1,
              width: '100%',
              position: "absolute",
              top: "initial",
              right: "initial",
              bottom: "initial",
              left: "0",
              borderBottom: '2px dashed #fff',
              cursor: 'pointer'

            }}>
            </Box>
          </Tooltip>
        </li> : null}
      {
        intervals.goal ? <li key={`${intervals.goal}-goal`} style={{ bottom: `calc(${(intervals.goal / options.max) * 100}% - 1px)` }}
        >
          <Tooltip label={<Box>
            <Text><span style={{ fontWeight: 'bold' }}>Perfect Temperature:</span> {intervals.goal}°F</Text>
          </Box>} aria-label='A tooltip' hasArrow bg={'white'} color='black' placement='left'>
            <Box style={{
              zIndex: 1,
              width: '100%',
              position: "absolute",
              top: "initial",
              right: "initial",
              bottom: "initial",
              left: "0",
              borderBottom: '2px dashed #fff',
              cursor: 'pointer'

            }}>
            </Box>
          </Tooltip>
        </li> : null}
    </ul>
  }

  const _createListIntervalsUI = (intervals) => {
    return <ul className="thermometer__list__statistics">
      {intervals.map((interval, index) => <li key={`${interval.risk_temperature}-${index}-temp`} style={{ bottom: `calc(${(interval.risk_temperature / options.max) * 100}% - 1px)` }}  >
        <Tooltip label={<Box>
          <Text>{intervals.filter(d => (d.risk_temperature === interval.risk_temperature)).map(d => toTitleCase(d.name)).join(', ')}</Text>
          <Text><span style={{ fontWeight: 'bold' }}>Risk Temperature:</span> {interval.risk_temperature}°F</Text>
          <Text><span style={{ fontWeight: 'bold' }}>RI Score:</span> {intervals.filter(d => (d.risk_temperature === interval.risk_temperature)).map(d => d.cq_score).join(', ')}</Text>
        </Box>} aria-label='A tooltip' hasArrow bg={'white'} color='black' placement='left'>
          <Box style={{
            zIndex: 1,
            width: '100%',
            position: "absolute",
            top: "initial",
            right: "initial",
            bottom: "initial",
            left: "0",
            borderBottom: '2px solid #000',
            cursor: 'pointer'
          }}>

          </Box>
        </Tooltip>
      </li>
      )
      }
    </ul>
  }

  const [value, setValue] = useState({
    scale: 1,
    translation: { x: 0, y: 0 }
  })
  useEffect(() => {
    if (options.width) {
      setValue(prev => ({
        ...prev,
        translation: { x: (options.width / 2.7), y: 0 }
      }))
    }
  }, [options.width])
  const theme = `thermometer--theme-${options.theme()}`
  const size = `thermometer--${options.size()}`
  const height = { height: `${options.height}px` }
  const heightPercent = { height: `${options.percent()}%` }
  const heightBgColor = { height: `calc(${options.height}px - 57px)` }
  const reverse = options.reverseGradient ? 'Reverse' : ''
  _createIntervals()
  const intervals = options.limit
  const stepIntervals = _createIntervalsUI(options.intervals)
  const stepGoalIntervals = _createGoalIntervalsUI(options.limit)

  const diff = options.diff
  const valstr = options.valstr()
  const stepListIntervals = _createListIntervalsUI(options.list)

  const renderThermometer = () => {
    return <div style={{ ...height, position: 'relative', marginTop: 10, marginBottom: 10 }} className={`thermometer ${size} ${theme}`}>
      <div style={{ position: 'absolute', bottom: 10, left: 25, zIndex: 10, textAlign: 'center', width: 50 }}>
        <Text lineHeight={1} textAlign='center'
          color='white' fontSize={props.value > 100 ? 'xs' : 'md'}>{valstr}</Text>
        <HStack justifyContent={'center'}>
          {diff >= 0 ? (
            <TriangleIcon style={{ height: 10, width: 10 }} />
          ) : (
            <TriangleRedIcon style={{ height: 10, width: 10 }} />
          )}
          <Text
            fontFamily={FONT_FAMILY.ptSans}
            fontSize={'xs'}
            color={'white'}
            lineHeight={1}
            style={{ marginLeft: 1 }}
          >
            {diff >= 0 ? '+' : null}
            {diff}
          </Text>
        </HStack>
      </div>
      <div className="thermometer__draw-a"></div>
      <div className={`thermometer__draw-b${reverse}`}></div>
      <div className="thermometer__meter">
        <ul className="thermometer__statistics">{stepIntervals}</ul>
        {stepGoalIntervals}
        {stepListIntervals}
        <div style={heightPercent} className="thermometer__mercury">

          <div className="thermometer__mask">
            <div className={`thermometer__bg-color${reverse}`}
              style={{
                ...heightBgColor,
                background: `linear-gradient(#fa7e57 ,#F9480F ${100 - ((intervals.above / options.max) * 100)}% , #43DAD4 ${100 - ((intervals.goal / options.max) * 100)}%, #023B93 ${100 - ((intervals.below / options.max) * 100)}%, #4d75b3)`
              }}
            >
            </div>
          </div>
        </div>
      </div>
    </div>
  }
  return options.tile ? <MapInteractionCSS
    value={value}
    minScale={1}
    onChange={(data) => {
      setValue(data)
    }}
    // disablePan={true}
    translationBounds={{
      xMin: -10, xMax: (options.width - 100)
    }}
  >
    {renderThermometer()}
  </MapInteractionCSS> : renderThermometer()

}

export default Thermometer