/* eslint-disable react-hooks/exhaustive-deps */
import { SearchIcon } from '@chakra-ui/icons';
import {
    Box,
    Button,
    Divider,
    HStack,
    ScaleFade,
    Skeleton,
    Stack,
    Text,
    VStack,
} from '@chakra-ui/react';
import {
    CQReactTable,
    TextInput,
} from '@prasanna-transcend/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate, } from 'react-router-dom';
import AppColors from '../../../constants/AppColors';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import {
    replaceRoute,
    toTitleCase,
} from '../../../utils/common.utils';
import { defaultReactTableProp } from '../../../utils/my_audits.utils';
import { priceConverter } from '../../../utils/priceConverter';
import { DashboardContext } from '../../../providers/DashboardProvider';
import { getCurrentUserTypeId } from '../../../utils/getCurrentUserTypeId';
import { AuthContext } from '../../../providers/AuthProvider';

const columnHelper = createColumnHelper();

function PhysicianTable() {
    const [ordering, setOrdering] = useState('');
    const [searchText, setSearchText] = useState('');
    const authContext = useContext(AuthContext)

    const { filterParams, hospitalFilter, setHospitalFilter, hospitals, getPhysicians, isHospitalLoading } = useContext(DashboardContext)

    useEffect(() => {
        const obj = getCurrentUserTypeId(authContext.currentUser);
        setHospitalFilter({
            ...hospitalFilter,
            ...obj,
        });
    }, [authContext.currentUser]);

    useEffect(() => {
        if (hospitalFilter.department_id) {
            getPhysicians.loadAPI()
        }
    }, [
        hospitalFilter])

    useEffect(() => {
        getPhysicians.setParams({ ordering });
    }, [ordering]);
    const onFilterChange = e => {
        if (e) {
            setSearchText(e.target.value);
        } else {
            setSearchText('');
        }
    };

    const onSearchAction = () => {
        setHospitalFilter(prev => ({
            ...prev,
            search: searchText,
        }));
    };

    const navigate = useNavigate();

    const columns = React.useMemo(
        () => [
            columnHelper.accessor('provider', {
                header: () => 'Provider Name',
                id: 'name',
                cell: info => {
                    const physicianFirstName = info.row.original.provider?.first_name;
                    const physicianLastName = info.row.original.provider?.last_name;
                    return (
                        <Text>
                            {toTitleCase(`${physicianFirstName} ${physicianLastName}`)}
                        </Text>
                    );
                },
            }),
            columnHelper.accessor('total_uploads', {
                header: () => 'Uploads',
                cell: info => {
                    return <Text>{info?.renderValue()}</Text>;
                },
            }),

            columnHelper.accessor('chart_accuracy', {
                id: 'chart_accuracy',
                header: () => <span>Chart Accuracy</span>,
                cell: info => {
                    const cellValue = info?.getValue();
                    if (!cellValue) {
                        return <Text color={AppColors.gray}>N/A</Text>;
                    }

                    return cellValue;
                },
            }),
            columnHelper.accessor('cq_rev_opp', {
                header: () => 'RI Rev. Opp',
                id: 'cq_rev_opp',
                cell: info => {
                    const cellValue = info?.getValue();
                    if (!cellValue) {
                        return <Text color={AppColors.gray}>N/A</Text>;
                    }

                    return priceConverter(cellValue);
                },
            }),
            columnHelper.accessor('organization_rank', {
                header: () => 'Organization Rank',
                cell: info => {
                    return <Text>{info?.renderValue()}</Text>;
                },
            }),
            columnHelper.accessor('provider', {
                header: () => 'Action',
                id: 'action',
                cell: info => {
                    const physicianId = info.row.original.provider?.provider_id;
                    return (
                        <Button
                            size={'sm'}
                            bg={AppColors.secondary}
                            color={'white'}
                            borderRadius="3xl"
                            onClick={() => {
                                const route = replaceRoute(ROUTES.PHYSICIAN_DASHBOARD, {
                                    departmentId: filterParams?.department_id,
                                    hospitalId: 1,
                                    physicianId: physicianId,
                                });
                                navigate(route);
                            }}
                            px={10}
                            fontWeight="normal"
                            fontSize={'sm'}
                            _hover={{
                                transition: 'all .1s ease',
                                bgColor: AppColors.white,
                                color: AppColors.secondary,
                                outline: `2px solid ${AppColors.secondary}`,
                            }}
                        >
                            View
                        </Button>
                    );
                },
            }),
        ],
        // eslint-disable-next-line
        [hospitals]
    );

    const getTableCellProps = (cell, data) => {
        const startingHeaderId = ['name'];

        return {
            fontFamily: FONT_FAMILY.ptSans,
            textAlign: startingHeaderId.includes(data.column.id) ? 'none' : 'center',
            fontWeight: 'bold',
            color: AppColors.secondary,
        };
    };

    function getHeaderCellProps(header) {
        const startingHeaderId = ['name'];
        return {
            textTransform: 'none',
            fontFamily: FONT_FAMILY.ptSans,
            fontWeight: 'bold',
            color: AppColors.black,
            justifyContent: startingHeaderId.includes(header.id)
                ? 'flex-start'
                : 'center',
        };
    }

    function handlePreviousPage() {
        getPhysicians.loadPrevious();
    }

    function handleNextPage() {
        getPhysicians.loadNext();
    }

    function handleSizeChange(size) {
        getPhysicians({ size });
    }
    const renderTable = () => {
        return (
          <ScaleFade initialScale={0.5} in={true}>
            <Box
              id="fixedTableHeader"
              className="tableWraper"
              maxHeight={'300px'}
              overflowY={'scroll'}
            >
              <CQReactTable
                isLoading={isHospitalLoading}
                data={hospitals}
                columns={columns}
                {...defaultReactTableProp}
                getHeaderCellProps={header => {
                  return {
                    ...getHeaderCellProps(header),
                  };
                }}
                getCellProps={getTableCellProps}
                onAsc={header => {
                  setOrdering(header);
                }}
                onDesc={header => {
                  setOrdering(`-${header}`);
                }}
                onPreviousPage={handlePreviousPage}
                onNextPage={handleNextPage}
                onSizeChange={handleSizeChange}
              />
            </Box>
          </ScaleFade>
        );
    };


    const renderFailedPage = () => {
        return (
            <VStack m={4} justifyContent={'center'} h={'100%'} textAlign={'center'}>
                <Text>Something went wrong, Please try again later</Text>
            </VStack>
        );
    };

    const renderLoader = () => {
        return (
            <Stack p={4}>
                <Skeleton height="20px" />
                <Skeleton height="20px" />
                <Skeleton height="20px" />
            </Stack>
        );
    };

    const renderMainContent = () => {
        if (isHospitalLoading) {
            return renderLoader();
        } else if (!hospitals) {
            return renderFailedPage();
        } else {
            return renderTable();
        }
    };

    return (
        <Box
            marginTop={3}
            width={'100%'}
            bg={AppColors.white}
            padding={4}
            borderRadius={'lg'}
            shadow={'md'}
        >
            <HStack width={'100%'} justifyContent={'space-between'} mb={3}>
                <Text fontFamily={FONT_FAMILY.ptSans} color={AppColors.primary}>
                    Provider
                </Text>
                <Box>
                    <TextInput
                        borderRadius={'md'}
                        onKeyDown={e => {
                            if (e.keyCode === 13) {
                                onSearchAction();
                            }
                        }}
                        onChange={e => onFilterChange(e)}
                        rightIconProps={{ zIndex: 0 }}
                        placeholder="Search Providers"
                        rightIcon={<SearchIcon color={AppColors.gray} />}
                    />
                </Box>
            </HStack>
            <Divider />
            {renderMainContent()}
        </Box>
    );
}

export default PhysicianTable;
