import {
    Stack,
    Image,
    Box,
} from '@chakra-ui/react';
import AppColors from '../../../constants/AppColors';
import { EyeHiddenIcon, EyeIcon, LockIcon } from '../../../constants/IconData';
import { QWatermark } from '../../../constants/ImageData';
import React, { useContext } from 'react';
import { AuthContext, AUTH_FORMS } from '../../../providers/AuthProvider';
import { renderCoverImage } from '../../../components/AuthCoverImage';
import { CQResetPassword } from '@prasanna-transcend/code-quick-components';
import { useQuery } from '../../../hooks';


export default function ResetPasswordContainer() {
    let query = useQuery();
    const token = query.get("token")
    const email = query.get("email").replace('/', '')


    const authContext = useContext(AuthContext);

    const formServerError = authContext.forms[AUTH_FORMS.RESET_PASSWORD]?.error;

    return (
        <Box bgColor={AppColors.primary} py={'5%'} px={'20%'} minH={'100vh'}>
            <Image top={'5vh'} left={30} style={{ height: '95vh' }} position={'absolute'} src={QWatermark} alt='q-watermark' />
            <Stack spacing={-8} direction={{ base: 'column', md: 'row' }}>
                {renderCoverImage()}
                <CQResetPassword
                    onResetPassword={authContext.onResetPassword}
                    forgotPasswordEmail={email}
                    isSubmitting={authContext.isSubmitting}
                    token={token}
                    email={email}
                    formServerError={formServerError}
                    title={'Reset Password'}
                    description={'Enter New Password for'}
                    buttonName={'Submit'}
                    newPasswordProps={{
                        leftIcon: <LockIcon />,
                        passwordShowIcon: <EyeHiddenIcon />,
                        passwordHideIcon: <EyeIcon />
                    }}
                    confirmPasswordProps={{
                        leftIcon: <LockIcon />,
                        passwordShowIcon: <EyeHiddenIcon />,
                        passwordHideIcon: <EyeIcon />
                    }}
                />
            </Stack>
        </Box>
    );
}