/* eslint-disable array-callback-return */
/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from 'react';
import { HotTable } from '@handsontable/react';
import 'handsontable/dist/handsontable.min.css';
import {
  AGREE_DISAGREE_COL,
  AUDIT_SHEET_HEADER,
  AUDIT_SHEET_KEY,
  BATCH_AGREE_DISAGREE_COL,
  BATCH_AUDIT_SHEET_HEADER,
  BATCH_RESPONSE_AS_COL,
  NUMERIC_KEYS,
  RENDERING_AS_COL,
  RESPONSE_AS_COL,
} from '../constants';
import { registerAllModules } from 'handsontable/registry';
import Handsontable from 'handsontable';
import '../styles.css';
import { useBoolean } from '@chakra-ui/react';
import { useContext } from 'react';
import { AuditSheetContext } from '../../../providers/AuditSheetProvider';
import { useToastr } from '@prasanna-transcend/code-quick-components';
import { getRowColArray } from '../getRowColumn';
import AppColors from '../../../constants/AppColors';
import { registerCustomCells } from './hands_on_table_renderers/CustomRenderers';
import { format } from 'date-fns';
import { toTitleCase } from '../../../utils/common.utils';
import { useLocation, useSearchParams } from 'react-router-dom';
import {
  findAgreeDisAgreeColumn,
  findDollorColumn,
  findHighlightedColumn,
  findResponseColumn,
  getAuditSheetKeys,
  // prepareAuditSheetColumn,
} from '../../../utils/auditsheet.utils';
import { defaultColNames } from '../../../constants/constants';
import { prepareAuditSheetHeader } from '../../../utils/prepareSheetHeader.utils';
import { prepareAuditSheetColumn } from '../../../utils/prepareAuditsheetColumns.utils';

function getScaledRowHeight(scale) {
  return `${24 * scale}px`;
}

function getScaledHeaderFontSize(scale) {
  return 13 * scale + 'px';
}

function getScaledRowFontSize(scale) {
  return 14 * scale + 'px';
}

const AuditSheet = ({
  scale = 1,
  sheet,
  view,
  updateAuditSheet,
  updateSheet,
  onCommentClick,
  closeComment,
  batch,
  batchId,
  uploadId,
  height,
  setSheets
}) => {
  const location = useLocation();

  let agreeValidator = (value, callback) => {
    setTimeout(() => {
      if (value.toLowerCase() === 'yes' || value === '-') {
        callback(true);
      } else {
        callback(false);
      }
    }, 1000);
  };

  const [queryParameters] = useSearchParams(location.search);
  const rowLocation = queryParameters.get('location');
  registerCustomCells({});

  // contexts
  const auditSheetContext = useContext(AuditSheetContext);
  const { industryCodes, getComments, commentFlags, providerOptions } =
    auditSheetContext;

  const {
    setAuditSheetColumnsKeys,
    setHilightedColumn,
    setDollorColumn,
    setAgreeDisAgreeCol,
  } = auditSheetContext;

  const {
    agreeDisAgreeCol,
    auditSheetColumnsKeys,
    dollarColumn,
    highlightedColumn,
  } = auditSheetContext;

  // booleans
  const [isProviderDollarDisabled, ipddState] = useBoolean(false);
  const [isAuditedDollarDisabled, iaddState] = useBoolean(false);

  const [sheetData, setSheetData] = useState([]);
  const [columnData, setColumnData] = useState([]);
  const [auditSheetHeaders, setAuditSheetHeaders] = useState([]);
  const [hiddenColumns, setHiddenColumns] = useState([]);
  const [tempColData, setTempColData] = useState([]);
  // refs
  const hotRef = useRef(null);
  const [localHeader, setLocalHeader] = useState(auditSheetHeaders);
  const [localColumnData, setLocalColumnData] = useState(columnData);
  const [responseColumn, setResponseColumn] = useState(null);

  const toast = useToastr();

  registerAllModules();

  Handsontable.renderers.registerRenderer(
    'customStylesRenderer',
    (hotInstance, TD, ...rest) => {
      Handsontable.renderers.getRenderer('text')(hotInstance, TD, ...rest);
      TD.style.fontSize = getScaledRowFontSize(scale);
      TD.style.textAlign = 'center';
    }
  );
  Handsontable.renderers.registerRenderer(
    'agreeRenderer',
    (hotInstance, TD, row, column, prop, value, cellProperties) => {
      Handsontable.renderers.getRenderer('text')(
        hotInstance,
        TD,
        row,
        column,
        prop,
        value,
        cellProperties
      );
      TD.style.fontSize = getScaledRowFontSize(scale);
      TD.style.textAlign = 'center';
      if (value != null) {
        TD.innerHTML = value === true || value === 'true' ? 'Yes' : '-';
      }
    }
  );

  function getWindowSize() {
    const { innerWidth, innerHeight } = window;
    return { innerWidth, innerHeight };
  }

  const [windowSize, setWindowSize] = useState(getWindowSize().innerWidth);

  useEffect(() => {
    function handleWindowResize() {
      const size = getWindowSize();
      setWindowSize(size.innerWidth);
    }
    window.addEventListener('resize', handleWindowResize);

    return () => {
      window.removeEventListener('resize', handleWindowResize);
    };
  }, []);

  useEffect(() => {
    const hot = hotRef.current.hotInstance;
    if (
      Object.keys(auditSheetContext.selectedCell).length &&
      sheet.data.length
    ) {
      const { row, col } = auditSheetContext.selectedCell;
      const index = sheet.data.findIndex(d => d.id === row);
      if (index !== -1) {
        hot.selectCell(index, col);
      }
    } else {
      hot.deselectCell();
    }
  }, [auditSheetContext.selectedCell]);

  useEffect(() => {
    const hot = hotRef.current.hotInstance;
    if (rowLocation && sheet.data.length) {
      const index = sheet.data.findIndex(d => d.row_id === Number(rowLocation));
      if (index !== -1) {
        hot.selectRows(index);
        hot.scrollViewportTo(Number(index), 0);
      }
    }
  }, [rowLocation, sheetData]);

  React.useEffect(() => {
    setColumnData(localColumnData);
    setAuditSheetHeaders(localHeader);
  }, [localHeader, localColumnData]);

  React.useEffect(() => {
    if (columnData.length) {
      const emptyColumns = [];

      columnData.forEach((cd, i) => {
        const isEmptyColumn = hotRef.current?.hotInstance?.isEmptyCol(i);
        const columnName = cd?.data;
        if (isEmptyColumn && !defaultColNames.includes(columnName)) {
          emptyColumns.push(i);
        }
      });

      const _tempColData = columnData.filter(
        (_, index) => !emptyColumns.includes(index)
      );

      setTempColData(_tempColData);

      setHiddenColumns([...emptyColumns]);
    }
  }, [columnData]);

  useEffect(() => {
    if (hiddenColumns.length) {
      const emptyColumns = [];
      const emptyColumnNames = [];

      columnData.forEach((cd, i) => {
        const isEmptyColumn = hotRef.current?.hotInstance?.isEmptyCol(i);
        const columnName = cd?.data;
        if (isEmptyColumn && !defaultColNames.includes(columnName)) {
          emptyColumns.push(i);
          emptyColumnNames.push(columnName);
        }
      });

      const tempColumnData = columnData.filter(
        (col, i) => !emptyColumnNames.includes(col.data)
      );
      const tempColumnHeader = auditSheetHeaders.filter(
        (header, i) => !emptyColumns.includes(i)
      );

      setLocalHeader(tempColumnHeader);
      setLocalColumnData(tempColumnData);
      const _sheetData=sheet.data
    
      const updatedRes = _sheetData.map(item => {
        const updatedItem = { ...item };
        emptyColumnNames.forEach(key => {
          if (key in updatedItem) {
            delete updatedItem[key];
          }
        });
        return updatedItem;
      });

      setSheets([{
        index: 1,
        sheet_name: 'Sheet1',
        data:updatedRes
      }])

    }
  }, [hiddenColumns]);

  React.useEffect(() => {
    const _hilghtedColumn = findHighlightedColumn(columnData);
    const _dollorColumn = findDollorColumn(columnData);
    const _auditSheetkey = getAuditSheetKeys(columnData);
    const _agreeDisagree = findAgreeDisAgreeColumn(columnData);
    const _responseColumn = findResponseColumn(columnData);

    setResponseColumn(_responseColumn);
    setAuditSheetColumnsKeys([..._auditSheetkey]);
    setHilightedColumn([..._hilghtedColumn]);
    setDollorColumn([..._dollorColumn]);
    setAgreeDisAgreeCol([..._agreeDisagree]);
  }, [columnData.length]);

  const _columnData = [
    {
      data: 'encounter_no',
      type: 'numeric',
      width: windowSize * 0.07,
      alignment: 'center',
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'rendering',
      type: 'audit_sheet.dropdown_renderer',
      width: windowSize * 0.07,
      source: providerOptions.map(p => p.value),
      readOnly: view,
    },
    {
      data: 'enc_dt',
      type: 'date',
      dateFormat: 'MM-DD-YYYY',
      width: windowSize * 0.07,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'srvcs_no',
      type: 'text',
      width: windowSize * 0.07,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'provider_rvu',
      type: 'numeric',
      width: windowSize * 0.07,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'provider_dollar_value',
      type: 'audit_sheet.currency_renderer',
      width: windowSize * 0.05,
      readOnly: view || isProviderDollarDisabled,
    },
    {
      data: 'response',
      type: 'text',
      width: windowSize * 0.11,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'agree',
      type: 'text',
      validator: agreeValidator,
      width: windowSize * 0.07,
      renderer: 'agreeRenderer',
      readOnly: view,
    },
    {
      data: 'disagree',
      type: 'text',
      validator: agreeValidator,
      width: windowSize * 0.07,
      renderer: 'agreeRenderer',
      readOnly: view,
    },
    {
      data: 'audited_code',
      type: 'text',
      width: windowSize * 0.07,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'audited_rvu',
      type: 'numeric',
      width: windowSize * 0.07,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'audited_dollar_value',
      type: 'audit_sheet.currency_renderer',
      width: windowSize * 0.07,
      readOnly: view || isAuditedDollarDisabled,
    },
    {
      data: 'notes',
      type: 'text',
      width: windowSize * 0.14,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
  ];

  React.useEffect(() => {
    const reDestructuredData = sheet.data;

    // spreading in object
    const _reDestructuredData = reDestructuredData.map(sd => {
      const tempObj = { ...sd, ...sd?.additional_attributes };

      delete tempObj.additional_attributes;

      return tempObj;
    });

    // -- preparing dynamic column headers --
    const auditSheetColumnsheaders =
      prepareAuditSheetHeader(_reDestructuredData);
    setAuditSheetHeaders(auditSheetColumnsheaders);

    // dynamic columns
    const dynamicColumnData = prepareAuditSheetColumn(
      _reDestructuredData,
      windowSize,
      view,
      providerOptions,
      isAuditedDollarDisabled,
      isProviderDollarDisabled
    );

    if (!!dynamicColumnData.length) {
      setColumnData(dynamicColumnData);
    } else {
      setColumnData(_columnData);
    }
  }, [sheet]);

  function setLocalSheetData(data) {
    if (!data) {
      setSheetData([]);
    }
    const nextSheetData = [];
    for (let i = 0; i < data.length; i++) {
      const datum = data[i];

      const renderingValue = datum.rendering;
      if (renderingValue) {
        if (
          typeof renderingValue === 'object' &&
          Object.keys(renderingValue).length
        ) {
          datum.rendering = toTitleCase(
            `${renderingValue.first_name} ${renderingValue.last_name}`
          );
        } else {
          const providerOption = providerOptions.find(
            p => p.id === renderingValue
          );
          if (providerOption) {
            datum.rendering = providerOption.value;
          }
        }
      }
      if (datum.enc_dt) {
        const d = Date.parse(datum.enc_dt);
        if (d) {
          datum.enc_dt = format(new Date(d), 'MM-dd-yyyy');
        }
      }
      nextSheetData.push(datum);
    }
    setSheetData(nextSheetData);
  }

  useEffect(() => {
    registerCustomCells({ scale });
  }, [scale]);

  useEffect(() => {
    if (sheet.data) {
      setLocalSheetData(sheet.data);
    }
  }, [sheet.data]);

  const updateSheetData = (nextSheetData, row, rowIndex) => {
    if (rowIndex != null) {
      nextSheetData[rowIndex] = {
        ...nextSheetData[rowIndex],
        ...row,
      };
    } else {
      nextSheetData.push(row);
    }

    return nextSheetData;
  };

  const onBeforeHotChange = change => {
    let nextSheetData = [...sheetData];
    change.map(changes => {
      const isNewRow = changes[0] > sheetData.length;
      switch (changes[1]) {
        case 'srvcs_no':
          if (
            Object.keys(industryCodes).length &&
            Object.keys(industryCodes).includes(changes[3])
          ) {
            const row = {
              [changes[1]]: changes[3],
              provider_dollar_value: industryCodes[changes[3]],
            };

            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : changes[0]
            );
            ipddState.on();
          } else {
            toast.showError({
              description: 'This Srvcs value is not available.',
            });
            const row = {
              srvcs_no: null,
              provider_dollar_value: null,
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : changes[0]
            );
          }
          break;
        case 'audited_code':
          if (
            Object.keys(industryCodes).length &&
            Object.keys(industryCodes).includes(changes[3])
          ) {
            const row = {
              [changes[1]]: changes[3],
              audited_dollar_value: industryCodes[changes[3]],
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : changes[0]
            );
            iaddState.on();
          } else {
            toast.showError({
              description: 'This Audited Code is not available.',
            });
            const row = {
              audited_code: null,
              audited_dollar_value: null,
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              row,
              isNewRow ? null : changes[0]
            );
          }
          break;
        case 'agree':
          const row = {
            ...nextSheetData[changes[0]],
            [changes[1]]:
              changes[3] === ''
                ? null
                : changes[3].toLowerCase() === 'yes' ||
                  changes[3].toLowerCase() === 'true'
                ? true
                : false,
            disagree:
              changes[3] === ''
                ? null
                : changes[3].toLowerCase() === 'yes' ||
                  changes[3].toLowerCase() === 'true'
                ? false
                : true,
          };
          nextSheetData = updateSheetData(
            nextSheetData,
            row,
            isNewRow ? null : changes[0]
          );
          if (
            changes[3].toLowerCase() === 'yes' ||
            changes[3].toLowerCase() === 'true'
          ) {
            nextSheetData[changes[0]].audited_code =
              nextSheetData[changes[0]].srvcs_no;
            nextSheetData[changes[0]].audited_rvu =
              nextSheetData[changes[0]].provider_rvu;
            nextSheetData[changes[0]].audited_dollar_value =
              nextSheetData[changes[0]].provider_dollar_value;
          }
          break;
        case 'disagree':
          const disagreeRow = {
            [changes[1]]:
              changes[3] === ''
                ? null
                : changes[3].toLowerCase() === 'yes' ||
                  changes[3].toLowerCase() === 'true'
                ? true
                : false,
            agree:
              changes[3] === ''
                ? null
                : changes[3].toLowerCase() === 'yes' ||
                  changes[3].toLowerCase() === 'true'
                ? false
                : true,
          };
          nextSheetData = updateSheetData(
            nextSheetData,
            disagreeRow,
            isNewRow ? null : changes[0]
          );
          break;
        case 'enc_dt':
          let data = changes[3];
          if (data) {
            const d = Date.parse(data);
            if (d) {
              const encDtRow1 = {
                [changes[1]]: format(new Date(d), 'MM-dd-yyyy'),
              };
              nextSheetData = updateSheetData(
                nextSheetData,
                encDtRow1,
                isNewRow ? null : changes[0]
              );
            } else {
              toast.showError({ description: 'Please, enter a valid date' });
            }
          } else {
            const encDtRow1 = {
              [changes[1]]: null,
            };
            nextSheetData = updateSheetData(
              nextSheetData,
              encDtRow1,
              isNewRow ? null : changes[0]
            );
          }

          break;
        default:
          const defaultRow = {
            [changes[1]]: changes[3],
          };
          nextSheetData = updateSheetData(
            nextSheetData,
            defaultRow,
            isNewRow ? null : changes[0]
          );
          break;
      }
    });
    setLocalSheetData(nextSheetData);
    //update audit sheet on first cell update in a row
    if (change.some(d => !sheetData[d[0]].id)) {
      updateAuditSheet({ ...sheet, data: nextSheetData });
    } else {
      updateSheet(nextSheetData);
    }
    return false;
  };

  function columnHighlight(
    instance,
    td,
    row,
    col,
    prop,
    value,
    cellProperties
  ) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.style.background = AppColors.lightPink;
    td.style.fontSize = getScaledRowFontSize(scale);
    td.style.textAlign = 'center';
  }

  function commentCell(instance, td, row, col, prop, value, cellProperties) {
    Handsontable.renderers.TextRenderer.apply(this, arguments);
    td.className = 'htCommentCell';
    td.style.fontSize = getScaledRowFontSize(scale);

    let COL = highlightedColumn; //  [4, 5, 10, 11]
    if (batch) {
      COL = highlightedColumn; // [5, 6, 11, 12]
    }
    if (COL.includes(col)) {
      td.style.background = AppColors.lightPink;
    }
    if (batch) {
      td.style.textAlign = 'center';
      if (agreeDisAgreeCol.includes(col)) {
        //agree
        td.innerHTML = value === true ? 'Yes' : '-';
      }
    } else {
      td.style.textAlign = col !== RENDERING_AS_COL && 'center';

      if (agreeDisAgreeCol.includes(col)) {
        //agree
        td.innerHTML = value === true ? 'Yes' : '-';
      }
    }
  }

  const hasComment = (row, col) => {
    if (row < sheet?.data?.length) {
      const colData = batch
        ? auditSheetColumnsKeys[col]
        : auditSheetColumnsKeys[col + 1];
      const rowId = sheet?.data[row]?.id;
      if (rowId && commentFlags && Object.keys(commentFlags).length) {
        if (commentFlags[rowId]?.includes(colData)) {
          return true;
        }
      }
    }
    return false;
  };
  const headerData = batch ? BATCH_AUDIT_SHEET_HEADER : AUDIT_SHEET_HEADER;

  const hotSettings = {
    data: sheet?.data,
    height,
    colHeaders: localHeader.map((header, i) => {
      
      const fontSize = getScaledHeaderFontSize(scale);
      return `<div style="font-size: ${fontSize};"class="header">${header}</div>`;
    }),
    licenseKey: 'non-commercial-and-evaluation',
    autoWrapRow: true,
    contextMenu: {
      async callback(key, selection, clickEvent) {
        // Common callback for all options
        onCommentClick(
          selection,
          { pageX: clickEvent.screenX, pageY: clickEvent.screenY },
          key === 'comments',
          false,
          columnData,
          hotRef.current?.hotInstance.getSelected()[0]
        );
        if (key === 'comments') {
          const { row, column } = getRowColArray(selection[0], sheet, batch);
          const colIndex=selection[0].start.col
          const rowIndex=selection[0].start.row
          const selectedColumnName = columnData[colIndex]?.data;
          await getComments({
            batchId,
            uploadId,
            row,
            column:selectedColumnName,
          });
        }
      },
      items: {
        row_above: {
          name: () => '<p>Insert row above</p>',
          disabled() {
            return view;
          },
        },
        row_below: {
          disabled() {
            return view;
          },
        },
        remove_row: {
          disabled() {
            return view;
          },
        },
        cut: {
          disabled() {
            return view;
          },
        },
        copy: {
          disabled() {
            return view;
          },
        },
        undo: {
          disabled() {
            return view;
          },
        },
        comments: {
          name: 'Comments',
          hidden() {
            // `hidden` can be a boolean or a function
            // Hide the option when the first column was clicked
            const selectedIndex = this.getSelectedLast()[2];
            const selectedColumn = this.getSelectedLast()[1];
            //hide comment for file id
            return !sheet.data[selectedIndex].id || selectedColumn === 0; // `this` === hot
          },
        },
      },
    },
    beforeChange: onBeforeHotChange,

    className: 'headerContainer',

    beforeKeyDown: function (e) {
      let col = this.getSelectedLast()[1];
      var evt = e || window.event; // IE support
      var key = evt.charCode || evt.keyCode || 0;

      // check for cut and paste
      var isClipboard = false;
      var ctrlDown = evt.ctrlKey || evt.metaKey; // Mac support

      // Check for Alt+Gr (http://en.wikipedia.org/wiki/AltGr_key)
      if (ctrlDown && evt.altKey) isClipboard = false;
      // Check for ctrl+c, v and x
      else if (ctrlDown && key === 67) isClipboard = true; // c
      else if (ctrlDown && key === 86) isClipboard = true; // v
      else if (ctrlDown && key === 88) isClipboard = true; // x
      else if (ctrlDown && evt.key === 'z') isClipboard = true; //z

      switch (col) {
        case 0:
        case 4:
        case 5:
        case 10:
        case 11:
          var isNumeric =
            NUMERIC_KEYS.includes(key) ||
            (key >= 35 && key <= 40) ||
            (key >= 48 && key <= 57) ||
            (key >= 96 && key <= 105);

          if ((!isNumeric && !isClipboard) || e.shiftKey) {
            // prevent alpha characters
            e.stopImmediatePropagation();
            e.preventDefault();
          }
          break;
        case 2:
          var number =
            NUMERIC_KEYS.includes(key) ||
            (key >= 35 && key <= 40) ||
            (key >= 48 && key <= 57) ||
            (key >= 96 && key <= 105);

          if ((!number && !isClipboard) || e.shiftKey) {
            // prevent alpha characters
            e.stopImmediatePropagation();
            e.preventDefault();
          }
          break;
        default:
          break;
      }
    },

    outsideClickDeselects: false,

    //batch ? BATCH_RESPONSE_AS_COL : RESPONSE_AS_COL
    afterOnCellMouseDown: (e, p) => {
      closeComment();
      // columnData[selection[0]?.start.col]
      const rowId = sheet.data[p.row].id;
      if (p.col === responseColumn && rowId) {
        onCommentClick(
          [
            {
              start: { row: p.row, col: p.col },
              end: { row: p.row, col: p.col },
            },
          ],
          { pageX: e.screenX + 100, pageY: e.screenY + 100 },
          false,
          true,
          columnData,
          hotRef.current?.hotInstance.getSelected()[0]
        );
      } else {
        onCommentClick(
          [
            {
              start: { row: p.row, col: p.col },
              end: { row: p.row, col: p.col },
            },
          ],
          { pageX: e.screenX + 100, pageY: e.screenY + 100 },
          false,
          false,
          columnData,
          hotRef.current?.hotInstance.getSelected()[0]
        );
      }
    },

    cells: function (row, col, prop) {
      var cellProperties = {};
      let COL = highlightedColumn; // [4, 5, 10, 11]
      if (batch) {
        COL = highlightedColumn; // [5, 6, 11, 12]
      }
      if (COL.includes(col)) {
        cellProperties.renderer = columnHighlight; // uses lookup map
        return cellProperties;
      }
      if (hasComment(row, col)) {
        cellProperties.renderer = commentCell; // uses comment map
        return cellProperties;
      }

      return cellProperties;
    },
  };

  const batchColumnData = [
    {
      data: 'file_name',
      type: 'text',
      width: windowSize * 0.06,
      alignment: 'center',
      readOnly: true,
      renderer: 'customStylesRenderer',
    },
    {
      data: 'encounter_no',
      type: 'numeric',
      width: windowSize * 0.06,
      alignment: 'center',
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'rendering',
      type: 'text',
      width: windowSize * 0.06,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'enc_dt',
      type: 'date',
      dateFormat: 'MM-DD-YYYY',
      width: windowSize * 0.06,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'srvcs_no',
      type: 'text',
      width: windowSize * 0.06,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'provider_rvu',
      type: 'numeric',
      width: windowSize * 0.06,
      renderer: 'customStylesRenderer',

      readOnly: view,
    },
    {
      data: 'provider_dollar_value',
      type: 'audit_sheet.currency_renderer',
      width: windowSize * 0.05,
      readOnly: view || isProviderDollarDisabled,
    },
    {
      data: 'response',
      type: 'text',
      width: windowSize * 0.11,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'agree',
      type: 'text',
      validator: agreeValidator,
      width: windowSize * 0.07,
      renderer: 'agreeRenderer',
      readOnly: view,
    },
    {
      data: 'disagree',
      type: 'text',
      validator: agreeValidator,
      width: windowSize * 0.07,
      renderer: 'agreeRenderer',
      readOnly: view,
    },
    {
      data: 'audited_code',
      type: 'text',
      width: windowSize * 0.07,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
    {
      data: 'audited_rvu',
      type: 'numeric',
      width: windowSize * 0.07,
      renderer: 'customStylesRenderer',

      readOnly: view,
    },
    {
      data: 'audited_dollar_value',
      width: windowSize * 0.07,
      type: 'audit_sheet.currency_renderer',
      readOnly: view || isAuditedDollarDisabled,
    },
    {
      data: 'notes',
      type: 'text',
      width: windowSize * 0.14,
      renderer: 'customStylesRenderer',
      readOnly: view,
    },
  ];

  const auditSheetColumnData = batch ? batchColumnData : _columnData;

  return (
    <HotTable
      ref={hotRef}
      columns={columnData} //auditSheetColumnData
      data={sheetData}
      settings={{
        ...hotSettings,
        rowHeights: getScaledRowHeight(scale),
        stretchH: 'all',
        autoColumnSize: false,
        autoRowSize: false,
        background: 'white',
        // hiddenColumns: {
        //   columns: [...hiddenColumns],
        // },
      }}
    ></HotTable>
  );
};
export default AuditSheet;
