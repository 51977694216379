const ROUTES = {
  // AUTH
  ROOT_SCREEN: '/',
  LOGIN_SCREEN: '/auth/login',
  FORGOT_PASSWORD_SCREEN: '/auth/forgot-password',
  CONFIRMATION_SCREEN: '/auth/forgot-password-message',
  RESET_PASSWORD_SCREEN: '/auth/reset-password',
  RESET_CONFIRMATION_SCREEN: '/auth/reset-password-message',
  REGISTER: '/client/register/',
  ON_BOARDING: '/client/onboarding',
  ON_BOARDING_PROVIDER: '/client/onboarding/provider',

  // PRIVATE ROUTES
  DASHBOARD: '/client/dashboard',
  UPLOAD: '/client/upload',
  MY_CHARTS: '/client/my-charts',
  HELP: '/client/help',
  SINGLE_AUDIT_SHEET: '/audit/sheet/:uploadId/view',
  VIEW_AUDIT_SHEET: '/audit/sheet/batch/:batchId/view',

  CLIENT_PHYSICIAN_GROUP: '/client/register/physicians-group',

  //hospital Dashboard
  HOSPITAL_DASHBOARD: '/client/hospitals/:hospitalId/dashboard',
  CHARTS: '/client/charts',

  // department dashboard
  DEPARTMENT_DASHBOARD:
    '/client/hospitals/:hospitalId/department/:departmentId/dashboard/',
  MY_ACCOUNT: `client/my-accounts`,
  PRACTICE_INFO: `client/practice-info`,

  // physician dashboard
  PHYSICIAN_DASHBOARD:
    '/client/hospitals/:hospitalId/department/:departmentId/physicians/:physicianId/dashboard',

  BATCH_DASHBOARD: '/client/batch/:batchId/dashboard/',
  //hospital usertype
  HOSPITAL_DEPARTMENT_DASHBOARD:
    '/client/dashboard/department/:departmentId/dashboard/',
  HOSPITAL_PHYSICIAN_DASHBOARD:
    '/client/dashboard/department/:departmentId/physicians/:physicianId/dashboard/',

  // department usertype
  DEPARTMENT_PHYSICIAN_DASHBOARD:
    '/client/dashboard/physicians/:physicianId/dashboard/',

  NOTIFICATIONS: '/client/notification',
};

export default ROUTES;
export const PUBLIC_PATH = [
  ROUTES.LOGIN_SCREEN,
  // ROUTES.ROOT_SCREEN,
  ROUTES.FORGOT_PASSWORD_SCREEN,
  ROUTES.CONFIRMATION_SCREEN,
  ROUTES.RESET_PASSWORD_SCREEN,
  ROUTES.RESET_CONFIRMATION_SCREEN,
  ROUTES.CLIENT_PHYSICIAN_GROUP,
  ROUTES.REGISTER,
];

export const UNAPPROVED_PAGE = [
  ROUTES.PRACTICE_INFO,
  ROUTES.MY_ACCOUNT,
  ROUTES.HELP
]
