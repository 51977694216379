import { format, parseISO } from "date-fns"
import { utcToZonedTime } from "date-fns-tz"

/* eslint-disable array-callback-return */
const getChartData = (filter, chartData, unitType, previousChartData, showComparison) => {
    const data = []
    const label = []
    const comparisonLabel = []
    const dateInUTC = date => utcToZonedTime(new Date(date));

    const WEEK = ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
    const MONTHS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec']
    switch (filter.toLowerCase()) {
        case 'day':
            Object.keys(chartData).map((key) => {
                if (chartData[key]) {
                    data.push({
                        x: key,
                        y: chartData[key]
                    })
                }
            })
            return { data: chartData, label }
        case 'week':
            if (Object.keys(chartData).length) {
                Object.keys(chartData).map((key) => {
                    if (chartData[key]) {
                        label.push(WEEK[key])
                        data.push(chartData[key])
                    }
                })
            }
            return { label, data }
        case 'month':
            if (Object.keys(chartData).length) {
                Object.keys(chartData).map((key, index) => {
                    if (chartData[key]) {
                        label.push(format(dateInUTC(key), 'MMM dd'))
                        data.push(chartData[key])
                    }
                })
            }
            return { data, label }
        case 'year':
            if (Object.keys(chartData).length) {
                Object.keys(chartData).map((key) => {
                    if (chartData[key]) {
                        const dateString = key;
                        const dateObject =  new Date(dateString);
                        const monthName = format(dateObject,  "MMM ''yy");
                        label.push(monthName)
                        data.push(chartData[key])
                    }
                })
            }
            return { label, data }
        case 'custom':
            if (Object.keys(chartData).length) {
                if (unitType === 'days') {
                    Object.keys(chartData).map((key, index) => {
                        if (chartData[key]) {
                            if (previousChartData) {
                                const temp = Object.keys(previousChartData)[index]
                                if (showComparison) {
                                    if (previousChartData[temp]) {
                                        comparisonLabel.push(format(new Date(temp), 'MMM dd, `yy'))
                                    }
                                }
                            }
                            label.push(format(dateInUTC(key), 'MMM dd, `yy'))
                            data.push(chartData[key])
                        }
                    })
                } else if (unitType === 'weeks') {
                    Object.keys(chartData).map((key, index) => {
                        if (chartData[key]) {
                            const arr = key?.split(':')
                            // data.push({
                            //     x: `${format(new Date(arr[0]), 'MMM dd, `yy')} - ${format(new Date(arr[1]), 'MMM dd, `yy')}`,
                            //     y: chartData[key]
                            // })
                            if (previousChartData) {
                                const temp = Object.keys(previousChartData)[index]
                                const arr2 = temp?.split(':')

                                if (showComparison) {
                                    if (previousChartData[temp]) {
                                        comparisonLabel.push(`${format(new Date(arr2[0]), 'MMM dd, `yy')} ${arr2[1] ? '-' + format(new Date(arr2[1]), 'MMM dd, `yy') : ''}`)
                                    }
                                }
                            }
                            label.push(`${format(dateInUTC(arr[0]), 'MMM dd, `yy')} ${arr[1] ? '-' + format(dateInUTC(arr[1]), 'MMM dd, `yy') : ''}`)
                            data.push(chartData[key])
                        }
                    })
                }
                else if (unitType === 'months') {
                    Object.keys(chartData).map((key, index) => {
                        if (chartData[key]) {
                            const arr = key?.split(':')
                            // data.push({
                            //     x: `${format(new Date(arr[0]), 'MMM dd, `yy')} - ${format(new Date(arr[1]), 'MMM dd, `yy')}`,
                            //     y: chartData[key]
                            // })
                            if (previousChartData) {
                                const temp = Object.keys(previousChartData)[index]
                                const arr2 = temp?.split(':')

                                if (showComparison) {
                                    if (previousChartData[temp]) {
                                        comparisonLabel.push(`${format(new Date(arr2[0]), 'MMM `yy')} ${arr2[1] ? '-' + format(new Date(arr2[1]), 'MMM `yy') : ''}`)
                                    }
                                }
                            }
                            label.push(`${format(dateInUTC(arr[0]), 'MMM `yy')} ${arr[1] ? '-' + format(dateInUTC(arr[1]), 'MMM `yy') : ''}`)
                            data.push(chartData[key])
                        }
                    })
                }
                else if (unitType === 'year') {
                    Object.keys(chartData).map((key, index) => {
                        if (chartData[key]) {
                            // data.push({
                            //     x: `${format(new Date(arr[0]), 'MMM dd, `yy')} - ${format(new Date(arr[1]), 'MMM dd, `yy')}`,
                            //     y: chartData[key]
                            // })
                            if (previousChartData) {
                                const temp = Object.keys(previousChartData)[index]

                                if (showComparison) {
                                    if (previousChartData[temp]) {
                                        comparisonLabel.push(format(new Date(temp), 'YYYY'))
                                    }
                                }
                            }
                            label.push(format(dateInUTC(key), 'YYYY'))
                            data.push(chartData[key])
                        }
                    })
                } else {
                    Object.keys(chartData).map((key, index) => {
                        if (chartData[key]) {
                            const arr = key?.split(':')
                            // data.push({
                            //     x: `${format(new Date(arr[0]), 'MMM dd, `yy')} - ${format(new Date(arr[1]), 'MMM dd, `yy')}`,
                            //     y: chartData[key]
                            // })
                            if (previousChartData) {
                                const temp = Object.keys(previousChartData)[index]
                                const arr2 = temp?.split(':')

                                if (showComparison) {
                                    if (previousChartData[temp]) {
                                        comparisonLabel.push(`${format(new Date(arr2[0]), 'MMM `yy')} - ${arr2[1] ? '-' + format(new Date(arr2[1]), 'MMM `yy') : ''}`)
                                    }
                                }
                            }
                            label.push(`${format(dateInUTC(arr[0]), 'MMM `yy')} - ${arr[1] ? '-' + format(dateInUTC(arr[1]), 'MMM `yy') : ''}`)
                            data.push(chartData[key])
                        }
                    })
                }
            }
            return { label, data, comparisonLabel }

        default: return {}

    }
}
export { getChartData }