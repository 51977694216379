/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useMemo, useState } from 'react';
import { Box, HStack, Text, Tooltip } from '@chakra-ui/react';
import {
  CQReactTable,
  PrimaryButton,
  useBoolean,
} from '@prasanna-transcend/code-quick-components';

import { createColumnHelper } from '@tanstack/react-table';
import { format } from 'date-fns';
// import '../style.css';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../../utils/my_audits.utils';
import { MyChartContext } from '../../../../providers/MyChartProvider';
import AppColors from '../../../../constants/AppColors';
import { EyeAvatarIcon } from '../../../../constants/IconData';
import { STATUS, UPLOAD_BREAKDOWN_STATUS } from '../../../../constants/constants';
import { useNavigate } from 'react-router-dom';
import ROUTES from '../../../../constants/Routes';
import BatchFileModal from '../../../dashboard/components/BatchFileModal';
import { priceConverter } from '../../../../utils/priceConverter';
import { replaceRoute } from '../../../../utils/common.utils';

const columnHelper = createColumnHelper();

const AllCharts = () => {
  const myChartContext = useContext(MyChartContext);
  const [ordering, setOrdering] = useState('');
  const { allCharts, isAllChartLoading, getAllCharts, filterParams } =
    myChartContext;
  const navigate = useNavigate();
  const [currentBatch, setCurrentBatch] = useState({});
  const [isModalOpen, imoState] = useBoolean(false);

  useEffect(() => {
    getAllCharts.loadAPI();
  }, [filterParams, getAllCharts.showOrdering]);

  useEffect(() => {
    getAllCharts.setParams({ ordering });
  }, [ordering]);

  const columns = useMemo(
    () => [
      columnHelper.accessor('upload_id', {
        header: () => 'Upload #',
        id: 'upload_id',
        cell: info => {
          const chartData = info.row.original;
          const cellValue = info.getValue();
          const isReceived = chartData.status === UPLOAD_BREAKDOWN_STATUS.RECEIVED;
          if (isReceived) {
            return (
              <Text
                _hover={{ textDecoration: 'underline' }}
                cursor={'pointer'}
                onClick={() => {
                  const batchDashboard = replaceRoute(ROUTES.BATCH_DASHBOARD, {
                    batchId: cellValue,
                  });
                  navigate(batchDashboard, {
                    state: { chartData: chartData },
                  });
                }}
              >
                {info.renderValue()}
              </Text>
            );
          }
          return (
            <Tooltip
              hasArrow
              backgroundColor={AppColors.secondary}
              label="Batch is in processing"
              placement="bottom"
            >
              <Text cursor={'pointer'}> {info.renderValue()}</Text>
            </Tooltip>
          );
        },
      }),
      columnHelper.accessor('cq_score', {
        id: 'cq_score',
        header: () => <span>RI Score</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor(row => row.upload_date, {
        id: 'upload_date',
        header: () => <span>Upload Date</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return format(new Date(cellValue), 'MM/dd/yyyy');
        },
      }),
      columnHelper.accessor('status', {
        header: () => 'Status',
        id: 'status',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return STATUS[cellValue];
        },
      }),
      columnHelper.accessor('opporturnity', {
        header: () => 'Opportunity',
        id: 'opporturnity',
        cell: info => {
          const chartData = info.row.original;
          const isReceived = chartData.status === UPLOAD_BREAKDOWN_STATUS.RECEIVED;
          const isProcessing = chartData.status === UPLOAD_BREAKDOWN_STATUS.PROCESSING;
          const cellValue = info.getValue();
          if (isProcessing && Number(cellValue) === 0) {
            return <Text color={AppColors.gray}>N/A</Text>;
          } else if (isReceived && Number(cellValue) > 0) {
            return <Text color={'#77C586'}>{priceConverter(cellValue)}</Text>;
          } else if (isReceived && Number(cellValue) === 0) {
            return <Text color={'#77C586'}>$0</Text>;
          } else if (isReceived && Number(cellValue) < 0) {
            return <Text color={'red'}>{`-${priceConverter(-cellValue)}`}</Text>;
          }
        },
      }),
      columnHelper.accessor('charts', {
        header: () => 'No. of Charts',
        id: 'charts',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('urgency', {
        header: () => 'Urgency',
        id: 'urgency',
        cell: info => {
          const cellValue = info.getValue();
          return cellValue ?? <Text color={AppColors.gray}>N/A</Text>;
        },
      }),

      columnHelper.accessor(row => row.upload_id, {
        id: 'actions',
        header: () => '',
        enableSorting: false,
        cell: info => {
          const cellValue = info.getValue();

          return (
            <HStack justifyContent={'center'}>
              <PrimaryButton
                bg={AppColors.secondary}
                fontSize={'14px'}
                fontWeight="normal"
                size="md"
                borderRadius="3xl"
                px="10"
                onClick={() => {
                  navigate(
                    ROUTES.VIEW_AUDIT_SHEET.replace(':batchId', cellValue)
                  );
                }}
                _hover={{
                  transition: 'all .1s ease',
                  bgColor: AppColors.white,
                  color: AppColors.secondary,
                  outline: `2px solid ${AppColors.secondary}`,
                }}
              >
                View
              </PrimaryButton>
              <EyeAvatarIcon
                style={{ cursor: 'pointer' }}
                onClick={() => {
                  setCurrentBatch(info.row.original);
                  imoState.on();
                }}
              />
            </HStack>
          );
        },
      }),
    ],
    [isAllChartLoading]
  );

  function handlePreviousPage() {
    getAllCharts.loadPrevious();
  }

  function handleNextPage() {
    getAllCharts.loadNext();
  }

  function handleSizeChange(size) {
    getAllCharts({ size });
  }

  const getTableData = async () => {
    const res = await myChartContext.getFileObjectByBatchId(
      currentBatch.upload_id
    );
    return res;
  };

  return (
    <Box>
      <CQReactTable
        isLoading={isAllChartLoading}
        data={allCharts}
        columns={columns}
        {...defaultReactTableProp}
        {...paginationProps(getAllCharts)}
        onPreviousPage={handlePreviousPage}
        onNextPage={handleNextPage}
        onSizeChange={handleSizeChange}
        onAsc={header => {
          setOrdering(header);
        }}
        onDesc={header => {
          setOrdering(`-${header}`);
        }}
      />
      <BatchFileModal
        isOpen={isModalOpen}
        onClose={imoState.off}
        currentBatch={currentBatch}
        getTableData={getTableData}
        isLoading={myChartContext.isFileLoading}
      />
    </Box>
  );
};
export default AllCharts;
