/* eslint-disable react-hooks/exhaustive-deps */
import { SearchIcon } from '@chakra-ui/icons';
import { Box, Button, Center, HStack, Text } from '@chakra-ui/react';
import {
  CQReactTable,
  Heading,
  TextInput,
} from '@prasanna-transcend/code-quick-components';
import { createColumnHelper } from '@tanstack/react-table';
import { useContext, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AppColors from '../../../constants/AppColors';
import ROUTES from '../../../constants/Routes';
import { FONT_FAMILY } from '../../../constants/Theme';
import { AuthContext } from '../../../providers/AuthProvider';
import { DashboardContext } from '../../../providers/DashboardProvider';
import { replaceRoute } from '../../../utils/common.utils';
import { getCurrentUserTypeId } from '../../../utils/getCurrentUserTypeId';
import {
  defaultReactTableProp,
  paginationProps,
} from '../../../utils/my_audits.utils';
import { priceConverter } from '../../../utils/priceConverter';

const HospitalsTable = () => {
  const dashboardContext = useContext(DashboardContext);
  const { hospitals } = dashboardContext;
  const columnHelper = createColumnHelper();
  const navigate = useNavigate();

  const [searchText, setSearchText] = useState('');
  const [ordering, setOrdering] = useState('');
  const authContext = useContext(AuthContext);
  const onSearchAction = () => {
    dashboardContext.setHospitalFilter(prev => ({
      ...prev,
      search: searchText,
    }));
  };

  const onFilterChange = e => {
    if (e) {
      dashboardContext.getHospitals.setParams({ page: 1 });
      setSearchText(e.target.value);
    } else {
      setSearchText('');
    }
  };

  useEffect(() => {
    const obj = getCurrentUserTypeId(authContext.currentUser);
    dashboardContext.setHospitalFilter({
      ...dashboardContext.hospitalFilter,
      ...obj,
    });
  }, [authContext.currentUser]);

  useEffect(() => {
    if (dashboardContext.hospitalFilter.health_system_id) {
      dashboardContext.getHospitals.loadAPI();
    }
  }, [dashboardContext.hospitalFilter]);

  useEffect(() => {
    dashboardContext.getHospitals.setParams({ ordering });
  }, [ordering]);

  const getTableCellProps = (cell, data) => {
    const startingHeaderId = ['name'];

    return {
      fontFamily: FONT_FAMILY.ptSans,
      textAlign: startingHeaderId.includes(data.column.id) ? 'none' : 'center',
      fontWeight: 'bold',
      color: AppColors.secondary,
    };
  };

  function getHeaderCellProps(header) {
    const startingHeaderId = ['name'];
    return {
      textTransform: 'none',
      fontFamily: FONT_FAMILY.ptSans,
      fontWeight: 'bold',
      color: AppColors.black,
      justifyContent: startingHeaderId.includes(header.id)
        ? 'flex-start'
        : 'center',
    };
  }

  const columns = useMemo(
    () => [
      columnHelper.accessor('name', {
        header: () => 'Name',
        id: 'name',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }
          return <Text>{cellValue}</Text>;
        },
      }),
      columnHelper.accessor('active_audits', {
        id: 'active_audits',
        header: () => <span>Active Audits</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('chart_accuracy', {
        id: 'chart_accuracy',
        header: () => <span>Chart Accuracy</span>,
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return cellValue;
        },
      }),
      columnHelper.accessor('cq_rev_opp', {
        header: () => 'RI Rev. Opp',
        id: 'cq_rev_opp',
        cell: info => {
          const cellValue = info.getValue();
          if (!cellValue) {
            return <Text color={AppColors.gray}>N/A</Text>;
          }

          return priceConverter(cellValue);
        },
      }),
      columnHelper.accessor('', {
        header: () => 'Action',
        id: 'action',
        cell: info => {
          return (
            <Button
              size={'sm'}
              bg={AppColors.secondary}
              color={'white'}
              borderRadius="3xl"
              px={10}
              fontWeight="normal"
              fontSize={'sm'}
              onClick={() => {
                const hospitalId = info.row.original.id;
                const HOSPITAL_DASHBOARD = replaceRoute(
                  ROUTES.HOSPITAL_DASHBOARD,
                  {
                    hospitalId: hospitalId,
                  }
                );
                navigate(HOSPITAL_DASHBOARD);
              }}
              _hover={{
                transition: 'all .1s ease',
                bgColor: AppColors.white,
                color: AppColors.secondary,
                outline: `2px solid ${AppColors.secondary}`,
              }}
            >
              View
            </Button>
          );
        },
      }),
    ],
    [dashboardContext.isHospitalLoading]
  );

  function handlePreviousPage() {
    dashboardContext.getHospitals.loadPrevious();
  }

  function handleNextPage() {
    dashboardContext.getHospitals.loadNext();
  }

  function handleSizeChange(size) {
    dashboardContext.getHospitals({ size });
  }

  return (
    <Box>
      <HStack justifyContent={'space-between'}>
        <Heading variant="h6" fontFamily={FONT_FAMILY.ptSans} fontWeight="bold">
          HOSPITALS
        </Heading>
        <Box w={'sm'}>
          <TextInput
            borderRadius={'md'}
            value={searchText}
            onChange={e => onFilterChange(e)}
            onKeyDown={e => {
              if (e.keyCode === 13) {
                onSearchAction();
              }
            }}
            rightIconProps={{ zIndex: 0 }}
            placeholder="Search Hospitals"
            style={{ fontFamily: FONT_FAMILY.ptSans }}
            fontSize="md"
            rightIcon={
              <SearchIcon onClick={onSearchAction} color={AppColors.gray} />
            }
          />
        </Box>
      </HStack>
      <Box mt={5} p={5} bg={'white'} borderRadius="md">
        <CQReactTable
          isLoading={dashboardContext.isHospitalLoading}
          data={hospitals}
          columns={columns}
          {...defaultReactTableProp}
          {...paginationProps(dashboardContext.getHospitals)}
          // getRowProps={() => { }}
          getHeaderCellProps={header => {
            return {
              ...getHeaderCellProps(header),
            };
          }}
          getCellProps={getTableCellProps}
          onPreviousPage={handlePreviousPage}
          onNextPage={handleNextPage}
          onSizeChange={handleSizeChange}
          onAsc={header => {
            setOrdering(header);
          }}
          onDesc={header => {
            setOrdering(`-${header}`);
          }}
        />
        {!hospitals?.length ? (
          <Center mt={10} fontFamily={FONT_FAMILY.ptSans}>
            {' '}
            No data available
          </Center>
        ) : null}
      </Box>
    </Box>
  );
};
export default HospitalsTable;
