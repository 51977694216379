import { format } from 'date-fns';
import {
  DEPARTMENT_TYPE,
  HEALTH_SYSTEM_TYPE,
  HOSPITAL_TYPE,
  PHYSICIAN_GROUP_TYPE,
  PROVIDER_TYPE,
} from '../constants/constants';

export function toTitleCase(str) {
  const transformedStr = str
    .split(' ')
    .map(w => (w[0] ? w[0].toUpperCase() + w.substring(1).toLowerCase() : null))
    .join(' ');

  return transformedStr;
}

export function replaceRoute(route, params, delimiter = ':') {
  const keys = Object.keys(params);
  for (let key of keys) {
    route = route.replace(delimiter + key, params[key]);
  }
  return route;
}

export function dateFormat(dates, formatType = 'MM/dd/yyyy') {
  if (dates) {
    return format(dates, formatType);
  }
}

export function setFilters(
  variable = {},
  prevStDate,
  prevEndDate,
  startDate,
  endDate
) {
  variable.previous_start_date = startDate;
  variable.previous_end_date = endDate;
  variable.start_date = prevEndDate;
  variable.end_date = prevStDate;
}

export function convertToPositiveDollar(num) {
  const number = Number(num);
  if (typeof num === 'string' && num.startsWith('-$')) {
    return num.substring(1);
  } else if (isNaN(number)) {
    return num;
  } else {
    const positiveNumber = Math.abs(number);
    return positiveNumber;
  }
}

export const getUserType = currentUser => {
  const filterParams = {};

  if (currentUser?.user_type === HEALTH_SYSTEM_TYPE) {
    filterParams['health_system_id'] = currentUser?.health_system_id?.id;
  } else if (currentUser?.user_type === HOSPITAL_TYPE) {
    filterParams['hospital_id'] = currentUser?.hospital_id?.id;
  } else if (currentUser?.user_type === DEPARTMENT_TYPE) {
    filterParams['department_id'] = currentUser?.department_id?.id;
  } else if (currentUser?.user_type === PROVIDER_TYPE) {
    filterParams['provider_id'] = currentUser?.provider_id?.providers__id;
  }else if (currentUser?.user_type === PHYSICIAN_GROUP_TYPE) {
    filterParams['hospital_id'] = currentUser?.hospital_id?.id;
    filterParams['health_system_id'] = currentUser?.physicians_group_id?.id;
  }
  return filterParams;
};
