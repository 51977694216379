import { useBoolean } from '@chakra-ui/react';
import React, { createContext, useState } from 'react';
import dashboardRepository from '../repositories/DashboardRepository';
import departmentDashboardRepository from '../repositories/DepartmentRepository';

export const DepartmentContext = createContext({

    getRVUOpp: async () => { },
    rvuOpp: {},
    isRvuLoading: false,
    getAIRisk: async () => { },
    aiRisk: {},
    isAIRiskLoading: false,
    error: {}
});

function DepartmentProvider(props) {
    const [rvuOpp, setRvuOpp] = React.useState({});
    const [isRvuLoading, iRVUState] = useBoolean(false)
    const [isAIRiskLoading, iarlState] = useBoolean(false)

    const [aiRisk, setAIRisk] = useState({})
    const [error, setError] = useState({
        TOTAL_UPLOADS: '',
        CQ_SCORE: '',
        RVU_OPPORTUNITY: '',
        AI_RISK: ''
    })
    const getRVUOpp = async (filterParams) => {
        try {
            iRVUState.on()
            const response = await departmentDashboardRepository.getRevenueOpportunity(
                filterParams
            );
            setRvuOpp(response);
        } catch (err) {
            const response = err.cause;
            if (response.message) {
                setError(prev => ({ ...prev, RVU_OPPORTUNITY: response.message }))
            }
        } finally {
            iRVUState.off()
        }
    }

    const getAIRisk = async (filter) => {
        try {
            iarlState.on();
            const response = await dashboardRepository.getAIRisk(filter);
            setAIRisk({ ...response, label: 'Physicians' })
        } catch (err) {
            const response = err.cause;
            if (response.message) {
                setError(prev => ({ ...prev, AI_RISK: response.message }))
            }
        } finally {
            iarlState.off();
        }
    }

    const mContext = {
        getRVUOpp,
        rvuOpp,
        isRvuLoading,
        getAIRisk,
        aiRisk,
        isAIRiskLoading,
        error
    };
    return (
        <DepartmentContext.Provider value={mContext}>
            {props.children}
        </DepartmentContext.Provider>
    );
}

export default DepartmentProvider;
