import { API } from '../config/AppConfig';
import {
  CHART_ACCURACY_BREAKDOWN,
  PHYSICIAN_CQ_SCORE,
  PHYSICIAN_STATISTICS,
  RVU_OPP,
  TOTAL_UPLOADS,
} from '../constants/Endpoints';

class PhysicianRepository {
  async getPhysician(queryParams, departmentId) {
    const response = await API.get(PHYSICIAN_STATISTICS, {
      queryParams,
    });
    return response;
  }
  async getPhysicianCQScore(queryParams) {
    const response = await API.get(PHYSICIAN_CQ_SCORE, { queryParams });
    return response;
  }

  async getAuditChartAccuracy(queryParams) {
    const response = await API.get(CHART_ACCURACY_BREAKDOWN, {
      queryParams,
    });
    return response;
  }

  async getTotalUploadsData(queryParams) {
    const response = await API.get(TOTAL_UPLOADS, {
      queryParams,
    });
    return response;
  }

  async getPhysicianCQscore(queryParams) {
    const response = await API.get(PHYSICIAN_CQ_SCORE, {
      queryParams,
    });
    return response;
  }

  async getRUVoppurtunity(queryParams) {
    const response = await API.get(RVU_OPP, {
      queryParams,
    });
    return response;
  }
}

const physicianRepository = new PhysicianRepository();
export default physicianRepository;
