import {
  useBoolean,
  useToastr,
} from '@prasanna-transcend/code-quick-components';
import React, { createContext, useState } from 'react';
import dashboardRepository from '../repositories/DashboardRepository';
import hospitalDashboardRepository from '../repositories/HospitalRepository';

export const HospitalDashboardContext = createContext({
  // async functions
  getHospital: async hospitalId => { },

  // states
  hospital: {},

  // booleans
  isFetchingHospital: false,
  getRVUOpp: async () => { },
  rvuOpp: {},
  isRvuLoading: false,
  getAIRisk: async () => { },
  aiRisk: {},
  isAIRiskLoading: false,
  error: {}
});

function HospitalProvider(props) {
  const toast = useToastr();

  const [hospital, setHospital] = useState({});
  const [isFetchingHospital, ifhState] = useBoolean(false);
  const [rvuOpp, setRvuOpp] = React.useState({});
  const [isRvuLoading, iRVUState] = useBoolean(false)
  const [isAIRiskLoading, iarlState] = useBoolean(false)

  const [aiRisk, setAIRisk] = useState({})
  const [error, setError] = useState({
    TOTAL_UPLOADS: '',
    CQ_SCORE: '',
    RVU_OPPORTUNITY: '',
    AI_RISK: ''
  })

  const getHospital = async hospitalId => {
    try {
      ifhState.on();
      const response =
        await hospitalDashboardRepository.getHospitalForDashboard(hospitalId);
      setHospital(response);
      return response;
    } catch (err) {
      toast.showError({
        description: `Something went wrong`,
      });
    } finally {
      ifhState.off();
    }
  };

  const getRVUOpp = async (filterParams) => {
    try {
      iRVUState.on()
      const response = await hospitalDashboardRepository.getRevenueOpportunity(
        filterParams
      );
      setRvuOpp(response);
    } catch (err) {
      // console.log(err)
    } finally {
      iRVUState.off()
    }
  }

  const getAIRisk = async (filter) => {
    try {
      iarlState.on();
      const response = await dashboardRepository.getAIRisk(filter);
      setAIRisk({ ...response, label: 'Departments' })
    } catch (err) {
      const response = err.cause;
      if (response.message) {
        setError(prev => ({ ...prev, AI_RISK: response.message }))
      }
    } finally {
      iarlState.off();
    }
  }

  const mContext = {
    getHospital,
    hospital,
    isFetchingHospital,
    getRVUOpp,
    rvuOpp,
    isRvuLoading,
    getAIRisk,
    aiRisk,
    isAIRiskLoading,
    error
  };

  return (
    <HospitalDashboardContext.Provider value={mContext}>
      {props.children}
    </HospitalDashboardContext.Provider>
  );
}

export default HospitalProvider;
