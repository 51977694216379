import {
  addionalAttributesKeys,
  auditedRuvColumns,
  defaultColumns,
  defaultDataProps,
  firstSetColumnKeyOrder,
  rvuColumnsOrder,
} from '../constants/constants';
import {
  columnWidth,
  dynamicAuditColumnHeader,
  columnType,
  AUDIT_COLUMN_HEADER,
} from '../pages/audit_sheet/constants';
import { toTitleCase } from './common.utils';

/**
 *
 * @param { Array } sheetData
 * @param { Number } windowSize
 * @param { Boolean } view
 * @param { Array } providerOptions
 * @param { Boolean } isAuditedDollarDisabled
 * @param { Boolean } isProviderDollarDisabled
 *
 */

// agree and disAgree  column validator functions
function agreeValidator(value, callback) {
  setTimeout(() => {
    if (value.toLowerCase() === 'yes' || value === '-') {
      callback(true);
    } else {
      callback(false);
    }
  }, 1000);
}

/**
 *
 * @param {String} key
 * @param {Number} windowSize
 * @param {Boolean} view
 * @returns
 */

function prepareBilledColumn(key, windowSize, view) {
  if (key.match('billed_cpt')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else if (key.match('billed_modifier')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else {
    return;
  }
}

/**
 *
 * @param {String} key
 * @param {Number} windowSize
 * @param {Boolean} view
 * @returns
 */

function prepareAuditedColumn(key, windowSize, view) {
  if (key.match('audited_icd')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.18,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else if (key.match('audited_cpt')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.19,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else if (key.match('audited_modifier')) {
    return {
      data: key,
      type: 'text',
      width: windowSize * 0.2,
      readOnly: view,
      renderer: 'customStylesRenderer',
    };
  } else {
    return;
  }
}

/**
 *
 * @param {String} key
 * @param {Number} windowSize
 * @param {Boolean} view
 * @returns
 */

function prepareSrvcColumn(key, windowSize, view) {
  return {
    data: key,
    type: 'text',
    width: windowSize * 0.15,
    readOnly: view,
    renderer: 'customStylesRenderer',
  };
}

function arrangeColumns(columnsData) {
  const firstSetColumns = [];

  const billedIctColumns = [];
  const billedCptColumns = [];
  const billedModifierColumns = [];

  const providerRvuColumns = [];

  const auditedIctColumns = [];
  const auditedCptColumns = [];
  const auditedModifierColumns = [];

  const _auditedRuvColumns = [];
  const _defaultColumns = [];

  let output = [];

  columnsData.forEach(cd => {
    if (firstSetColumnKeyOrder.includes(cd?.data)) {
      firstSetColumns.push(cd);
    } else if (rvuColumnsOrder.includes(cd?.data)) {
      providerRvuColumns.push(cd);
    } else if (auditedRuvColumns.includes(cd?.data)) {
      _auditedRuvColumns.push(cd);
    } else if (defaultColumns.includes(cd?.data)) {
      _defaultColumns.push(cd);
    } else if (cd?.data.match('srvcs_no')) {
      billedIctColumns.push(cd);
    } else if (cd?.data.match('billed_cpt')) {
      billedCptColumns.push(cd);
    } else if (cd?.data.match('billed_modifier')) {
      billedModifierColumns.push(cd);
    } else if (cd?.data.match('audited_icd')) {
      auditedIctColumns.push(cd);
    } else if (cd?.data.match('audited_cpt')) {
      auditedCptColumns.push(cd);
    } else if (cd?.data.match('audited_modifier')) {
      auditedModifierColumns.push(cd);
    }
  });

  const ictCol = [...Object.values(sortData(billedIctColumns))];
  const cptCol = [...Object.values(sortData(billedCptColumns))];
  const modCol = [...Object.values(sortData(billedModifierColumns))];

  const aIctCol = [...Object.values(sortData(auditedIctColumns))];
  const aCptCol = [...Object.values(sortData(auditedCptColumns))];
  const aModCol = [...Object.values(sortData(auditedModifierColumns))];

  const col = [
    ...firstSetColumns,
    ...ictCol,
    ...cptCol,
    ...modCol,
    ...providerRvuColumns,

    ...aIctCol,
    ...aCptCol,
    ...aModCol,
    ..._auditedRuvColumns,
    ..._defaultColumns,
  ];

  output.push(col);

  return output.flat();
}

export function prepareAuditSheetColumn(
  _sheetData,
  windowSize,
  view,
  providerOptions,
  isAuditedDollarDisabled,
  isProviderDollarDisabled
) {
  const sheetData = [_sheetData[0]];

  const auditSheetColumns = [];
  sheetData.forEach(sd => {
    let columnProps = {};

    if (sd && Object.keys(sd).length) {
      Object?.keys(sd).forEach(key => {
        if (dynamicAuditColumnHeader[key]) {
          columnProps['data'] = key;
          columnProps['type'] = columnType[key];
          columnProps['width'] = windowSize * columnWidth[key];
          columnProps['readOnly'] = view;
          columnProps['renderer'] = 'customStylesRenderer';

          if (key === AUDIT_COLUMN_HEADER.RENDERING) {
            const providerNames = providerOptions.map(p => p.value);
            columnProps['source'] = providerNames;
          }

          if (key === AUDIT_COLUMN_HEADER.ENC_DT) {
            columnProps['dateFormat'] = 'MM-DD-YYYY';
            columnProps['datePickerConfig'] = {
              disableDayFn(date) {
                return date > new Date();
              },
            };
          }

          if (key === AUDIT_COLUMN_HEADER.PROVIDER_DOLLER_VALUE) {
            columnProps['readOnly'] = view || isProviderDollarDisabled;
          }

          if (key === AUDIT_COLUMN_HEADER.AUDITOR_DOLLER_VALUE) {
            columnProps['readOnly'] = view || isAuditedDollarDisabled;
          }

          if (
            key === AUDIT_COLUMN_HEADER.AGREE ||
            key === AUDIT_COLUMN_HEADER.DISAGREE
          ) {
            columnProps['validator'] = agreeValidator;
            columnProps['renderer'] = 'agreeRenderer';
          }
          auditSheetColumns.push(columnProps);
          columnProps = {};
        } else if (key?.match('srvcs_no')) {
          const srvcColumnProps = prepareSrvcColumn(key, windowSize, view);
          auditSheetColumns.push(srvcColumnProps);
        } else if (key?.match('billed')) {
          const billedColumnProps = prepareBilledColumn(key, windowSize, view);
          auditSheetColumns.push(billedColumnProps);
        } else if (key?.match('audited')) {
          const auditedColumnProps = prepareAuditedColumn(
            key,
            windowSize,
            view
          );
          auditSheetColumns.push(auditedColumnProps);
        }
      });
    }
  });

  const arrangedColumns = arrangeColumns(auditSheetColumns);

  return arrangedColumns;
}

/**
 *
 * @param {String} key
 */

function prepareKey(key) {
  if (key.match('srvcs_no')) {
    const k = key.replace(/srvcs_no/i, 'billed_icd');

    const columnKey = k.replace(/_/g, ' ').split(' ');

    const replaceStr = columnKey.map((w, i) => {
      if (i === 0) {
        return toTitleCase(w);
      } else {
        return w.toUpperCase();
      }
    });

    return replaceStr.join(' ');
  }

  const columnKey = key.replace(/_/g, ' ').split(' ');

  const replaceStr = columnKey.map((w, i) => {
    if (i === 0) {
      return toTitleCase(w);
    } else if (w === 'icd' || w === 'cpt') {
      return w.toUpperCase();
    } else {
      return toTitleCase(w);
    }
  });

  return replaceStr.join(' ');
}

/**
 *
 * @param {Array} sheetData
 *
 */

export function prepareAuditSheetHeader(sheetData) {
  const headers = [];

  [sheetData[0]].forEach(sd => {
    if (sd && Object.keys(sd)) {
      Object?.keys(sd).forEach(key => {
        if (key !== 'id' && key !== 'row_id' && key !== 'chart_id') {
          if (dynamicAuditColumnHeader[key]) {
            headers.push(dynamicAuditColumnHeader[key]);
          } else {
            const newKey = prepareKey(key);
            headers.push(newKey);
          }
        }
      });
    }
  });

  return arrangeHeader(headers);

  const _headers = arrangeHeader(headers);
  return Array.from(new Set(_headers));
}

/**
 *
 * @param {Array} headers
 */

function arrangeHeader(headers) {
  const firstSetColumns = ['File Id #', 'Encounter #', 'Rendering', 'Enc Dt'];

  const agreeColumns = ['RVU', '$ value', 'Response', 'Agree', 'Disagree'];

  const auditedRvuValues = ['RVU', '$ Value', 'Notes'];

  const lastSetColumns = [
    'Provider Education',
    'Number & Complexity',
    'Amount & Complexity',
    'Mortality of PatientManagement',
  ];

  const billedIcdColumns = [];

  const billedCptColumns = [];

  const billedModifierColumns = [];

  const auditedIcdColumns = [];

  const auditedCptColumns = [];

  const auditedModifier = [];

  headers.forEach(h => {
    if (h.match('Billed ICD')) {
      billedIcdColumns.push(h);
    } else if (h.match('Billed CPT')) {
      billedCptColumns.push(h);
    } else if (h.match('Billed Modifier')) {
      billedModifierColumns.push(h);
    } else if (h.match('Audited ICD')) {
      auditedIcdColumns.push(h);
    } else if (h.match('Audited CPT')) {
      auditedCptColumns.push(h);
    } else if (h.match('Audited Modifier')) {
      auditedModifier.push(h);
    }
  });

  const billedColumns = [
    ...sortColumns(billedIcdColumns),
    ...sortColumns(billedCptColumns),
    ...sortColumns(billedModifierColumns),
  ];
  const auditedColumns = [
    ...sortColumns(auditedIcdColumns),
    ...sortColumns(auditedCptColumns),
    ...sortColumns(auditedModifier),
  ];

  const sortedHeaders = [
    ...firstSetColumns,
    ...billedColumns,
    ...agreeColumns,
    ...auditedColumns,
    ...auditedRvuValues,
    ...lastSetColumns,
  ];

  return sortedHeaders;
}

/**
 *
 * @param {Array} columns
 */

function sortColumns(columns) {
  return columns.sort((a, b) => {
    const aNum = parseInt(a.match(/\d+/));
    const bNum = parseInt(b.match(/\d+/));

    if (isNaN(aNum) && isNaN(bNum)) {
      return 0;
    } else if (isNaN(aNum)) {
      return -1;
    } else if (isNaN(bNum)) {
      return 1;
    }

    return aNum - bNum;
  });
}

/**
 *
 * @param {Array} sheetData
 */

export function arrangeData(sheetData) {
  let firstSetDatas = {};

  let billedIcddatas = {};
  let billedCptDatas = {};
  let billedModifierDatas = {};

  let agreeColumns = {};

  let auditedIcdData = {};
  let auditedCptData = {};
  let auditedModifierData = {};

  let auditedRvuColumns = {};

  let lastSetColumns = {};

  let defaultprops = {};

  const finalData = [];

  sheetData.forEach(item => {
    for (const key in item) {
      if (firstSetColumnKeyOrder.includes(key)) {
        Object.assign(firstSetDatas, { [key]: item[key] });
      } else if (rvuColumnsOrder.includes(key)) {
        Object.assign(agreeColumns, { [key]: item[key] });
      } else if (auditedRuvColumns.includes(key)) {
        Object.assign(auditedRvuColumns, { [key]: item[key] });
      } else if (defaultColumns.includes(key)) {
        Object.assign(lastSetColumns, { [key]: item[key] });
      } else if (key.match('srvcs_no')) {
        Object.assign(billedIcddatas, { [key]: item[key] });
      } else if (key.match('billed_cpt')) {
        Object.assign(billedCptDatas, { [key]: item[key] });
      } else if (key.match('billed_modifier')) {
        Object.assign(billedModifierDatas, { [key]: item[key] });
      } else if (key.match('audited_icd')) {
        Object.assign(auditedIcdData, { [key]: item[key] });
      } else if (key.match('audited_cpt')) {
        Object.assign(auditedCptData, { [key]: item[key] });
      } else if (key.match('audited_modifier')) {
        Object.assign(auditedModifierData, { [key]: item[key] });
      } else if (defaultDataProps.includes(key)) {
        Object.assign(defaultprops, { [key]: item[key] });
      }
    }

    const billedColumns = {
      ...sortData(billedIcddatas),
      ...sortData(billedCptDatas),
      ...sortData(billedModifierDatas),
    };

    const auditedColumns = {
      ...sortData(auditedIcdData),
      ...sortData(auditedCptData),
      ...sortData(auditedModifierData),
    };

    const col = {
      ...firstSetDatas,
      ...billedColumns,
      ...agreeColumns,
      ...auditedColumns,
      ...auditedRvuColumns,
      ...lastSetColumns,
      ...defaultprops,
    };

    Object.assign(firstSetDatas, {});
    Object.assign(agreeColumns, {});
    Object.assign(auditedRvuColumns, {});
    Object.assign(lastSetColumns, {});

    Object.assign(billedIcddatas, {});
    Object.assign(billedCptDatas, {});
    Object.assign(billedModifierDatas, {});

    Object.assign(auditedIcdData, {});
    Object.assign(auditedCptData, {});
    Object.assign(auditedModifierData, {});
    Object.assign(defaultprops, {});

    finalData.push(col);
  });

  return finalData;
}

function sortData(obj) {
  return Object.keys(obj)
    .sort((a, b) => {
      const hasNumberA = /\d+$/.test(a);
      const hasNumberB = /\d+$/.test(b);

      if (hasNumberA && hasNumberB) {
        const numberA = parseInt(a.match(/\d+$/)[0]);
        const numberB = parseInt(b.match(/\d+$/)[0]);
        return numberA - numberB;
      } else if (hasNumberA) {
        return 1;
      } else if (hasNumberB) {
        return -1;
      } else {
        return a.localeCompare(b);
      }
    })
    .reduce((acc, key) => {
      acc[key] = obj[key];
      return acc;
    }, {});
}

/**
 *
 * @param {Array} column
 */

export function findHighlightedColumn(column) {
  const highlightedIndices = [];

  column?.forEach(({ data }, i) => {
    if (data === 'provider_rvu') {
      highlightedIndices.push(i);
    } else if (data === 'provider_dollar_value') {
      highlightedIndices.push(i);
    } else if (data === 'audited_rvu') {
      highlightedIndices.push(i);
    } else if (data === 'audited_dollar_value') {
      highlightedIndices.push(i);
    }
  });

  return highlightedIndices;
}

export function findDollorColumn(column) {
  const dolloarColumnIndices = [];

  column.forEach(({ data }, i) => {
    if (data === 'provider_dollar_value') {
      dolloarColumnIndices.push(i);
    } else if (data === 'audited_dollar_value') {
      dolloarColumnIndices.push(i);
    }
  });

  return dolloarColumnIndices;
}

export function _prepareColumnheaderName() {}

function sanitizeObject(payload) {
  for (const key of addionalAttributesKeys) {
    for (const k in payload) {
      if (k.match(key)) {
        delete payload[k];
      }
    }
  }

  return payload;
}

export function payloadreStructure(payload) {
  const arr = [];
  const payloadprops = payload[0];

  payload
    .map(({ data }) => data)
    .forEach(item => {
      item.forEach(i => {
        const additional_attributes = {};
        Object.keys(i).forEach(k => {
          addionalAttributesKeys.forEach(aak => {
            if (k.startsWith(aak)) {
              Object.assign(additional_attributes, { [k]: i[k] });
            }
          });
        });

        const _payload = sanitizeObject({ ...i, additional_attributes });

        const setAuditedCode = {
          ..._payload,
          audited_code: _payload['audited_icd'],
        };
        delete setAuditedCode['audited_icd'];

        arr.push(setAuditedCode);
      });
    });

  return [{ ...payloadprops, data: arr }];
}

/**
 *
 * @param {Array} data
 * @returns { data with adtional_attribues }
 */

function haveAddionalAttributes(data) {
  const noAddionalAttributes = data.every(
    obj => obj.additional_attributes === null
  );

  if (noAddionalAttributes) {
    return data.map(d => {
      const tempObj = {
        ...d,
        additional_attributes: {
          provider_education: null,
          number_complexity: null,
          amount_complexity: null,
          risk_complications: null,
          audited_cpt: null,
          billed_cpt: null,
        },
      };
      return tempObj;
    });
  } else {
    return data;
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with srvcs Column }
 */

function isHaveSrvcsNoColumn(response) {
  const isEmptySrvcsColumn = response.every(obj => obj?.srvcs_no === null);

  if (isEmptySrvcsColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('srvcs_no_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            srvcs_no_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with billedCPT column }
 */

function isHaveBilledCPTColumn(response) {
  const havebilledCpt = response
    .map(obj => {
      return obj.additional_attributes.hasOwnProperty('billed_cpt');
    })
    .every(Boolean);

  const isEmptyBilledCptColumn = response.every(
    obj => obj?.additional_attributes?.billed_cpt === null
  );

  if (!havebilledCpt) {
    return response.map(res => {
      return {
        ...res,
        additional_attributes: {
          ...res.additional_attributes,
          billed_cpt: null,
        },
      };
    });
  } else if (isEmptyBilledCptColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res?.additional_attributes?.hasOwnProperty('billed_cpt_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            billed_cpt_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with billedCPTColumn }
 */

function isHaveBilledModifierColumn(response) {
  const isEmptyBilledModifierColumn = response.every(
    obj => obj?.billed_modifier === null
  );

  if (isEmptyBilledModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('billed_modifier_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            billed_modifier_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns
 */
function isHAveAuditedCodeColumn(response) {
  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.audited_code === null
  );

  if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_icd_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            audited_icd_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with auditedcptColumn }
 */
function isHaveAuditedCPTColumn(response) {
  const haveAuditedCpt = response
    .map(obj => {
      return obj.additional_attributes.hasOwnProperty('audited_cpt');
    })
    .every(Boolean);

  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.additional_attributes?.audited_cpt === null
  );

  if (!haveAuditedCpt) {
    return response.map(res => {
      return {
        ...res,
        additional_attributes: {
          ...res.additional_attributes,
          audited_cpt: null,
        },
      };
    });
  } else if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_cpt_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            audited_cpt_2: null,
          },
        };
      }
    });
  }
}

/**
 *
 * @param {Array} response
 * @returns { data with auditedModifier }
 */
function isHaveAuditedModifierColumn(response) {
  const isEmptyAuditedModifierColumn = response.every(
    obj => obj?.audited_modifier === null
  );

  if (isEmptyAuditedModifierColumn) {
    return response;
  } else {
    return response.map(res => {
      if (res.additional_attributes?.hasOwnProperty('audited_modifier_2')) {
        return res;
      } else {
        return {
          ...res,
          additional_attributes: {
            ...res?.additional_attributes,
            audited_modifier_2: null,
          },
        };
      }
    });
  }
}

export function createEmptyColumn(response) {
  const _haveAddionalAttributes = haveAddionalAttributes(response);

  // billed
  const _isHaveSrvcsNoColumn = isHaveSrvcsNoColumn(_haveAddionalAttributes);

  const _isHaveBilledCPTColumn = isHaveBilledCPTColumn(_isHaveSrvcsNoColumn);

  const _isHaveBilledModifierColumn = isHaveBilledModifierColumn(
    _isHaveBilledCPTColumn
  );

  // audited
  const _isHAveAuditedCodeColumn = isHAveAuditedCodeColumn(
    _isHaveBilledModifierColumn
  );

  const _isHaveAuditedCPTColumn = isHaveAuditedCPTColumn(
    _isHAveAuditedCodeColumn
  );

  const _isHaveAuditedModifierColumn = isHaveAuditedModifierColumn(
    _isHaveAuditedCPTColumn
  );
  return _isHaveAuditedModifierColumn;
}

export const createEmptyTablesProps = currentUpload => {
  return [
    {
      index: 1,
      sheet_name: 'Sheet1',
      data: [
        {
          file_name: currentUpload?.upload_id,
          chart_id: currentUpload.id,
          row_id: 1,

          encounter_no: null,
          rendering: { first_name: '', last_name: '', id: '' },
          enc_dt: null,

          additional_attributes: {
            billed_cpt: null,

            audited_cpt: null,

            provider_education: null,
            number_complexity: null,
            amount_complexity: null,
            risk_complications: null,
          },

          srvcs_no: null,
          billed_modifier: null,

          provider_rvu: null,
          provider_dollar_value: null,
          response: null,
          agree: null,
          disagree: null,

          audited_code: null,
          audited_modifier: null,

          audited_rvu: null,
          audited_dollar_value: null,
          notes: null,
        },
      ],
    },
  ];
};

export function getAuditSheetKeys(columnData) {
  return columnData.map(({ data }, i) => {
    return data;
  });
}

export function findAgreeDisAgreeColumn(columnData) {
  const agreeDisAgreeIndices = [];
  columnData.forEach(({ data }, i) => {
    if (data === 'agree') {
      agreeDisAgreeIndices.push(i);
    } else if (data === 'disagree') {
      agreeDisAgreeIndices.push(i);
    }
  });

  return agreeDisAgreeIndices;
}

/**
 *
 * @param {Array} columnData
 */

export function findResponseColumn(columnData) {
  const responseColumnIndices = columnData.reduce((acc, cd, i) => {
    if (cd.data === 'response') {
      acc = i;
    }
    return acc;
  }, null);
  return responseColumnIndices;
}

/**
 *
 * @param {String} columnName
 */
export function createColumnDataName(columnName) {
  const regex = /^(.*?)(_([0-9]+))?$/;
  const match = columnName.match(regex);
  const prefix = match[1] || '';
  const number = match[3] ? parseInt(match[3]) : 0;
  const incrementedNumber = number === 0 ? 2 : number + 1;

  const colName = `${prefix}_${incrementedNumber}`;

  return colName;
}

export function creatColumnHeader(columnName) {
  const regex = /^(.*?)(\s+([0-9]+))?$/;
  const match = columnName.match(regex);
  const prefix = match[1] || '';
  const number = match[3] ? parseInt(match[3]) : null;

  const prefixWithSpaces = prefix.trim();

  const incrementedNumber = number !== null ? number + 1 : 2;

  return `${prefixWithSpaces} ${incrementedNumber}`;
}

export function isColumnMatched(string, keywords) {
  for (let i = 0; i < keywords.length; i++) {
    const pattern = new RegExp(keywords[i] + '(_\\d+)?');
    const match = string.match(pattern);
    if (match) {
      return true;
    }
  }
  return false;
}
