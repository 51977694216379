import {
  Box,
  Button,
  HStack,
  ScaleFade,
  Skeleton,
  Stack,
  Text,
  VStack,
} from '@chakra-ui/react';
import React from 'react';
import { useContext } from 'react';
import AppColors from '../../../../constants/AppColors';
import { TriangleIcon, TriangleRedIcon } from '../../../../constants/IconData';
import { FONT_FAMILY } from '../../../../constants/Theme';
import { BatchContext } from '../../../../providers/BatchProvider';
import { convertToPositiveDollar } from '../../../../utils/common.utils';
import { priceConverter } from '../../../../utils/priceConverter';

function BatchRevenueOppurtunity() {
  const batchContext = useContext(BatchContext);
  const { revenueOpporturintyScore, isRevenueOppScoreFetching } = batchContext;
  const renderContent = () => {
    return (
      <ScaleFade initialScale={0.5} in={true}>
        <Box
          m={2}
          bg={AppColors.white}
          borderRadius={'md'}
          padding={4}
          display={'flex'}
          flexDirection={'column'}
        >
          {/* header */}
          <HStack justifyContent={'space-between'}>
            <Text
              fontSize="sm"
              fontFamily={FONT_FAMILY.ptSans}
              color={AppColors.primary}
            >
              Revenue Opportunity
            </Text>
          </HStack>
          {/* content */}
          <HStack mt={4}>
            {revenueOpporturintyScore.difference >= 0 ? (
              <TriangleIcon />
            ) : (
              <TriangleRedIcon />
            )}
            <Text
              fontSize={'xs'}
              fontFamily={FONT_FAMILY.ptSans}
              color={revenueOpporturintyScore.difference >= 0 ? 'black' : 'red'}
            >
              {convertToPositiveDollar(
                revenueOpporturintyScore.difference
                  ? priceConverter(revenueOpporturintyScore.difference)
                  : '$0'
              )}
            </Text>
          </HStack>
          <Box
            mt={5}
            width={'100%'}
            height={'100%'}
            display={'flex'}
            flexDirection={'column'}
            justifyContent={'space-evenly'}
          >
            <Stack>
              <Text mb={2} fontSize={'5xl'} fontFamily={FONT_FAMILY.ptSans}>
                {revenueOpporturintyScore?.cq_outstanding_rev_opp
                  ? priceConverter(
                      revenueOpporturintyScore?.cq_outstanding_rev_opp
                    )
                  : '$0'}
              </Text>
              <Text
                fontSize={'sm'}
                fontFamily={FONT_FAMILY.ptSans}
                color={AppColors.lightGrey}
              >
                This is the amount of revenue possibly missing out on
                reimbursement.
              </Text>
            </Stack>

            {/* footer */}
            <Box mt={10}>
              <Button variant="outline" fontSize={'sm'}>
                <a href="#outstandingRevenue">Chart Details</a>
              </Button>
            </Box>
          </Box>
        </Box>
      </ScaleFade>
    );
  };

  const renderFailedPage = () => {
    return (
      <VStack justifyContent={'center'} h={'100%'} textAlign={'center'}>
        <Text>Something went wrong, Please try again later</Text>
      </VStack>
    );
  };

  const renderLoader = () => {
    return (
      <Stack p={4}>
        <Skeleton height="20px" />
        <Skeleton height="20px" />
        <Skeleton height="20px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isRevenueOppScoreFetching) {
      return renderLoader();
    } else if (!revenueOpporturintyScore) {
      return renderFailedPage();
    } else {
      return renderContent();
    }
  };
  return renderMainContent();
}

export default BatchRevenueOppurtunity;
