import { API } from '../config/AppConfig';
import {
  ADD_COMMENT,
  CHART_ACCURACY_BREAKDOWN,
  CQ_REVENUE_OPP,
  DASHBOARD_RVU_OPP_BREAKDOWN,
  GET_AUDITSHEET_COMMENTS,
  GET_DASHBOARD_CQ_SCORE,
  UPLOAD_CHART_ACTIVITY,
} from '../constants/Endpoints';
class BatchDashboardRepository {
  async getBatchCQScore(queryParams) {
    const response = await API.get(GET_DASHBOARD_CQ_SCORE, { queryParams });
    return response;
  }

  async getoutStandingRevOpp(queryParams) {
    const response = await API.get(CQ_REVENUE_OPP, { queryParams });
    return response;
  }

  async getOutStandingRevOppBreakDown(queryParams) {
    const response = await API.get(DASHBOARD_RVU_OPP_BREAKDOWN, {
      queryParams,
    });
    return response;
  }

  async getChartAccuracyData(queryParams) {
    const response = await API.get(CHART_ACCURACY_BREAKDOWN, {
      queryParams,
    });
    return response;
  }

  async getUploadChartActivity(queryParams) {
    const response = await API.get(UPLOAD_CHART_ACTIVITY, { queryParams });
    return response;
  }
  async getAuditRebuttalComments(queryParams) {
    const response = await API.get(GET_AUDITSHEET_COMMENTS, {
      pathParams: { batchId: queryParams.batch_id },
      queryParams
    });
    return response;
  }

  async addComment(commentBody) {
    const response = await API.post(ADD_COMMENT, {
      body: commentBody,
    });
    return response;
  }
}

const batchDashboardRepository = new BatchDashboardRepository();
export default batchDashboardRepository;
