/* eslint-disable react-hooks/exhaustive-deps */
import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Skeleton,
  Stack,
  Text,
  useBoolean,
} from '@chakra-ui/react';
import { useToastr } from '@prasanna-transcend/code-quick-components';
import React, { useState } from 'react';
import CQContainer from '../../components/layout/CQContainer';
import AppColors from '../../constants/AppColors';
import { FONT_FAMILY } from '../../constants/Theme';
import faqHelpRepository from '../../repositories/FaqRepository';

const HelpContainer = props => {
  const toast = useToastr();
  const [isFetching, ifState] = useBoolean(false);
  const [faqQuestions, setFaqQuestions] = useState([]);

  const getFaqQuestions = async () => {
    try {
      ifState.on();
      const response = await faqHelpRepository.getFaqQuestions();
      setFaqQuestions(response);
      return response;
    } catch (error) {
      toast.showError({
        description: `${error.toString()}`,
      });
    } finally {
      ifState.off();
    }
  };

  React.useEffect(() => {
    getFaqQuestions();
  }, []);

  const renderAccordian = () => {
    return (
      <Accordion borderColor={'transparent'} allowToggle>
        {faqQuestions
          .sort((q, p) => q.order - p.order)
          .filter(f => f.is_active)
          .map((faq, i) => {
            return (
              <AccordionItem key={i} mb={5}>
                <h2>
                  <AccordionButton
                    color={AppColors.secondary}
                    _hover={{ bg: AppColors.white }}
                    bg={AppColors.white}
                    borderRadius={'md'}
                    _expanded={{
                      bg: AppColors.secondary,
                      color: AppColors.white,
                    }}
                  >
                    <Box flex="1" textAlign="left">
                      <Text
                        fontSize={'xl'}
                        fontFamily={FONT_FAMILY.baiJamurjee}
                        p={3}
                      >
                        {faq.question}
                      </Text>
                    </Box>
                    <AccordionIcon />
                  </AccordionButton>
                </h2>
                <AccordionPanel
                  fontFamily={FONT_FAMILY.ptSans}
                  fontSize={'lg'}
                  p={5}
                >
                  {faq.answer}
                </AccordionPanel>
              </AccordionItem>
            );
          })}
      </Accordion>
    );
  };

  const renderLoader = () => {
    return (
      <Stack>
        <Skeleton mb={2} borderRadius={'md'} height="70px" />
        <Skeleton mb={2} borderRadius={'md'} height="70px" />
        <Skeleton mb={2} borderRadius={'md'} height="70px" />
        <Skeleton mb={2} borderRadius={'md'} height="70px" />
        <Skeleton mb={2} borderRadius={'md'} height="70px" />
      </Stack>
    );
  };

  const renderMainContent = () => {
    if (isFetching) {
      return renderLoader();
    } else {
      return renderAccordian();
    }
  };

  return (
    <CQContainer py={30} px={200}>
      <Stack mb={5}>
        <Text
          fontFamily={FONT_FAMILY.baiJamurjee}
          fontSize={'25px'}
          fontWeight={600}
        >
          We are here to help - FAQ
        </Text>
      </Stack>
      {renderMainContent()}
    </CQContainer>
  );
};

export default HelpContainer;
